import { MapLayerMouseEvent, Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { closeAllMapboxPopups, getPopupQueryType } from "features/map/utils";

import { ODPopupProps } from "components";

import { Counts, DatasetGate, QueryType, Segment, SelectedArea } from "types";

import { ROADS_SOURCE_ID } from "../../roads/map-data/sources";
import { GATES_LAYER_ID } from "./layers";
import { GATES_SOURCE_ID } from "./sources";

const updateSegmentsStateWithHover = (
  map: mapboxgl.Map,
  segments: Segment[],
  status: boolean,
  roadSourceLayer?: string,
) => {
  if (map.getSource(ROADS_SOURCE_ID) && roadSourceLayer) {
    segments.forEach((segment) => {
      map.setFeatureState(
        {
          source: ROADS_SOURCE_ID,
          sourceLayer: roadSourceLayer,
          id: segment.id,
        },
        { hover: status },
      );
    });
  }
};

export const getGatesHandlers = (
  map: mapboxgl.Map,
  gates: DatasetGate[] | null,
  ODCounts: MutableRefObject<Counts>,
  selectedZone: MutableRefObject<SelectedArea | null>,
  queryType: MutableRefObject<QueryType>,
  ODPopupRef: React.RefObject<HTMLDivElement>,
  mapboxODZoneHoverPopupRef: React.RefObject<mapboxgl.Popup>,
  setODPopupProps: React.RefObject<Dispatch<SetStateAction<ODPopupProps | null>>>,
  roadSourceLayer?: string,
) => {
  let clickedState: string | null = null;
  let hoveredSegments: Segment[] | null = null;
  const handleMouseMove = (e: MapLayerMouseEvent) => {
    if (map) {
      if (clickedState) {
        map.setFeatureState(
          {
            source: GATES_SOURCE_ID,
            id: clickedState,
          },
          { hover: false },
        );
      }

      const featureId = e.features?.[0].id?.toString();
      if (featureId && gates) {
        map.getCanvas().style.cursor = "pointer";
        map.setFeatureState(
          {
            source: GATES_SOURCE_ID,
            id: featureId,
          },
          { hover: true },
        );

        const gate = gates.find((gate: DatasetGate) => gate.identifier === featureId);
        const segments = gate?.segments || [];
        hoveredSegments = segments;
        updateSegmentsStateWithHover(map, segments, true, roadSourceLayer);
        clickedState = featureId;
        const count = ODCounts.current.gates.get(featureId) || 0;

        if (typeof setODPopupProps.current === "function") {
          setODPopupProps.current({
            count: queryType.current ? count : undefined,
            type: queryType.current
              ? getPopupQueryType(selectedZone.current?.id, featureId, queryType.current)
              : undefined,
            gateId: featureId,
          });
        }

        if (mapboxODZoneHoverPopupRef.current) {
          mapboxODZoneHoverPopupRef.current.remove();
        }

        if (ODPopupRef.current) {
          (mapboxODZoneHoverPopupRef.current as mapboxgl.Popup) = new Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 15,
          })
            .setLngLat(e.lngLat)
            .setDOMContent(ODPopupRef.current as Node)
            .addTo(map);
        }
      }
    }
  };

  const handleMouseLeave = () => {
    if (map && clickedState) {
      map.getCanvas().style.cursor = "";
      map.setFeatureState(
        {
          source: GATES_SOURCE_ID,
          id: clickedState,
        },
        { hover: false },
      );
      clickedState = null;
    }

    if (map && hoveredSegments) {
      updateSegmentsStateWithHover(map, hoveredSegments, false, roadSourceLayer);
    }

    if (mapboxODZoneHoverPopupRef.current) {
      mapboxODZoneHoverPopupRef.current.remove();
    }
  };

  map.on("mousemove", GATES_LAYER_ID, handleMouseMove);
  map.on("mouseleave", GATES_LAYER_ID, handleMouseLeave);

  return {
    cleanGatesHandlers: () => {
      closeAllMapboxPopups(map);

      map.off("mousemove", GATES_LAYER_ID, handleMouseMove);
      map.off("mouseleave", GATES_LAYER_ID, handleMouseLeave);
    },
  };
};
