export enum ProfileMode {
  ROADS_MODE_GETTING_SEGMENT_IDS = "ROADS_MODE Time for getting segment ids",
  ROADS_MODE_GETTING_SEGMENT_COUNTS = "ROADS_MODE Time for getting segment counts",
  ROADS_MODE_PUT_LAYERS_ON_MAP = "ROADS_MODE Time for putting layers on the map (the layer manager result)",
  ROADS_MODE_LAYER_MANAGER_OPERATIONS = "ROADS_MODE Time after receiving all data and before rendering (the layer manager operations)",
}

export const profileTimeStart = (mode: ProfileMode) => {
  if (process.env.NODE_ENV === "development") {
    console.time(mode);
  }
};

export const profileTimeEnd = (mode: ProfileMode) => {
  if (process.env.NODE_ENV === "development") {
    console.timeEnd(mode);
  }
};
