import { FC } from "react";

import { BluePoint, HeadCellAlignCenter, Table, TableHeadRow } from "./Table";

export const LicenseSoftwareTable: FC = () => {
  return (
    <Table>
      <thead>
        <TableHeadRow>
          <th>Software licensing</th>
          <HeadCellAlignCenter>Basic</HeadCellAlignCenter>
          <HeadCellAlignCenter>Pro</HeadCellAlignCenter>
        </TableHeadRow>
      </thead>
      <tbody>
        <tr>
          <td>View and Filter</td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
        </tr>
        {/* <tr>
          <td>Interactive summaries: screenlines & polygon</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <tr>
          <td>Custom zones </td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr> */}
        <tr>
          <td>Export</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <tr>
          <td>Creation of subarea datasets based on custom zonings</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <tr>
          <td>Corridor Discovery</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <tr>
          <td>Select Link</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr>
        {/* <tr>
          <td>Attach to Partner Databases</td>
          <td>&nbsp;</td>
          <td>
            <BluePoint />
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
};
