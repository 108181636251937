import { East, North, NorthEast, NorthWest, South, SouthEast, SouthWest, West } from "@mui/icons-material";

import { getSelectedVolumeObject } from "features/map/utils";

import { VolumeCount } from "components";

import { Direction, LEFT_SIDE_DIRECTIONS, SelectedSegmentConfig, SelectedVolume, Volume } from "types";

export const getOpenChevronIcon = (isOpen: boolean, placement: "bottom" | "top") => {
  if (placement === "bottom") {
    return isOpen ? "chevron-up" : "chevron-down";
  } else {
    return isOpen ? "chevron-down" : "chevron-up";
  }
};

export const getDirectionLabel = (direction: Direction | undefined): string => {
  switch (direction) {
    case "N":
      return "North";
    case "S":
      return "South";
    case "E":
      return "East";
    case "W":
      return "West";
    case "NE":
      return "North-East";
    case "NW":
      return "North-West";
    case "SE":
      return "South-East";
    case "SW":
      return "South-West";
    default:
      return "";
  }
};

export const getDirectionArrowIcon = (direction: Direction | undefined) => {
  switch (direction) {
    case "N":
      return <North />;
    case "S":
      return <South />;
    case "E":
      return <East />;
    case "W":
      return <West />;
    case "NE":
      return <NorthEast />;
    case "NW":
      return <NorthWest />;
    case "SE":
      return <SouthEast />;
    case "SW":
      return <SouthWest />;
    default:
      return undefined;
  }
};

export const getVolumesByDirections = (
  volume: Volume,
  isPedestriansMode: boolean,
  couldBeAdded?: boolean,
  onClick?: (selectedVolume: SelectedVolume, id: string) => void,
) => {
  const handleClick = (segmentId: string) => {
    if (onClick) {
      onClick(getSelectedVolumeObject(volume), segmentId);
    }
  };

  if (isPedestriansMode) {
    return (
      <>
        <VolumeCount
          count={volume.seg_pair ? volume.volumeTF + volume.volumeFT : volume.volumeFT}
          couldBeAdded={couldBeAdded}
          onClick={() => handleClick(volume.fromToId)}
        />
      </>
    );
  }

  if (volume.seg_pair && LEFT_SIDE_DIRECTIONS.indexOf(volume.tf_dir) !== -1) {
    return (
      <>
        <VolumeCount
          icon={getDirectionArrowIcon(volume.tf_dir)}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
        />
        <VolumeCount
          icon={getDirectionArrowIcon(volume.ft_dir)}
          count={volume.volumeFT}
          onClick={() => handleClick(volume.fromToId)}
          couldBeAdded={couldBeAdded}
        />
      </>
    );
  }

  return (
    <>
      {!volume.seg_pair && <td onClick={() => handleClick(volume.fromToId)}></td>}
      <VolumeCount
        icon={getDirectionArrowIcon(volume.ft_dir)}
        count={volume.volumeFT}
        onClick={() => handleClick(volume.fromToId)}
        couldBeAdded={couldBeAdded}
      />
      {volume.seg_pair && (
        <VolumeCount
          icon={getDirectionArrowIcon(volume.tf_dir)}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
        />
      )}
    </>
  );
};

export const getSelectedSegmentConfig = (selectedVolume: SelectedVolume, id: string): SelectedSegmentConfig => {
  return {
    segmentId: id,
    streetName: selectedVolume.streetName,
    direction:
      id === selectedVolume.tfSegmentId && selectedVolume.tfDirection
        ? selectedVolume.tfDirection
        : selectedVolume.ftDirection,
    lon: selectedVolume.lngLat[0],
    lat: selectedVolume.lngLat[1],
    fromToId: selectedVolume.feature.fromToId,
  };
};
