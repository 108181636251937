import { useEffect, useRef } from "react";

export function useStateRef<T>(state: T) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
}
