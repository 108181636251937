import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, styled } from "@mui/material";
import * as React from "react";

import { FormControlLabel } from "../FormControlLabel/FormControlLabel";

const StyledMuiCheckbox = styled((props: MuiCheckboxProps) => <MuiCheckbox disableRipple {...props} />)(
  ({ theme }) => ({
    padding: "0 9px",
  }),
);

type CheckboxBaseProps = Omit<
  MuiCheckboxProps,
  | "centerRipple"
  | "disableElevation"
  | "disableFocusRipple"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "TouchRippleProps"
  | "touchRippleRef"
>;

export interface CheckboxProps extends CheckboxBaseProps {
  label?: React.ReactNode;
  labelPlacement?: "end" | "start";
}

export const Checkbox: React.FC<CheckboxProps> = ({
  color = "secondary",
  size = "small",
  label,
  labelPlacement = "end",
  ...props
}) => {
  return label ? (
    <FormControlLabel
      control={<StyledMuiCheckbox color={color} size={size} {...props} />}
      label={label}
      labelPlacement={labelPlacement}
      sx={{ marginRight: 0 }}
    />
  ) : (
    <StyledMuiCheckbox color={color} size={size} {...props} />
  );
};
