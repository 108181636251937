import { Check, Clear, Edit } from "@mui/icons-material";
import { Box, Paper, Stack, SxProps, Typography } from "@mui/material";
import { IconButton } from "components_new";
import { FC, ReactNode } from "react";

export interface EditPaperProps {
  children: ReactNode;
  title: string;
  editMode: boolean;
  areUnappliedChanges: boolean;
  disabled?: boolean;
  handleSetEditMode: (editMode: boolean) => void;
  handleApply: () => void;
  handleCancel: () => void;
  sx?: SxProps;
}

export const EditPaper: FC<EditPaperProps> = ({
  children,
  title,
  editMode,
  areUnappliedChanges,
  disabled,
  sx,
  handleSetEditMode,
  handleApply,
  handleCancel,
}) => {
  return (
    <Box sx={{ paddingTop: 1, marginTop: 0.5 }}>
      <Paper
        variant="outlined"
        sx={{
          ...sx,
          height: "100%",
          position: "relative",
          paddingTop: 1,
          backgroundColor: "grey.50",
        }}
      >
        <Typography
          fontSize={11}
          fontWeight={500}
          sx={{
            position: "absolute",
            top: "-12px",
            left: "8px",
            paddingX: 1,
            backgroundColor: "#ffffff",
            border: 1,
            borderColor: "divider",
            borderRadius: "4px",
            padding: "1px 4px",
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            paddingX: 1,
            position: "absolute",
            top: "-10px",
            right: "16px",
            backgroundColor: "#ffffff",
            border: 1,
            borderColor: "divider",
            borderRadius: "4px",
          }}
        >
          {editMode ? (
            <Stack direction={"row"} spacing={1}>
              <IconButton color="success" disabled={!areUnappliedChanges} onClick={handleApply}>
                <Check fontSize="small" />
              </IconButton>
              <IconButton color="error" onClick={handleCancel}>
                <Clear fontSize="small" />
              </IconButton>
            </Stack>
          ) : (
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 500,
                cursor: disabled ? "not-allowed" : "pointer",
                display: "flex",
                alignItems: "center",
              }}
              color={disabled ? "text.disabled" : "secondary"}
              onClick={() => !disabled && handleSetEditMode(true)}
            >
              <Edit fontSize="inherit" sx={{ marginRight: 0.5 }} />
              Edit
            </Typography>
          )}
        </Box>

        {children}
      </Paper>
    </Box>
  );
};
