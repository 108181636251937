import { Screenline } from "api/analytics/index.d";
import { ConfirmDialog, DialogContentText } from "components_new";
import { FC } from "react";

export interface DeleteScreenlineDialogProps {
  screenlineToDelete: Screenline | null;
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteScreenline: () => void;
}

export const DeleteScreenlineDialog: FC<DeleteScreenlineDialogProps> = ({
  screenlineToDelete,
  isOpen,
  onClose,
  handleConfirmDeleteScreenline,
}) => {
  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      title={"Delete screenline " + screenlineToDelete?.id}
      confirmButtonText="Delete"
      cancelButtonText="Cancel"
      onConfirm={handleConfirmDeleteScreenline}
      onCancel={onClose}
    >
      <DialogContentText>Are you sure you want to delete this screenline?</DialogContentText>
    </ConfirmDialog>
  );
};
