import { useAuth0 } from "@auth0/auth0-react";
import { QueryBuilderOutlined } from "@mui/icons-material";
import { Alert, Typography } from "@mui/material";
import { Checkbox } from "components_new";
import { Button } from "components_new";
import React, { useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

import {
  DistanceSimplifications,
  DistanceTypes,
  FixedDistanceSimplification,
  VariableDistanceSimplification,
  Zoning,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { convertFeetToMeters } from "utils/format";

import { CustomZoningInfo } from "./CustomZoningInfo";
import { StyledDialogActions } from "./UploadZoningFiles";

interface Props {
  loading: boolean;
  folderId: string;
  defaultZoningName: string;
  activeStep: number;
  zoning: Zoning;
  errorMessage: string | null;
  successMessage: string | null;
}

// distance simplification in feet
export const DEFAULT_INTERVAL_M = 1.5;
export const DEFAULT_MAX_INTERVAL_M = 3;
export const DEFAULT_SQR_ROOT_AREA_FACTOR = 0.01;

const convertSimplificationToMeters = (simplification: DistanceSimplifications, distanceType: DistanceTypes) => {
  const newSimplification: VariableDistanceSimplification & FixedDistanceSimplification = {};

  if (distanceType === DistanceTypes.Constant) {
    newSimplification.intervalM = convertFeetToMeters(+simplification.intervalM);
  }
  if (distanceType === DistanceTypes.AreaDependent) {
    newSimplification.maxIntervalM = convertFeetToMeters(+simplification.maxIntervalM);
    newSimplification.sqrRootAreaFactor = +simplification.sqrRootAreaFactor;
  }

  return newSimplification;
};

export const CreateCustomZoning = ({
  activeStep,
  loading,
  folderId,
  defaultZoningName,
  zoning,
  errorMessage,
  successMessage,
}: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const [zoningName, setZoningName] = useState<string>(defaultZoningName);
  const [zoningDescription, setZoningDescription] = useState<string>("");
  const [selectedNameField, setSelectedNameField] = useState<string>("");
  const [selectedIdField, setSelectedIdField] = useState<string>(
    zoning.schema.idField === "__fid" ? "" : zoning.schema.idField,
  );
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
  const [isSimplificationActive, setIsSemplificationActive] = useState(true);
  const [selectedSimplification, setSelectedSimplification] = useState<DistanceSimplifications>({
    sqrRootAreaFactor: DEFAULT_SQR_ROOT_AREA_FACTOR.toString(),
    maxIntervalM: DEFAULT_MAX_INTERVAL_M.toString(),
    intervalM: DEFAULT_INTERVAL_M.toString(),
  });
  const [distanceType, setDistanceType] = useState<DistanceTypes>(DistanceTypes.Constant);
  const [openNewDatasetDialog, setOpenNewDatasetDialog] = useState(true);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const zoningPreparing = useAppSelector((state) => state.datasetFolders.zoningPreparing);

  const handlePrepareZoning = () => {
    dispatch(
      datasetFoldersActions.prepareZoning({
        stagedZoningId: zoning.zoningId,
        idField: selectedIdField,
        nameField: selectedNameField || null,
        attributes: selectedAttributes,
        ...(isSimplificationActive
          ? { simplification: convertSimplificationToMeters(selectedSimplification, distanceType) }
          : {}),
      }),
    );
  };

  const handleCreateZoning = () => {
    addCustomGAEvent("custom_zoning", "add_custom_zoning", zoningName, user, userOrganizationName);
    dispatch(
      datasetFoldersActions.createZoning(
        zoning.zoningId,
        folderId,
        zoningName,
        zoningDescription,
        openNewDatasetDialog,
      ),
    );
  };

  const isValidForm = selectedIdField.length > 0 && zoningName.length > 0;

  const settings = {
    loading,
    activeStep,
    zoningName,
    zoningDescription,
    selectedIdField,
    selectedNameField,
    selectedAttributes,
    selectedSimplification,
    distanceType,
    isSimplificationActive,
    setZoningName,
    setSelectedIdField,
    setSelectedNameField,
    setZoningDescription,
    setSelectedSimplification,
    setSelectedAttributes,
    setDistanceType,
    setIsSemplificationActive,
  };

  return (
    <>
      <CustomZoningInfo zoning={zoning} settings={settings} interactive />
      <StyledDialogActions>
        {errorMessage ? (
          <Alert severity={"error"}>Error: {errorMessage}</Alert>
        ) : successMessage ? (
          <Alert severity={"success"}>{successMessage}</Alert>
        ) : zoningPreparing.state === DataState.LOADING ? (
          <Alert severity="info" icon={<QueryBuilderOutlined fontSize="inherit" />}>
            The zoning is being prepared, please wait…
          </Alert>
        ) : (
          <div />
        )}
        {activeStep === 2 ? (
          <Checkbox
            checked={openNewDatasetDialog}
            onChange={(e) => setOpenNewDatasetDialog(e.target.checked)}
            label={
              <Typography fontSize={14} fontWeight={500}>
                Add dataset after creation
              </Typography>
            }
          />
        ) : (
          <div />
        )}

        <Button
          loading={loading}
          size="medium"
          color="secondary"
          disabled={!isValidForm || !zoning.validation.isValid}
          onClick={activeStep === 2 ? handleCreateZoning : handlePrepareZoning}
        >
          {activeStep === 2 ? "Create" : "Prepare"}
        </Button>
      </StyledDialogActions>
    </>
  );
};
