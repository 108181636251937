import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { MutableRefObject, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { ScreenlineEditor } from "features/screenline";

import { MapController } from "./MapController";
import { ScreenlinesAnalyticsPanel } from "./ScreenlinesAnalyticsPanel";

interface ScreenlinesModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  draw: MutableRefObject<any | null>;
  isModuleLoaded: boolean;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setSelectedScreenlineId: ((screenlineId: string | null) => void) | null;
  setSelectedIntersectionId: ((intersectionId: string | null) => void) | null;
  setScreenlinesModuleData: (moduleData: ModuleData | null) => void;
  onOpenRightPanel?: () => void;
  closeScreenlineAnalyticsPanelRef: MutableRefObject<(() => void) | null>;
}

export const ScreenlinesModuleComponent = ({
  map,
  draw,
  isModuleLoaded,
  layerManagerRef,
  setSelectedScreenlineId,
  setSelectedIntersectionId,
  setScreenlinesModuleData,
  closeScreenlineAnalyticsPanelRef,
}: ScreenlinesModuleComponentProps) => {
  useEffect(() => {
    const closeScreenlineAnalyticsPanel = closeScreenlineAnalyticsPanelRef.current;

    return () => {
      if (closeScreenlineAnalyticsPanel) {
        closeScreenlineAnalyticsPanel();
      }

      setScreenlinesModuleData(null);
    };
  }, [setScreenlinesModuleData, closeScreenlineAnalyticsPanelRef]);

  return (
    <>
      <MapController
        map={map}
        draw={draw}
        isModuleLoaded={isModuleLoaded}
        layerManagerRef={layerManagerRef}
        setScreenlinesModuleData={setScreenlinesModuleData}
      />
      <ScreenlineEditor
        map={map}
        draw={draw}
        setSelectedScreenlineId={setSelectedScreenlineId}
        setSelectedIntersectionId={setSelectedIntersectionId}
      />
      <ScreenlinesAnalyticsPanel
        closeScreenlineAnalyticsPanelRef={closeScreenlineAnalyticsPanelRef}
        setSelectedScreenlineId={setSelectedScreenlineId}
      />
    </>
  );
};
