import { SxProps, ToggleButtonGroup, styled } from "@mui/material";
import { ToggleButton } from "components_new";
import React, { FC, useCallback } from "react";

export interface ToggleButtonsProps {
  leftButtonLabel: string;
  rightButtonLabel: string;
  leftButtonDisabled?: boolean;
  rightButtonDisabled?: boolean;
  activeIndex: string;
  leftButtonIndex: string;
  rightButtonIndex: string;
  isMapToggle?: boolean;
  onChangeIndex: (activeIndex: string) => void;
  sx?: SxProps;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  minWidth: "110px",
  padding: theme.spacing(0.5),
}));

const MapToggleButtonsContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 22,
  left: "50%",
  transform: "translate(0%, -50%)",
  borderRadius: theme.shape.borderRadius,
  zIndex: 3,
  marginTop: theme.spacing(2),
  backgroundColor: "#fff",
  padding: theme.spacing(0.5),
  boxShadow: theme.shadows[1],
}));

export const ToggleButtons: FC<ToggleButtonsProps> = ({
  leftButtonLabel,
  rightButtonLabel,
  leftButtonDisabled,
  rightButtonDisabled,
  activeIndex,
  onChangeIndex,
  leftButtonIndex,
  rightButtonIndex,
  isMapToggle,
  sx,
}) => {
  const handleLeftButtonClick = useCallback(() => {
    if (activeIndex !== leftButtonIndex && !leftButtonDisabled) {
      onChangeIndex(leftButtonIndex);
    }
  }, [activeIndex, onChangeIndex, leftButtonIndex, leftButtonDisabled]);

  const handleRightButtonClick = useCallback(() => {
    if (activeIndex !== rightButtonIndex && !rightButtonDisabled) {
      onChangeIndex(rightButtonIndex);
    }
  }, [activeIndex, onChangeIndex, rightButtonIndex, rightButtonDisabled]);

  const renderButtons = useCallback(
    () => (
      <ToggleButtonGroup fullWidth value={activeIndex} sx={sx}>
        <StyledToggleButton
          fullWidth
          disabled={leftButtonDisabled}
          value={leftButtonIndex}
          onClick={handleLeftButtonClick}
        >
          {leftButtonLabel}
        </StyledToggleButton>
        <StyledToggleButton
          fullWidth
          disabled={rightButtonDisabled}
          value={rightButtonIndex}
          onClick={handleRightButtonClick}
        >
          {rightButtonLabel}
        </StyledToggleButton>
      </ToggleButtonGroup>
    ),
    [
      activeIndex,
      handleLeftButtonClick,
      handleRightButtonClick,
      leftButtonDisabled,
      leftButtonIndex,
      leftButtonLabel,
      rightButtonDisabled,
      rightButtonIndex,
      rightButtonLabel,
      sx,
    ],
  );

  return (
    <>
      {isMapToggle && <MapToggleButtonsContainer>{renderButtons()}</MapToggleButtonsContainer>}
      {!isMapToggle && renderButtons()}
    </>
  );
};
