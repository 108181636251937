import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import mapboxgl from "mapbox-gl";
import React, { MutableRefObject, useEffect } from "react";

import { MapBoxStyles } from "features/map/baseMapStyles";
import { getBounds } from "features/map/utils";

import { getMapHomeControl } from "components";

import { useAppSelector, usePrevious } from "hooks";

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
(mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable @typescript-eslint/no-var-requires */
/* eslint-enable import/no-webpack-loader-syntax */

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

interface Props {
  children?: React.ReactNode;
  map: MutableRefObject<mapboxgl.Map | null>;
  onMapLoaded: (loaded: boolean) => void;
}

const MapContainer = styled(Box)`
  width: 100%;
  height: 100%;
`;

export const RoadVmtMap = ({ children, map, onMapLoaded }: Props) => {
  // Focus Areas
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const previousSelectedFocusAreaId = usePrevious(selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const mapContainer = React.useRef(null);

  //Resetting the map if selected area of focus changes
  useEffect(() => {
    if (previousSelectedFocusAreaId && selectedFocusAreaId && previousSelectedFocusAreaId !== selectedFocusAreaId) {
      map.current = null;
      onMapLoaded(false);
    }
  }, [map, selectedFocusAreaId, previousSelectedFocusAreaId, onMapLoaded]);

  // Initialize map
  useEffect(() => {
    if (map.current) {
      return; // initialize map only once
    }

    if (selectedFocusArea) {
      const newBounds = getBounds(selectedFocusArea.geometry);
      const padding = { top: 40, bottom: 40, left: 340, right: 40 };

      map.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: MapBoxStyles.Streets,
        fitBoundsOptions: {
          padding,
        },
        bounds: newBounds,
        pitch: 0,
        bearing: 0,
        logoPosition: "bottom-right",
        transformRequest: (url, resourceType): any => {
          if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
            return {
              headers: {
                authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              },
              url,
            };
          }
        },
      });

      // disable map rotation using right click + drag
      map.current.dragRotate.disable();

      // disable map rotation using touch rotation gesture
      map.current.touchZoomRotate.disableRotation();

      // Add home control to the map.
      map.current.addControl(
        getMapHomeControl(map.current, newBounds, {
          padding,
        }),
        "top-left",
      );

      // Add zoom controls to the map.
      map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), "top-left");

      map.current.on("load", () => {
        onMapLoaded(true);
      });
    }
  }, [map, onMapLoaded, selectedFocusArea]);

  return (
    <>
      {children}
      <MapContainer ref={mapContainer} />
    </>
  );
};
