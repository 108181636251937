import { Box, Slide, SvgIcon, styled } from "@mui/material";
import { IconButton } from "components_new";
import React, { useEffect, useState } from "react";

interface CarouselProps {
  children: React.ReactNode[];
}

const CarrouselContainer = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CarrouselControls = styled(Box)`
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const SelectedCarouselButtonSvg = () => (
  <SvgIcon sx={{ width: "auto", height: "6px" }}>
    <svg width="34" height="6" viewBox="0 0 34 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="34" height="6" rx="3" fill="#000000" />
    </svg>
  </SvgIcon>
);

const NonSelectedCarouselButtonSvg = () => (
  <SvgIcon sx={{ width: "auto", height: "6px" }}>
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="8" height="6" rx="3" fill="#D1D5DB" />
    </svg>
  </SvgIcon>
);

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  // Index of the current page
  const [currentPage, setCurrentPage] = useState(0);
  // The direction that the slide will slide in
  const [slideDirection, setSlideDirection] = useState<"right" | "left">("left");
  // disable auto switching, when we spessfic select a page, autoswitch stops
  const [isAutoSwitchDisabled, setAutoSwitchDisabled] = useState(false);
  // auto switch will "temporary stop" when the mouse is over the content
  const [isAutoSwitching, setAutoSwitching] = useState(true);

  useEffect(() => {
    if (isAutoSwitching && !isAutoSwitchDisabled) {
      const timer = setTimeout(() => {
        // the condition must be inside and outside the timer:
        // - inside the timer: othewise when we hover it would change slide one extra time
        // - outside the timer: to avoid creating unnessary timers.
        if (isAutoSwitching && !isAutoSwitchDisabled) {
          if (currentPage >= children.length - 1) {
            setSlideDirection("right");
            setCurrentPage(0);
          } else {
            setSlideDirection("left");
            setCurrentPage(currentPage + 1);
          }
        }
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentPage, children, isAutoSwitching, isAutoSwitchDisabled]);

  const handleSelectPage = (index: number) => () => {
    // once we click a spessfic slide, the slides auto change stop
    setAutoSwitchDisabled(true);
    setAutoSwitching(false);
    if (index > currentPage) setSlideDirection("left");
    if (index < currentPage) setSlideDirection("right");
    setCurrentPage(index);
  };

  const handleHoverCarrousel = (isHover: boolean) => () => {
    if (!isAutoSwitchDisabled) {
      setAutoSwitching(!isHover);
    }
  };

  return (
    <CarrouselContainer onMouseOver={handleHoverCarrousel(true)} onMouseOut={handleHoverCarrousel(false)}>
      <Box>
        {children.map((card, index) => (
          <Box key={`card-${index}`} sx={{ display: currentPage === index ? "block" : "none" }}>
            <Slide appear={false} direction={slideDirection} in={currentPage === index}>
              <Box>{card}</Box>
            </Slide>
          </Box>
        ))}
      </Box>
      <CarrouselControls>
        {children.map((_, index) => (
          <IconButton key={`page-select-${index}`} sx={{ padding: "4px" }} onClick={handleSelectPage(index)}>
            {index === currentPage ? <SelectedCarouselButtonSvg /> : <NonSelectedCarouselButtonSvg />}
          </IconButton>
        ))}
      </CarrouselControls>
    </CarrouselContainer>
  );
};

export default Carousel;
