import { Dispatch, SetStateAction } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { MapController } from "./MapController";

interface GatesModuleComponentProps {
  setGatesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const GatesModuleComponent = ({ setGatesModuleData }: GatesModuleComponentProps) => {
  return (
    <>
      <MapController setGatesModuleData={setGatesModuleData} />
    </>
  );
};
