import { useEffect } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { selectLicensedAreasByAllowCorridor, selectLicensedAreasByAllowVmt } from "store/permissionsSelectors";
import { analyticsActions } from "store/sections/analytics";
import { globalActions } from "store/sections/global";

import { FocusAreaItem, MapVisualizationType, ROUTES } from "types";

export const useMapSearchParams = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const datasetEditorRouteMatch = useMatch(ROUTES.DatasetEditor);
  const selectLinkRouteMatch = useMatch(ROUTES.SelectLinkEditor);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const focusArea = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const mode = useAppSelector((state) => state.analytics.mapVisualizationMode);

  const vmtLicenseAreas = useAppSelector(selectLicensedAreasByAllowVmt);
  const corridorLicenseAreas = useAppSelector(selectLicensedAreasByAllowCorridor);

  function getLicensedArea(licensedAreas: FocusAreaItem[], licensedAreaId: string | null | undefined) {
    return licensedAreaId ? licensedAreas.find((licensedArea) => licensedArea.id === licensedAreaId) : undefined;
  }

  useEffect(() => {
    if (focusArea.state === DataState.AVAILABLE) {
      const focusAreaId = searchParams.get("id");
      const firstFocusAreaId = focusArea.data[0].id;
      const searchParamTimePeriod = searchParams.get("timePeriod");
      const searchParamMode = searchParams.get("mode") as MapVisualizationType;

      const setSelectedFocusAreaIdByAccessList = (possibleLicensedAreas: FocusAreaItem[]) => {
        const targetFocusArea =
          // 1º there are a valid licensed area in the state ?
          getLicensedArea(possibleLicensedAreas, selectedFocusAreaId) ??
          // 2º there are a valid licensed area in the query param ?
          getLicensedArea(possibleLicensedAreas, focusAreaId) ??
          // 3º choose the first valid data license.
          possibleLicensedAreas[0];

        if (targetFocusArea && (!selectedFocusAreaId || targetFocusArea.id !== selectedFocusAreaId)) {
          // Use query parameter if is a valid one,
          // otherwise use the default from the licensed area
          const targetTimePeriod =
            searchParamTimePeriod && targetFocusArea.timePeriods.indexOf(searchParamTimePeriod) > 0
              ? searchParamTimePeriod
              : targetFocusArea.timePeriods[0];

          dispatch(
            globalActions.setSelectedFocusAreaId({
              focusAreaId: targetFocusArea.id,
              timePeriod: targetTimePeriod,
            }),
          );
        }
      };

      const setSelectedFocusAreaId = () => {
        if (!selectedFocusAreaId) {
          dispatch(
            globalActions.setSelectedFocusAreaId({
              focusAreaId: focusAreaId || firstFocusAreaId,
              ...(searchParamTimePeriod && !timePeriod ? { timePeriod: searchParamTimePeriod } : {}),
            }),
          );
        }
      };

      const setMode = () => {
        if (mode === null) {
          dispatch(analyticsActions.setMapVisualizationMode(searchParamMode ?? MapVisualizationType.OD));
        }
      };

      const setFocusAreaIdParam = () => {
        if (selectedFocusAreaId && focusAreaId !== selectedFocusAreaId) {
          searchParams.set("id", selectedFocusAreaId);
        }
      };

      const setTimePeriodParam = () => {
        if (timePeriod && searchParamTimePeriod !== timePeriod) {
          searchParams.set("timePeriod", timePeriod);
        }
      };

      const setModeParam = () => {
        if (mode !== null && searchParamMode !== mode) {
          searchParams.set("mode", mode);
        }
      };

      if (datasetEditorRouteMatch || selectLinkRouteMatch) {
        setSearchParams(undefined);
        return;
      }

      if (pathname === ROUTES.Map) {
        setSelectedFocusAreaId();
        setMode();
        setFocusAreaIdParam();
        setTimePeriodParam();
        setModeParam();
        setSearchParams(searchParams, { replace: true });
        return;
      }

      if (pathname === ROUTES.CorridorDiscovery) {
        if (corridorLicenseAreas) {
          setSelectedFocusAreaIdByAccessList(corridorLicenseAreas);
          setMode();
          setFocusAreaIdParam();
          setTimePeriodParam();
          setSearchParams(searchParams, { replace: true });
        }
        return;
      }

      if (pathname === ROUTES.RoadVmt) {
        if (vmtLicenseAreas) {
          setSelectedFocusAreaIdByAccessList(vmtLicenseAreas);
          setMode();
          setFocusAreaIdParam();
          setTimePeriodParam();
          setSearchParams(searchParams, { replace: true });
        }
        return;
      }
      setSelectedFocusAreaId();
      setMode();

      if (searchParams.get("id") !== null) {
        setSearchParams(undefined);
      }
    }
  }, [
    selectedFocusAreaId,
    timePeriod,
    vmtLicenseAreas,
    corridorLicenseAreas,
    mode,
    searchParams,
    pathname,
    focusArea,
    datasetEditorRouteMatch,
    selectLinkRouteMatch,
    setSearchParams,
    dispatch,
  ]);
};
