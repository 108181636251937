import styled from "@emotion/styled";
import { Divider, Switch } from "components_new";
import { ChangeEvent, FC } from "react";

import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { sequentialSchemes } from "features/map/layerColors";

export interface MapLayersProps {
  showZones: boolean;
  colorScheme: string;
  changeShowZones: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  changeColorScheme: (scheme: string) => void;
}

const MapLayersWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RoadVmtMapLayers: FC<MapLayersProps> = ({
  showZones,
  colorScheme,
  changeShowZones,
  changeColorScheme,
}) => {
  return (
    <MapLayersWrapper>
      <Divider sx={{ marginBottom: 1 }}>Map Layers</Divider>
      <>
        <Switch label="Road VMT Counts" checked={showZones} onChange={changeShowZones} />

        <ColorSchemeSelector
          availableSchemes={sequentialSchemes}
          selectedColorScheme={colorScheme}
          onChange={changeColorScheme}
        />
      </>
    </MapLayersWrapper>
  );
};
