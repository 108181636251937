import { Typography, styled } from "@mui/material";
import logo from "assets/png/BentleyLOGO_WHITE_type.png";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import { DOCS_ROUTES } from "types";

const StyledFooter = styled("footer")(({ theme }) => ({
  paddingX: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  color: "#ffffff",
  textAlign: "center",
  letterSpacing: "0.4px",
  fontWeight: 500,
  display: "grid",
  gridTemplateColumns: "1fr 380px 1fr",
  alignItems: "center",
}));

const StyledAnchor = styled("a")(({ theme }) => ({
  color: "#ffffff",
  fontSize: "16px",

  "&:hover": {
    color: "#ffffff",
    textDecoration: "underline",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "#ffffff",
  fontSize: "16px",

  "&:hover": {
    color: "#ffffff",
    textDecoration: "underline",
  },
}));

const TermsContainer = styled("div")({
  justifySelf: "flex-end",
});

const Copyright = styled(Typography)(({ theme }) => ({
  justifySelf: "flex-start",
  fontSize: "16px",
  fontWeight: 600,
}));

const Logo = styled("img")({
  justifySelf: "center",
  width: "120px",
});

export const Footer: FC = () => (
  <StyledFooter>
    <TermsContainer>
      <StyledAnchor href="https://www.bentley.com/legal/privacy-policy/">Privacy Statement</StyledAnchor> |{" "}
      <StyledLink to={DOCS_ROUTES.Terms}>Terms of Use</StyledLink> |{" "}
      <StyledAnchor href="mailto:openpaths-patterns@bentley.com">Contact Us</StyledAnchor>
    </TermsContainer>
    <Logo src={logo} alt="logo" />
    <Copyright>© {new Date().getFullYear()} Bentley Systems, Incorporated</Copyright>
  </StyledFooter>
);
