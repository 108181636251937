import { AccessTime, Check } from "@mui/icons-material";
import { Stack, styled } from "@mui/material";
import area from "@turf/area";
import { multiPolygon, polygon } from "@turf/turf";
import attentionSVG from "assets/svg/attention.svg";
import warningInvertedSVG from "assets/svg/warning-inverted.svg";
import warningSVG from "assets/svg/warning.svg";
import { Button, Checkbox, Dialog, DialogProps } from "components_new";
import React, { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { InfoPane } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { convertSquareMetersToSquareMiles } from "utils/format";

interface ComputeDatasetDialogProps extends DialogProps {
  onClose: () => void;
}

const DatasetSummary = styled("div")`
  display: flex;
  flex-direction: row;
  margin: 32px 100px 24px 100px;
  justify-content: space-between;
`;

const ValidationSummary = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  height: 170px;
`;

const ValidationSummaryHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--color-text-field-gray);
  padding: 0 1rem;
  font-weight: 600;
  font-size: 12px;

  & > div {
    margin-left: 0.5rem;
  }
`;

const ValidationMessages = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  overflow-y: auto;

  & > ul {
    list-style: none;

    & > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5rem;
      font-size: 12px;
      color: var(--color-textSecondary);

      & > img {
        margin-right: 0.5rem;
      }
    }
  }
`;

const NotificationByEmail = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-size: 12px;
  color: var(--color-textSecondary);

  & > span {
    margin-bottom: 0.5rem;
  }
`;

export const ComputeDatasetDialog: FC<ComputeDatasetDialogProps> = ({ open, onClose, ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSetEmailNotification, setIsSetEmailNotification] = React.useState(true);

  const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
  const ODDatasetConfigValidation = useAppSelector((state) => state.analytics.ODDatasetConfigValidation);
  const subareaState = useAppSelector((state) => state.analytics.subareaState);

  useEffect(() => {
    if (
      ODDatasetConfiguration.state === DataState.AVAILABLE &&
      typeof ODDatasetConfiguration.data?.datasetId === "string" &&
      open
    ) {
      dispatch(analyticsActions.validateODDatasetConfig(ODDatasetConfiguration.data.datasetId));
    }
  }, [ODDatasetConfiguration.state, ODDatasetConfiguration.data?.datasetId, open, dispatch]);

  const getSubAreaDescription = useMemo(() => {
    if (ODDatasetConfiguration.data?.subAreaGeometry) {
      const polygonCoordinates = (ODDatasetConfiguration.data.subAreaGeometry as any)?.coordinates;
      const turfPolygon =
        ODDatasetConfiguration.data.subAreaGeometry.type === "MultiPolygon"
          ? multiPolygon(polygonCoordinates)
          : polygon(polygonCoordinates);
      const areaInSquareMeters = area(turfPolygon);

      return Math.floor(convertSquareMetersToSquareMiles(areaInSquareMeters));
    }

    return "0";
  }, [ODDatasetConfiguration.data?.subAreaGeometry]);

  const getZoneTypeDescription = () => {
    if (ODDatasetConfiguration.data?.zoningLevel) {
      const isOne = subareaState.data?.zoneIds.length && subareaState.data.zoneIds.length === 1;

      switch (ODDatasetConfiguration.data.zoningLevel) {
        case "Tract":
          return `${isOne ? "Tract" : "Tracts"}`;
        case "County":
          return `${isOne ? "County" : "Counties"}`;
        case "BlockGroup":
          return `${isOne ? "Block Group" : "Block Groups"}`;
      }
    }

    return "";
  };

  const handleStartComputation = () => {
    if (ODDatasetConfiguration.data?.datasetId) {
      dispatch(
        analyticsActions.computeODDataset(ODDatasetConfiguration.data.datasetId, isSetEmailNotification, navigate),
      );

      onClose();
    }
  };

  const errors = ODDatasetConfigValidation.data?.messages.filter((message) => message.severity === "error");
  const warnings = ODDatasetConfigValidation.data?.messages.filter((message) => message.severity === "warning");

  const handleChangeEmailNotification = () => {
    setIsSetEmailNotification(!isSetEmailNotification);
  };

  return (
    <Dialog
      open={open}
      title={`Compute dataset - ${ODDatasetConfiguration.data?.datasetName}`}
      onClose={onClose}
      actions={
        <Button
          size="medium"
          disabled={
            ODDatasetConfigValidation.state === DataState.LOADING || ODDatasetConfigValidation.data?.isValid === false
          }
          onClick={handleStartComputation}
        >
          Start Computation
        </Button>
      }
      {...props}
    >
      <Stack>
        <DatasetSummary>
          <InfoPane
            value={subareaState.data?.zoneIds.length || "0"}
            label={getZoneTypeDescription()}
            description="Zones"
          />
          <InfoPane value={getSubAreaDescription || ""} label="Square Miles" description="Subarea" />
          <InfoPane
            value={ODDatasetConfiguration.data?.gates?.length || "0"}
            label={`External Gate${ODDatasetConfiguration.data?.gates?.length === 1 ? "" : "s"}`}
            description="Gates"
          />
        </DatasetSummary>
        <ValidationSummary>
          <ValidationSummaryHeader>
            {ODDatasetConfigValidation.state === DataState.LOADING && (
              <AccessTime fontSize="small" sx={{ color: "#6b7280" }} />
            )}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE &&
              (errors?.length !== 0 || warnings?.length !== 0) && <img src={warningSVG} alt="" width={16} />}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE &&
              errors?.length === 0 &&
              warnings?.length === 0 && <Check fontSize="small" sx={{ color: "#34d399" }} />}
            {ODDatasetConfigValidation.state === DataState.AVAILABLE && (
              <div>{`${errors?.length} error${errors?.length === 1 ? "" : "s"} | ${warnings?.length} warning${
                warnings?.length === 1 ? "" : "s"
              }`}</div>
            )}
            {ODDatasetConfigValidation.state === DataState.LOADING && <div>validating...</div>}
          </ValidationSummaryHeader>
          <ValidationMessages>
            <ul>
              {ODDatasetConfigValidation.data?.messages &&
                ODDatasetConfigValidation.data?.messages.map((item, i) => (
                  <li key={i}>
                    {item.severity === "error" && <img src={warningInvertedSVG} alt="" width={16} />}
                    {item.severity === "warning" && <img src={attentionSVG} alt="" width={16} />}
                    {item.text}
                  </li>
                ))}
            </ul>
          </ValidationMessages>
        </ValidationSummary>
        <NotificationByEmail>
          <span>
            Billions of trip data points will be processed to compute the dataset. It can take up to a few hours
          </span>
          <Checkbox
            label="Notify me by email when the dataset is ready"
            checked={isSetEmailNotification}
            onChange={handleChangeEmailNotification}
          />
        </NotificationByEmail>
      </Stack>
    </Dialog>
  );
};
