import { Box, SxProps } from "@mui/material";
import * as React from "react";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, sx, ...props }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...props}>
      {value === index && <Box sx={sx}>{children}</Box>}
    </div>
  );
};
