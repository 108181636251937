import { FC } from "react";

import { Table, TableHeadRow } from "./Table";

interface FileColumn {
  column: string;
  type: string;
  description: any;
  notes: string;
}

export interface FileTableProps {
  fileColumns: FileColumn[];
}

export const FileTable: FC<FileTableProps> = ({ fileColumns }) => {
  return (
    <Table>
      <thead>
        <TableHeadRow>
          <th>Column</th>
          <th>Type</th>
          <th>Description</th>
          <th>Notes</th>
        </TableHeadRow>
      </thead>
      <tbody>
        {fileColumns.map((f, i) => (
          <tr key={i}>
            <td width={"150px"}>{f.column}</td>
            <td width={"150px"}>{f.type}</td>
            <td>{f.description}</td>
            <td width={"150px"}>{f.notes}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
