import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import { Dialog } from "components_new";
import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

import { CreateCustomZoning } from "./CreateCustomZoning";
import { UploadZoningFiles } from "./UploadZoningFiles";

interface Props {
  folderId: string;
  onClose: () => void;
}

const steps = ["Upload", "Prepare", "Create"];

const CustomStep = styled(Step)`
  .Mui-active svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ZoningUploader = ({ folderId, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [wasPrepared, setWasPrepared] = useState(false);

  const zoningUploading = useAppSelector((state) => state.datasetFolders.zoningUploading);
  const zoningPreparing = useAppSelector((state) => state.datasetFolders.zoningPreparing);
  const zoningRequestLoading = useAppSelector((state) => state.datasetFolders.loading);
  const errorDialogMessage = useAppSelector((state) => state.datasetFolders.errorDialogMessage);

  useEffect(() => {
    if (activeStep === 0 && zoningUploading.state === DataState.AVAILABLE) {
      setActiveStep(1);
    } else if (activeStep === 1 && zoningPreparing.state === DataState.AVAILABLE && !wasPrepared) {
      setActiveStep(2);
      setWasPrepared(true);
    }
  }, [activeStep, wasPrepared, zoningUploading.state, zoningPreparing.state]);

  useEffect(() => {
    if (zoningPreparing.state === DataState.LOADING && activeStep === 1) {
      setWasPrepared(false);
    }
  }, [zoningPreparing.state, activeStep]);

  const handleCloseZoningUploadDialog = () => {
    dispatch(datasetFoldersActions.deleteZoning());

    onClose();
  };

  const handleChangeStep = (step: number) => {
    if (
      step === 0 ||
      (step === 1 && zoningUploading.state !== DataState.AVAILABLE) ||
      (step === 2 && !isPrepared) ||
      step === activeStep
    ) {
      return false;
    }

    setActiveStep(step);
  };

  const isPrepared = zoningPreparing.state === DataState.AVAILABLE;

  const getCompletedStatus = (index: number) => {
    if (index === 0) {
      return zoningUploading.state === DataState.AVAILABLE;
    }

    return false;
  };

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={handleCloseZoningUploadDialog} title="Create custom zoning">
      <Stepper activeStep={activeStep} style={{ width: "50%", margin: "0 auto 16px auto" }} nonLinear>
        {steps.map((label, index) => (
          <CustomStep key={label} completed={getCompletedStatus(index)}>
            <StepButton disableRipple onClick={() => handleChangeStep(index)}>
              {label}
            </StepButton>
          </CustomStep>
        ))}
      </Stepper>

      {activeStep === 0 && <UploadZoningFiles zoningUploading={zoningUploading} />}
      {zoningUploading.state === DataState.AVAILABLE && (
        <CreateCustomZoning
          loading={zoningPreparing.state === DataState.LOADING || zoningRequestLoading}
          defaultZoningName={zoningUploading.data?.defaultName || ""}
          zoning={activeStep === 2 && isPrepared ? zoningPreparing.data.zoning : zoningUploading.data.zoning}
          folderId={folderId}
          activeStep={activeStep}
          errorMessage={errorDialogMessage || zoningPreparing.error?.body?.what || null}
          successMessage={isPrepared && activeStep === 2 ? "The zoning has been successfully prepared" : null}
        />
      )}
    </Dialog>
  );
};
