import {
  CircularProgress,
  CircularProgressProps,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  alpha,
  styled,
} from "@mui/material";
import * as React from "react";

interface MuiStyledButtonProps extends MuiButtonProps {
  loading?: boolean;
}

const StyledMuiButton = styled(
  (props: MuiStyledButtonProps) => (
    //Default props override
    <MuiButton disableElevation disableFocusRipple disableRipple disableTouchRipple {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== "loading",
  },
)(
  //Default styles override
  ({ loading, theme }) => ({
    textTransform: "none",

    "&.MuiButton-outlined": {
      backgroundColor: "#fff",
    },

    "&.MuiButton-outlinedPrimary": {
      borderColor: alpha(theme.palette.primary.main, 0.2),
    },

    "&.MuiButton-outlinedSecondary": {
      borderColor: alpha(theme.palette.secondary.main, 0.2),
    },

    "& .buttonCircularProgress": {
      display: loading ? "inline-block" : "none",
    },

    "& .buttonLabel": {
      visibility: loading ? "hidden" : "visible",
    },

    "& .MuiButton-endIcon, .MuiButton-startIcon": {
      visibility: loading ? "hidden" : "visible",
    },

    "&.MuiButton-sizeSmall": {
      fontSize: theme.typography.pxToRem(12),

      "& .buttonCircularProgress": {
        width: `${theme.typography.pxToRem(18)} !important`,
        height: `${theme.typography.pxToRem(18)} !important`,
      },
    },
    "&.MuiButton-sizeMedium": {
      fontSize: theme.typography.pxToRem(14),

      "& .buttonCircularProgress": {
        width: `${theme.typography.pxToRem(20)} !important`,
        height: `${theme.typography.pxToRem(20)} !important`,
      },
    },
    "&.MuiButton-sizeLarge": {
      fontSize: theme.typography.pxToRem(16),

      "& .buttonCircularProgress": {
        width: `${theme.typography.pxToRem(22)} !important`,
        height: `${theme.typography.pxToRem(22)} !important`,
      },
    },
  }),
);

const ButtonCircularProgress = styled((props: CircularProgressProps) => (
  <CircularProgress color="inherit" {...props} />
))(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  margin: "auto",
}));

//Excluding unused props
type ButtonBaseProps = Omit<
  MuiButtonProps,
  | "centerRipple"
  | "disableFocusRipple"
  | "disableElevation"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "TouchRippleProps"
  | "touchRippleRef"
>;

//Extending props
export interface ButtonProps extends ButtonBaseProps {
  loading?: boolean;
}

//Component implementation
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ color = "primary", variant = "contained", size = "small", loading, children, ...props }, ref) => {
    return (
      <StyledMuiButton ref={ref} color={color} variant={variant} size={size} loading={loading} {...props}>
        {loading && <ButtonCircularProgress className="buttonCircularProgress" />}
        <div className="buttonLabel">{children}</div>
      </StyledMuiButton>
    );
  },
);
