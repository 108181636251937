import { Gate, ODTileService, RoadClassCategory, RoadsTileService, TileServiceMetadata, VectorLayer } from "types";

import { parseTileServiceUrl } from "utils/parse";

export const getLayersZoomRange = (layers: VectorLayer[]) => {
  return layers.reduce((obj: { [key: string]: { minzoom: number; maxzoom: number } }, layer) => {
    obj[layer.id] = { minzoom: layer.minzoom, maxzoom: layer.maxzoom };
    return obj;
  }, {});
};

export const extendODMetadata = (
  odTileService: ODTileService,
  tileServiceMetadata?: TileServiceMetadata,
): ODTileService => {
  if (tileServiceMetadata) {
    const layersZoomRange = getLayersZoomRange(tileServiceMetadata.vector_layers);
    return {
      url: parseTileServiceUrl(odTileService.url),
      layers: odTileService.layers.map((layer) => ({
        ...layer,
        url: parseTileServiceUrl(layer.url),
        ...layersZoomRange[layer.name],
      })),
      minZoom: tileServiceMetadata.minzoom,
      maxZoom: tileServiceMetadata.maxzoom,
    };
  } else {
    const baseUrl = process.env.REACT_APP_API_HOST;

    return {
      ...odTileService,
      url: parseTileServiceUrl(odTileService.url),
      layers: odTileService.layers.map((layer) => ({
        ...layer,
        url: parseTileServiceUrl(layer.url),
        ...(baseUrl && !layer.url.includes(baseUrl) ? { name: layer.level } : {}),
      })),
    };
  }
};

export const extendRoadsMetadata = (
  roadsTileService: RoadsTileService,
  tileServiceMetadata: TileServiceMetadata,
): RoadsTileService => ({
  ...roadsTileService,
  minZoom: tileServiceMetadata.minzoom,
  maxZoom: tileServiceMetadata.maxzoom,
});

export const isSameGate = (gateA: Gate, gateB: Gate) => {
  const segmentsA = gateA.segments || [];
  const segmentsB = gateB.segments || [];
  for (let segment of segmentsA) {
    if (segmentsB.find((s) => s.id === segment.id)) return true;
  }
  return false;
};

export const getRoadCategoryFromFactype = (factype: number) => {
  switch (factype) {
    case 1:
      return RoadClassCategory.LIMITED_ACCESS;
    default:
      return RoadClassCategory.OTHER;
  }
};
