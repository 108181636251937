import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { buildFilters } from "features/filters/utils";

import { corridorActions } from "store/sections/corridor";

import { CorridorEdge, FiltersType, MeasureType } from "types";

export const useFetchCorridorMetadata = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null) => {
      if (timePeriod) {
        dispatch(
          corridorActions.fetchCorridorMetadata({
            timePeriod,
            includeDisabled: false,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchCorridorEdgeIds = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, corridorLevel: string) => {
      if (timePeriod) {
        dispatch(
          corridorActions.fetchCorridorEdgeIds({
            timePeriod,
            level: corridorLevel,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchCorridorEdgeCounts = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, corridorLevel: string, filters: FiltersType) => {
      if (timePeriod) {
        dispatch(
          corridorActions.fetchCorridorEdgeCounts({
            timePeriod,
            level: corridorLevel,
            measure: MeasureType.AADT,
            filter: buildFilters(filters),
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchCorridorNodeIds = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, corridorLevel: string) => {
      if (timePeriod) {
        dispatch(
          corridorActions.fetchCorridorNodeIds({
            timePeriod,
            level: corridorLevel,
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchCorridorNodeCounts = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string | null, corridorLevel: string, filters: FiltersType) => {
      if (timePeriod) {
        dispatch(
          corridorActions.fetchCorridorNodeCounts({
            timePeriod,
            level: corridorLevel,
            measure: MeasureType.AADT,
            filter: buildFilters(filters),
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchServiceOverlayLayers = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(corridorActions.fetchServiceOverlayLayers());
  }, [dispatch]);
};

export const useSetSelectedEdge = () => {
  const dispatch = useDispatch();

  return useCallback(
    (edge: CorridorEdge | null) => {
      dispatch(corridorActions.setSelectedCorridorEdge(edge));
    },
    [dispatch],
  );
};

export const useSetSelectedEdgeId = () => {
  const dispatch = useDispatch();

  return useCallback(
    (edgeId: string | null) => {
      dispatch(corridorActions.setSelectedCorridorEdgeDirectionId(edgeId));
    },
    [dispatch],
  );
};

export const useFetchEdgeDetails = () => {
  const dispatch = useDispatch();

  return useCallback(
    (edgeId: number, timePeriod: string | null, corridorLevel: string, filters: FiltersType, dimensions: string[]) => {
      if (timePeriod) {
        const breakdowns = dimensions.map((d: string) => ({
          dimensions: [d],
          includeUnfiltered: false,
        }));

        dispatch(
          corridorActions.fetchCorridorEdgeDetails({
            edgeId,
            timePeriod,
            level: corridorLevel,
            measure: MeasureType.AADT,
            summary: {
              breakdowns,
              filteredTotal: true,
              unfilteredTotal: false,
            },
            filter: buildFilters(filters),
          }),
        );
      }
    },
    [dispatch],
  );
};

export const useFetchCorridorHeatmapConfiguration = () => {
  const dispatch = useDispatch();

  return useCallback(
    (timePeriod: string, corridorLevel: string, filters: FiltersType) => {
      dispatch(
        corridorActions.fetchCorridorHeatmapConfiguration({
          timePeriod,
          level: corridorLevel,
          measure: MeasureType.AADT,
          filter: buildFilters(filters),
          parameters: {},
        }),
      );
    },
    [dispatch],
  );
};
