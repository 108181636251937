import styled from "@emotion/styled";
import { CircularProgress } from "components_new";
import React, { FC } from "react";

import { StatePageContainer } from "components";

const LoadingContainer = styled.div`
  margin: auto;
`;

const LoadingHeading = styled.h3`
  font-weight: 500;
  margin-top: 0.5rem;
`;

export const LoadingPage: FC = () => (
  <StatePageContainer>
    <LoadingContainer>
      <CircularProgress size={60} />
      <LoadingHeading>Loading...</LoadingHeading>
    </LoadingContainer>
  </StatePageContainer>
);
