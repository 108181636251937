import { FileDownload } from "@mui/icons-material";
import { MenuItem, styled } from "@mui/material";
import { Button, TextField } from "components_new";
import React, { ChangeEvent, FC, MutableRefObject, memo, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { AreaName, FocusAreaDropdown, LeftSidebar } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { exportActions } from "store/sections/export";
import { globalActions } from "store/sections/global";
import { mapActions } from "store/sections/map";
import { roadVmtActions } from "store/sections/roadVmt";

import { RoadVmtExportDialog } from "./RoadVmtExportDialog";
import { RoadVmtFilters } from "./RoadVmtFilters";
import { RoadVmtLegend } from "./RoadVmtLegend";
import { RoadVmtMapLayers } from "./RoadVmtMapLayers";
import { ROAD_VMT_LAYER_PREFIX } from "./map-data/layers";
import { getAvailableLayers } from "./utils";

interface RoadVmtMapControlsPanelProps {
  mapController: MutableRefObject<null | any>;
  colorScaleFn?: Function | null;
}

const FiltersContainer = styled("div")`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 120px);
  margin: 0.5em -1rem 0 0;
  padding: 0 1rem 0 2px;
`;

const AreaNameContainer = styled("div")`
  margin: 1rem 0;
  display: flex;
`;

const ExportButton = styled(Button)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const RoadVmtMapControlsPanel: FC<RoadVmtMapControlsPanelProps> = memo(({ mapController, colorScaleFn }) => {
  const dispatch = useAppDispatch();

  const [showZones, setShowZones] = useState<boolean>(true);

  const isRoadVmtExportAllowed = useAppSelector(
    (state) => state.roadVmt.roadVmtMetadata.data?.exportPermissions.allowExport,
  );

  const colorScheme = useAppSelector((state) => state.map.colorScheme);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const permissions = useAppSelector((state) => state.license.permissions);

  const roadVmtMetadata = useAppSelector((state) => state.roadVmt.roadVmtMetadata);

  //Export
  const newExport = useAppSelector((state) => state.export.newExport);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

  const eligibleFocusAreas = useMemo(
    () =>
      focusAreas.data?.filter(
        (area) =>
          !area.datasetId &&
          permissions.data?.licensedAreas.find(
            (a) => a.licensedAreaId.toString() === area.licensedAreaId && a.dataDetail.roadVmtDetail,
          ),
      ) || [],
    [focusAreas.data, permissions.data],
  );

  const filterLoading = useMemo(() => {
    return roadVmtMetadata.state === DataState.LOADING;
  }, [roadVmtMetadata]);

  const selectedFocusArea = useMemo(
    () => eligibleFocusAreas?.find((area) => area.id === selectedFocusAreaId) || null,
    [eligibleFocusAreas, selectedFocusAreaId],
  );

  const isDataset = useMemo(() => Boolean(selectedFocusArea?.datasetId), [selectedFocusArea?.datasetId]);

  const isExportDisabled = useMemo(() => isRoadVmtExportAllowed === false, [isRoadVmtExportAllowed]);

  const handleToggleShowZones = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (mapController.current && roadVmtMetadata.data) {
      setShowZones(checked);

      getAvailableLayers(roadVmtMetadata.data).forEach((layer) => {
        mapController.current.layerManager.updateLayerLayout(
          `${ROAD_VMT_LAYER_PREFIX}_${layer.name}`,
          "visibility",
          checked ? "visible" : "none",
        );
      });
    }
  };

  const changeColorScheme = (scheme: string) => {
    dispatch(mapActions.setColorScheme(scheme));
  };

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
    }
  };

  const handleChangeTimePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(globalActions.setTimePeriod(event.target.value));
  };

  useEffect(() => {
    return () => {
      dispatch(roadVmtActions.clearRoadVmtMetadata());
      dispatch(roadVmtActions.updateCurrentFilters(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (newExport.data) {
      toast.success("New export job added", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.data, dispatch]);

  useEffect(() => {
    if (newExport.error) {
      toast.error("Failed to add new export job", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(exportActions.clearNewExport());
      toast.clearWaitingQueue();
    }
  }, [newExport.error, dispatch]);

  return (
    <>
      <LeftSidebar>
        <FocusAreaDropdown
          sx={{ mb: 2 }}
          loading={focusAreas.state === DataState.LOADING}
          disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
          options={eligibleFocusAreas}
          value={selectedFocusArea}
          onChange={handleChangeFocusArea}
        />
        {isDataset && (
          <AreaNameContainer>
            <AreaName>{selectedFocusArea?.region}</AreaName>
          </AreaNameContainer>
        )}

        <TextField
          fullWidth
          select
          value={timePeriod || ""}
          label="Time Period"
          disabled={(selectedFocusArea?.timePeriods?.length ?? 1) <= 1}
          onChange={handleChangeTimePeriod}
          sx={{ mb: 2 }}
        >
          {selectedFocusArea?.timePeriods?.map((timePeriod) => (
            <MenuItem key={timePeriod} value={timePeriod}>
              {timePeriod}
            </MenuItem>
          ))}
        </TextField>

        <ExportButton
          startIcon={<FileDownload />}
          color="secondary"
          disabled={isExportDisabled}
          onClick={() => setIsExportDialogOpen(true)}
        >
          New Export
        </ExportButton>

        <FiltersContainer>
          <RoadVmtFilters loading={filterLoading} />
          <RoadVmtMapLayers
            showZones={showZones}
            changeShowZones={handleToggleShowZones}
            colorScheme={colorScheme}
            changeColorScheme={changeColorScheme}
          />
        </FiltersContainer>
        {colorScaleFn && <RoadVmtLegend colorScaleFn={colorScaleFn} colorScheme={colorScheme} />}
      </LeftSidebar>
      {isExportDialogOpen && (
        <RoadVmtExportDialog open={isExportDialogOpen} onClose={() => setIsExportDialogOpen(false)} />
      )}
    </>
  );
});
