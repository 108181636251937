import { styled } from "@mui/material";
import { FC } from "react";

import { PageHeader } from "./PageHeader";

// !Important: Styles should be the exact copy of the styles in auth-manage(terms)
const TermsContainer = styled("div")`
  max-height: calc(100vh - 378px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: none;
  line-height: 1.28581;
  letter-spacing: 0;
  font-size: 14pt;
  color: #000000;
  font-family: "Inter", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Open Sans", "Helvetica Neue", "Icons16", sans-serif;

  .introductory {
    font-size: 15pt;
    margin-bottom: 20pt;
  }

  .date {
    font-size: 14pt;
    font-weight: 400;
    text-align: right;
    margin-top: 10pt;
  }

  .terms {
    background-color: #e5e5e5;
    padding: 40px;
    overflow: auto;
    word-break: break-word;
  }

  .heading {
    color: #000000;
    font-weight: bold;
    margin: 5pt 0;
    padding: 0;
  }

  .title {
    margin-bottom: 30px;
  }

  .title .center {
    text-align: center;
  }

  h1.heading {
    line-height: 40pt;
    font-size: 36pt;
  }

  h2.heading {
    line-height: 32pt;
    font-size: 28pt;
  }

  h3.heading {
    line-height: 25pt;
    font-size: 22pt;
  }

  h4.heading {
    line-height: 21pt;
    font-size: 18pt;
    padding-top: 5pt;
  }

  h5.heading {
    line-height: 19pt;
    font-size: 16pt;
    padding-top: 3pt;
  }

  h6.heading {
    line-height: 16pt;
    font-size: 14pt;
  }

  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3pt;
    cursor: pointer;
    padding: 8pt 14pt;
    font-size: 18pt;
    min-width: 40px;
    min-height: 40px;
    background-color: #0067b0;
    color: #fff;
  }

  .button:disabled {
    outline: none;
    background-color: rgba(206, 217, 224, 0.5);
    background-image: none;
    cursor: not-allowed;
    color: rgba(92, 112, 128, 0.6);
  }

  a {
    color: #0067b0;
    text-decoration: none;
  }

  ul li {
    margin-bottom: 10pt;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10pt;
    margin-bottom: 20pt;
  }

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }
`;

export const TermsOfUse: FC = () => (
  <>
    <PageHeader title="Terms of Use" />{" "}
    <TermsContainer>
      <div className="terms">
        <section>
          <div className="date">Last Updated: July 25th, 2024</div>
        </section>
        <section>
          <h3 className="heading">1 Bentley and the Site Services</h3>
          <p>
            Bentley Systems, Incorporated ("<b>Bentley</b>"; also "<b>we</b>," "<b>our</b>," or "<b>us</b>") is a
            technology company that works with wireless carriers and other data suppliers to provide aggregated insights
            to various clients, mainly in the mobility, transportation and smart cities sectors (collectively, the "
            <b>Services</b>"). The purpose of the website is to enable new and existing clients to log in and access our
            Services, applications, products, and data, hereinafter collectively referred to as the ("
            <b>Site Services</b>
            ").
          </p>
        </section>
        <section>
          <h3 className="heading">2 Overview of this Agreement</h3>
          <p>
            This agreement ("<b>Terms of Use</b>" or "<b>Agreement</b>") explains the terms under which you are allowed
            to access and/or use the website, and applications (collectively, the "<b>Site</b>") offered by Bentley. The
            Agreement includes terms regarding privacy, limitations on liability, choice of governing law and
            jurisdiction, and where applicable, WAIVER OF CLASSNaclassName ACTIONS AND JURY TRIALS, AND RESOLUTION OF
            DISPUTES BY ARBITRATION INSTEAD OF IN COURT, among other things. Please read these Terms of Use carefully
            before visiting our Site.
          </p>
          <p>
            This Agreement applies to all who access the Site as a visitor, user, or otherwise ("<b>you</b>"). If you do
            not agree to these terms, you may not visit, use, or access the Site or Site Services as a Site visitor,
            user, or otherwise, and may not click to accept the Terms of Use when prompted on the Site. This Agreement
            incorporates by reference Bentley's{" "}
            <a href="https://www.bentley.com/legal/privacy-policy/" target="_blank" rel="noreferrer">
              privacy policy
            </a>{" "}
            ("<b>Privacy Policy</b>"),{" "}
            <a href="https://www.bentley.com/legal/cookie-policy/" target="_blank" rel="noreferrer">
              cookie policy
            </a>
            , and{" "}
            <a href="https://www.bentley.com/legal/data-processing-addendum/" target="_blank" rel="noreferrer">
              data processing addendum("<b>DPA</b>")
            </a>
            .
          </p>
          <p>
            YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY DOWNLOADING, INSTALLING, COPYING, ACCESSING OR USING
            THE SITE SERVICES AND THE SITE. YOUR ACCEPTANCE OF ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT IS A
            CONDITION TO THE GRANT OF LICENSE, ACCESS AND USE BELOW. THIS AGREEMENT, AS MAY BE MODIFIED BY ANY
            APPLICABLE SIGNED WRITTEN AGREEMENT BETWEEN YOU AND BENTLEY, REPRESENTS THE ENTIRE SET OF TERMS AND
            CONDITIONS GOVERNING YOUR USE OF THE SITE SERVICES AND THE SITE AND SUPERSEDES ALL PRIOR OR CONTEMPORANEOUS
            ORAL OR WRITTEN COMMUNICATIONS, PROPOSALS AND PRESENTATIONS WITH RESPECT TO THE SITE SERVICES AND THE SITE
            OR THE SUBJECT MATTER OF THE AGREEMENT.
          </p>
          <p>
            Bentley may update or amend this Agreement and any Terms of Service and/or Site Policies at any time without
            prior notice to you. If any changes are made, the "last updated" date given above will be revised. Revisions
            will take effect when posted, unless stated otherwise.
          </p>
        </section>
        <section>
          <h3 className="heading">3 Users with Existing Contracts in Place</h3>
          <p>
            If, other than these Terms of Use, you or your organization has entered into a separate written contract
            with Bentley ("<b>Existing Client Contract</b>") and the Existing Client Contract is still in force, you
            agree as a condition of using the Site Services to be bound by both the terms of the Existing Client
            Contract and these Terms of Use. An Existing Client Contract may define additional rights or privileges not
            specifically granted under these Terms and Conditions. If any of the provisions of this Agreement directly
            conflict with the Existing Client Contract, the terms of the Existing Client Contract shall prevail.
          </p>
        </section>
        <section>
          <h3 className="heading">4 Intellectual Property Ownership and Licenses</h3>
          <h4 className="heading">4.1 Intellectual Property Ownership and Licenses</h4>
          <p>
            Bentley owns and retains all right, title, and interest in the Site, Site Services, and licensed data ("
            <b>Licensed Data</b>") and in all of Bentley’s patents, registrations, filings, applications, trademarks,
            trade names, logos, inventions, copyrights, know-how, trade secrets and other intellectual property relating
            to the design, processing, production, operation or service of its business. For avoidance of doubt,
            Licensed Data includes any reports that may be provided to you that include aggregated and statistical data
            and insights. Except as expressly provided herein, nothing in the Agreement will transfer, assign, license
            or otherwise grant any party any right or interest in any other party’s Intellectual Property Rights.
          </p>
          <p>
            The Bentley logos and names are trademarks of Bentley and are registered in certain jurisdictions. Any other
            product names, company names, marks, logos, and symbols on the Site or Site Services may be the trademarks
            of their respective owners.
          </p>
          <h4 className="heading">4.2 Limited License To Site and Data</h4>
          <p>
            Bentley grants you a nonexclusive, nontransferable, non-sublicensable, revocable, limited license to access
            the Site, Site Services, and aggregated insights provided through the Site ("<b>Licensed Data</b>"). This
            limited license is subject to and conditioned on agreement and compliance with these Terms of Use (in
            particular, the conditions set forth in Section 5 below regarding permitted and prohibited uses of the Site)
            and Bentley's{" "}
            <a href="https://www.bentley.com/legal/data-processing-addendum/" target="_blank" rel="noreferrer">
              Data Processing Addendum
            </a>
            , which are incorporated by reference.
          </p>
          <p>
            Title to the Site, Site Services, and Licensed Data, and all rights with respect thereto not specifically
            granted under this Agreement, including without limitation, all rights of reproduction, modification,
            distribution, and all copyright, patent, trademark, trade secret and other proprietary rights and interests,
            are reserved to Bentley.
          </p>
          <p>
            All use of the Licensed Data inures solely to the benefit of Bentley , and nothing conveys to you or any
            party you represent any right, title or interest in or to the Licensed Data or any other Intellectual
            Property other than the right to use the Licensed Data for the purposes subject to this Agreement. Subject
            to the foregoing, any and all use or display of the Licensed Data will retain and provide clear and
            prominent attribution to indicate origin from and ownership by Bentley in a manner that reflects favorably
            upon Bentley.
          </p>
          <h4 className="heading">4.3 Your Data Ownership</h4>
          <p>
            To the extent that you provide Bentley with any data or other information ("<b>User Submitted Data</b>"),
            all ownership rights, intellectual property rights, title, and interest, in the Data shall be owned by and
            deemed your property.
          </p>
          <h4 className="heading">4.4 License to Bentley for User Submitted Data</h4>
          <p>
            You hereby grant Bentley and its Affiliates a royalty-free, perpetual right and license to use, analyze,
            aggregate, modify, enhance and create derivative works from the User Submitted Data for purposes of
            providing the Site Services and Licensed Data to the Registered User and for Bentley's lawful business
            purposes, including modifying, updating and improving products and services.
          </p>
        </section>
        <section>
          <h3 className="heading">5 Permitted and Prohibited Site Uses</h3>
          <h4 className="heading">5.1 Permitted Site Uses</h4>
          <p>
            Bentley grants access to the Site, Site Services, and Licensed Data to Registered Users solely for lawful,
            internal, and non-commercial purposes.
          </p>
          <h4 className="heading">5.2 Prohibited Site/Licensed Data Uses</h4>
          <p>The following is a non-exhaustive list of examples of prohibited uses of the Site and Licensed Data:</p>
          <ul>
            <li>
              reselling, renting, decompiling, reverse engineering or creating derivative works from the Licensed Data,
              unless otherwise explicitly specified herein;
            </li>

            <li>using any of the Licensed Data for commercial use, unless covered by and Existing Client Contract.;</li>

            <li>
              attempting to re-identify or decrypt the identity of any person(s) or device from the Licensed Data;
            </li>

            <li>
              using the Licensed Data as part of any effort to compete with Bentley or otherwise using the Site and/or
              the content for any revenue-generating endeavor or commercial enterprise;
            </li>

            <li>
              using, or encouraging, promoting, facilitating, instructing, or inducing others to use, the Site, Site
              Services, or Licensed Data for any activity that violates any law, statute, ordinance or regulation; for
              any other illegal or fraudulent purpose or any purpose that is harmful to others;
            </li>

            <li>
              submitting information to Bentley that is untrue, inaccurate, incomplete, misleading, or not current; or
              failing to maintain the accuracy of such information;
            </li>

            <li>becoming a Bentley Registered User before reaching the age of 18 or using the Site as a "minor";</li>

            <li>accessing web interface through non-human means (i.e. bots, scripts or otherwise);</li>

            <li>
              systematically retrieving data or other content from the Site to create or compile, directly or
              indirectly, a collection, compilation, database, or directory without written permission from Bentley;
            </li>

            <li>
              making any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
              electronic or other means for the purpose of sending unsolicited email, or registering a User by automated
              means or under false pretenses;
            </li>

            <li>circumventing, disabling, or otherwise interfering with security-related features of the Site;</li>

            <li>engaging in unauthorized framing of or linking to the Site;</li>

            <li>
              engaging in any automated use of the system, such as using scripts to send comments or messages, or using
              any data mining, robots, or similar data gathering and extraction tools, including artificial intelligence
              and machine learning applications, without Bentley's prior written consent;
            </li>

            <li>
              interfering with, disrupting, or creating an undue burden on the Site or the networks or services
              connected to the Site;
            </li>

            <li>attempting to impersonate another user or person or using the username of another user;</li>

            <li>selling or otherwise transferring Registered User information;</li>

            <li>using any information obtained from the Site in order to harass, abuse, or harm another person;</li>

            <li>
              deciphering, decompiling, disassembling, or reverse engineering any of the software comprising or in any
              way making up a part of the Site;
            </li>

            <li>
              attempting to bypass any measures of the Site designed to prevent or restrict access to the Site or any
              portion of the Site;
            </li>

            <li>
              copying or adapting the Site's software, including but not limited to Flash, PHP, HTML, JavaScript, or any
              other code;
            </li>

            <li>
              uploading or transmitting (or attempting to upload or to transmit) viruses, Trojan horses, or other
              malicious material, including excessive use of capital letters and spamming (continuous posting of
              repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Site or that
              modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or
              maintenance;
            </li>

            <li>
              uploading or transmitting (or attempting to upload or to transmit) any material that acts as a passive or
              active information collection or transmission mechanism, including without limitation, clear graphics
              interchange formats ("gifs"), 1x1 pixels, web bugs, cookies, or other similar devices (sometimes referred
              to as "spyware" or "passive collection mechanisms" or "PCMs");
            </li>

            <li>uploading content for which you do not have the copyright or authorization of the copyright holder;</li>

            <li>
              except as may be the result of standard search engine or internet browser usage, using, launching,
              developing, or distributing any automated system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Site, or using or launching any unauthorized script
              or other software, unless previously agreed by Bentley in written form;
            </li>

            <li>
              disparaging, tarnishing, or otherwise harming, in Bentley's opinion, Bentley and/or the Bentley's Site;
            </li>
          </ul>
          <h4 className="heading">5.3 Enforcement</h4>
          <p>Bentley reserves the right, but not the obligation, to:</p>
          <ul>
            <li>monitor the Site for violations of these Terms of Use;</li>

            <li>
              terminate Registered Users and ban them from using of the Site, where users engage in prohibited uses of
              the Site;
            </li>

            <li>terminate licenses to the Site and Licensed Data;</li>

            <li>
              take appropriate legal action in its sole discretion against anyone who violates the law or these Terms of
              Use, including without limitation, reporting such user to law enforcement authorities;
            </li>

            <li>
              otherwise manage the Site in a manner designed to protect Bentley's rights and property and to facilitate
              the proper functioning of the Site.
            </li>
          </ul>
          <h4 className="heading">5.4 Reporting and Correcting Violations</h4>
          <p>
            If you become aware of any violation of this Terms of Use and/or of any of Bentley's Intellectual Property
            rights, you must immediately report it to Bentley's team through e-mail at{" "}
            <a href="mailto:contracts@bentley.com">contracts@bentley.com</a>. You agree to assist Bentley with any
            investigation it undertakes and to take any remedial steps Bentley requires in order to correct a violation
            of these Terms of Use.
          </p>
        </section>
        <section>
          <h3 className="heading">6 Data Privacy</h3>
          <p>
            Bentley may collect registration and other information about you through our Services. Bentley's collection
            and use of this information is governed by our{" "}
            <a href="https://www.bentley.com/legal/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            . Bentley cares about the integrity and security of your personal information and has implemented
            commercially reasonable technical and organizational measures designed to secure your personal information
            from accidental loss and from unauthorized access, use, alteration or disclosure. However, Bentley cannot
            guarantee that unauthorized third parties will never be able to defeat our security measures or use your
            personal information for improper purposes. You acknowledge that you provide your personal information at
            your own risk.
          </p>
        </section>
        <section>
          <h3 className="heading">7 Representations and Warranties</h3>
          <h4 className="heading">7.1 Your Representations and Warranties to Bentley</h4>
          <p>
            When you provide Bentley with User Submitted Data, you represent and warrant that you have the right, power
            and authority to provide such data and grant the licenses specified above. You further represent and warrant
            that by registering as a User and providing User Submitted Data, you will not engage in a prohibited use of
            the Site, Site Services, or Licensed Data, or violate third-party rights of any kind, including without
            limitation any Intellectual Property Rights or privacy rights. To the extent your User Submitted Data may be
            copyrightable, you represent, warrant and covenant that you are the owner of all the copyright rights to
            such User Content and that Bentley may exercise the rights to your User Submitted Data granted under this
            Agreement without any liability or obligation for any payment.
          </p>
          <p>
            You warrant that you are not a consumer, you are at least eighteen (18) years old, and that you have the
            legal capacity to agree to and be bound by these terms. If you access or use the site on behalf of a
            company, principal or other entity, you represent that you have authority to bind such entity and its
            affiliates to these terms and that these terms are fully binding upon them. In such case, the term "you"
            will refer to you and such entity and its affiliates. If you do not have authority, or if you do not agree
            with these Terms of Use, you may not access or use the Site or other content or Site Services.
          </p>
          <h4 className="heading">7.2 Bentley's Representations and Warranties</h4>
          <h5 className="heading">7.2.1 Representations</h5>
          <p>
            Bentley represents, warrants and covenants that it is validly incorporated and duly registered under
            applicable laws and has the power to conduct its business as conducted at the Effective Date.
          </p>
          <h5 className="heading">7.2.2 Warranties</h5>
          <p>
            THE SITE, SITE SERVICES, AND LICENSED DATA ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT
            YOUR USE OF THE SITE AND BENTLEY'S SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY
            LAW, BENTLEY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE, THE SITE SERVICES,
            AND LICENSED DATA, AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. BENTLEY MAKES NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE OR THE LICENSED DATA AND WE WILL ASSUME NO
            LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (II)
            PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF BENTLEY SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE OR THE MARKETPLACE
            OFFERINGS, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE
            BY ANY THIRD PARTY, AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
            DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT TRANSMITTED, OR OTHERWISE MADE AVAILABLE
            VIA THE SITE. SOME COUNTRIES OR JURISDICTIONS DO NOT ALLOW FOR ALL THE FOREGOING LIMITATIONS ON WARRANTIES,
            SO TO THAT EXTENT, ANY, SOME, OR ALL OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN THESE JURISDICTIONS,
            THE FOREGOING EXCLUSIONS WILL BE ENFORCED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW.
          </p>
        </section>
        <section>
          <h3 className="heading">8 Exclusions and Limitations on Bentley's Liability</h3>
          <h4 className="heading">8.1 Disclaimer: No Liability For Damages Caused by Use of Site or Licensed Data</h4>
          <p>
            SUBJECT TO SECTION 8.3 BELOW, IN NO EVENT WILL BENTLEY OR ITS DIRECTORS, EMPLOYEES, AGENTS, LICENSORS, OR
            AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
            INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF CONFIDENTIAL OR OTHER
            DATA OR INFORMATION, LOSS OF GOODWILL, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, SITE SERVICES,
            THE LICENSED DATA, OR OTHER CONTENT, EVEN IF BENTLEY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
            PARTICULAR, BENTLEY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR
            USE OF BENTLEY'S SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
            PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
            SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE
            BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A
            RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SITE;
            (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
          </p>
          <h4 className="heading">8.2 Limitation on Liability</h4>
          <p>
            UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF BENTLEY ARISING OUT OF OR RELATED TO YOUR USE OF THE
            SITE, THE SITE SERVICES, THE LICENSED DATA, OR OTHER CONTENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS),
            REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR
            OTHERWISE, EXCEED ONE HUNDRED US DOLLARS ($100). BECAUSE SOME COUNTRIES OR JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES, SOME OR ALL OF THE ABOVE LIMITATIONS MAY
            NOT APPLY TO YOU. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT
            APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW, BUT WILL NONETHELESS BE ENFORCED TO THE GREATEST EXTENT
            PERMITTED BY APPLICABLE LAW.
          </p>
        </section>
        <section>
          <h3 className="heading">9 Indemnification</h3>
          <p>
            To the fullest extent permitted by law, you agree to defend, indemnify, and hold Bentley harmless, including
            its subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and
            against any loss, damage, liability, claim, or demand, including reasonable attorneys' fees and expenses,
            made by any third party due to or arising out of: (i) your User Submitted Data; (ii) breach of these Terms
            of Use; (iii) any breach of your representations and warranties set forth in these Terms of Use; (iv) your
            violation of the rights of a third party, including but not limited to Intellectual Property Rights; (v) any
            other party's access and use of the Site Services with your username, password or other appropriate
            credentials. Notwithstanding the foregoing, Bentley reserves the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are required to indemnify Bentley, and you agree
            to cooperate, at your expense, with Bentley's defense of such claims. Bentley will use reasonable efforts to
            notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming
            aware of it.
          </p>
        </section>
        <section>
          <h3 className="heading">10 Other Limitations and Disclaimers</h3>
          <h4 className="heading">10.1 Events Beyond Bentley's Control</h4>
          <p>
            Bentley is not in breach of this Agreement or liable to you if there is any total or partial failure of
            performance of the Site or Site Services resulting from any act, circumstance, event or matter beyond its
            reasonable control. This may include where such results from any act of God, fire, act of government or
            state or regulation, war, civil commotion, terrorism, insurrection, inability to communicate with third
            parties for whatever reason, failure of any computer dealing or necessary system, failure or delay in
            transmission of communications, failure of any internet service provider, strike, industrial action or
            lock-out or any other reason beyond Bentley's reasonable control.
          </p>
          <h4 className="heading">10.2 Local access restrictions and additional local information</h4>
          <p>
            Bentley's Site and all content and functionality contained within it is not directed to or intended for use
            by any person resident or located in any country where the distribution of such content or functionality is
            contrary to the laws, rules or regulations of such country. The same applies to any person resident or
            located in any country where such distribution is prohibited without obtaining the necessary licenses or
            authorizations and such licenses or authorizations have not been obtained. If any such local access
            restriction applies to you, you are not authorized to access Bentley's Site or use any of its content.
          </p>
          <p>
            Bentley makes no representation that every aspect of the service is appropriate or available for use in any
            particular jurisdiction. By choosing to access and use the Site and Site Services, you agree that: (i) you
            do so on your own initiative and at your own risk; (ii) you will not use the service if doing so would cause
            Bentley to breach any sanctions laws; (iii) you are responsible for complying with local laws and
            regulations, if and as far as local laws and regulations apply; and (iv) you specifically agree to comply
            with all relevant laws and regulations concerning the transmission of technical data exported from the
            country in which you reside. If and as far as there is any inconsistency between any of these terms (and/or
            any relevant additional terms) and your legal rights in your country of residence, your rights under your
            local law will prevail as to those specific terms.
          </p>
          <h4 className="heading">10.3 Use of Unofficial Translations</h4>
          <p>
            These Terms as well as all other texts throughout the Site may be translated from English to other
            languages. These are unofficial translations and are only provided as convenience translations. They should
            therefore be interpreted in accordance with their English language versions which will prevail in the event
            of any discrepancy between the English version and the translation. Bentley assumes no liability for any
            errors, omissions or ambiguities in the translations. Any person or entity choosing to rely on the
            translated content does so at their own risk. When in doubt, please always refer to the official English
            language version.
          </p>
        </section>
        <section>
          <h3 className="heading">11 Term, Termination and Suspension</h3>
          <p>
            These Terms of Use shall remain in full force and effect while you access or use the Site Services or
            Licensed Data and will remain in effect until terminated by either you or Bentley. Bentley may terminate
            this Agreement or the operation of or access to all or any portion of the Site Services or Licensed Data at
            any time for any reason. If you wish to terminate this Agreement with Bentley, you may immediately stop
            accessing or using the Site Services and/or Licensed Data.
          </p>
        </section>
        <section>
          <h3 className="heading">12 BENTLEY ENTITY, GOVERNING LAW, DISPUTE RESOLUTION AND NOTICES</h3>
          <p>
            Depending on where your principal place of business is (or, if you are an individual, where you are
            resident), this Agreement is between you and the Bentley entity set out below. This Agreement will be
            governed by and construed in accordance with the substantive laws in force in the respective country
            specified in the below table. To the maximum extent permitted by applicable law, the parties agree that the
            provisions of the United Nations Convention on Contracts for the International Sale of Goods, as amended,
            and the provisions of the Uniform Computer Information Transactions Act, as they may have been or hereafter
            may be in effect in any jurisdiction, shall not apply to this Agreement. Any dispute, controversy or claim
            between the parties arising under this Agreement shall be resolved pursuant to the applicable dispute
            resolution provision set out below. Please send all notices under this Agreement to the attention of the
            Bentley Legal Department and have these addressed to the respective Bentley entity according to your
            location per the below table.
          </p>
          <table>
            <tr>
              <th style={{ width: "15%" }}>
                Your principal place of business (or, if you are an individual, the place of your residency)
              </th>
              <th style={{ width: "30%" }}>References to "Bentley" mean the following Bentley entity:</th>
              <th style={{ width: "15%" }}>Governing law is:</th>
              <th>Exclusive jurisdiction/forum for dispute resolution:</th>
            </tr>
            <tr>
              <td>USA and Canada</td>
              <td>
                Bentley Systems, Incorporated, a Delaware corporation having its registered office at 685 Stockton
                Drive, Exton, PA 19341-0678
              </td>
              <td>Commonwealth of Pennsylvania</td>
              <td>
                The federal courts located in Philadelphia, Pennsylvania shall have exclusive jurisdiction over all
                disputes relating to this Agreement
              </td>
            </tr>
            <tr>
              <td>United Kingdom</td>
              <td>
                Bentley Systems (UK) Limited, having its registered office at 43rd Floor, 8 Bishopsgate, London, United
                Kingdom, EC2N 4BQ
              </td>
              <td>England and Wales</td>
              <td>
                The courts located in London, England shall have exclusive jurisdiction over all disputes relating to
                this Agreement
              </td>
            </tr>
            <tr>
              <td>Brazil</td>
              <td>
                Bentley Systems Brasil Ltda., having its registered office at Avenida Paulista, 2537,9o. andar, 09-114,
                São Paulo, SP, Zip Code 01310-100
              </td>
              <td>Brazil</td>
              <td>
                The courts and tribunals of São Paulo, Brazil shall have exclusive jurisdiction over all disputes
                relating to this Agreement
              </td>
            </tr>
            <tr>
              <td>Mexico</td>
              <td>
                BENTLEY SYSTEMS DE MEXICO S.A., having its registered office at de C.V, Av. Insurgentes Sur #1106 piso
                7, Col. Noche Buena, CDMX, México, C.P. 03720
              </td>
              <td>Mexico</td>
              <td>
                The courts and tribunals of Mexico City, Mexico shall have exclusive jurisdiction over all disputes
                relating to this Agreement
              </td>
            </tr>
            <tr>
              <td>China</td>
              <td>
                Bentley Systems (Beijing) Co., Ltd., having its registered office at Unit 1405-06, Tower 1, China
                Central Place, No. 81 Jianguo Road, Chaoyang District, Beijing, China
              </td>
              <td>People's Republic of China</td>
              <td>
                The parties agree to resolve amicably any dispute or difference arising from or in connection with this
                Agreement. In the event the parties are unable to settle the dispute or difference within 30 days from
                the delivery by any party of a notice confirming the existence of the dispute, any party may submit the
                dispute to the China International Economic and Trade Arbitration Commission in Beijing ("CIETAC") for
                final and binding arbitration in accordance with CIETAC's rules and procedures. The award rendered by
                CIETAC shall be enforceable by any court of competent jurisdiction.
              </td>
            </tr>
            <tr>
              <td>Taiwan</td>
              <td>
                Bentley Systems, Incorporated, Taiwan Branch, having its registered office at Spaces, 1F., No. 170, Sec.
                3, Nanjing E.Rd., Zhongshan Dist., Taipei City 104, Taiwan, Republic of China
              </td>
              <td>Taiwan</td>
              <td>
                Any dispute, controversy, difference or claim arising out of, relating to or in connection with this
                Agreement, or the breach, termination or invalidity thereof, shall be finally settled by arbitration
                referred to the Chinese Arbitration Association, Taipei in accordance with the Association's arbitration
                rules. The place of arbitration shall be in Taipei, Taiwan. The language of arbitration shall be
                English. The arbitral award shall be final and binding upon both parties.
              </td>
            </tr>
            <tr>
              <td>India</td>
              <td>
                Bentley Systems India Private Limited, having its registered office at Suite No. 1001 & 1002, WorkWell
                Suites, 10th Floor, Max House, 1516/338, 339, 340, Village Bahapur, New Delhi 110020 ,India
              </td>
              <td>India</td>
              <td>
                The courts located in New Delhi, India shall have exclusive jurisdiction over all disputes relating to
                this Agreement
              </td>
            </tr>
            <tr>
              <td>Worldwide unless in a country or region described above</td>
              <td>
                Bentley Systems International Limited, having its registered office at 6th Floor, 1 Cumberland Place,
                Fenian St, Dublin 2, D02 AX07, Ireland
              </td>
              <td>Ireland</td>
              <td>
                The courts located in Dublin, Ireland shall have exclusive jurisdiction over all disputes relating to
                this Agreement
              </td>
            </tr>
          </table>
        </section>
        <section>
          <h3 className="heading">13 Miscellaneous</h3>
          <h4 className="heading">13.1 Entire Agreement/Severability</h4>
          <p>
            These Terms of Use, together with any amendments and any additional agreements you may enter into with
            Bentley, shall constitute the entire agreement between you and Bentley concerning use of the Site, Site
            Services, and Licensed Data. If any provision of these Terms is deemed invalid by a court of competent
            jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of
            these terms, which shall remain in full force and effect. If a provision of these Terms of Use is or becomes
            illegal, invalid or unenforceable in any country, that will not affect the legality, validity or
            enforceability in that country of any other provision or in other jurisdictions of that or any other
            provisions of these Terms of Use.
          </p>
          <h4 className="heading">13.2 No assignment</h4>
          <p>
            This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by you,
            but may be assigned by Bentley without restriction. Any attempted transfer or assignment in violation hereof
            shall be null and void.
          </p>
          <h4 className="heading">13.3 No Agency</h4>
          <p>
            This Agreement and any registration for or use of this Site will not be construed as creating or implying
            any relationship of agency, franchise, partnership or joint venture between you and Bentley, except and
            solely to the extent expressly stated in the Terms of Use.
          </p>
          <h4 className="heading">13.4 No Waiver</h4>
          <p>
            Bentley's lack of enforcement of any right or provision in these Terms of Use will not constitute, in any
            way, a waiver of such right or provision unless acknowledged and agreed to by us in writing.
          </p>
          <h4 className="heading">13.5 Translation</h4>
          <p>
            This Agreement was originally written in English. We may translate this Agreement into other languages. In
            the event of a conflict between a translated version and the English version, the English version will
            control except where prohibited by applicable law.
          </p>
          <h4 className="heading">13.6 Notice</h4>
          <p>
            Any notice or other communication given under or in connection with these Terms of Use will be in English.
            All other documents provided under or in connection with these Terms of Use will be in English.
          </p>
          <h4 className="heading">13.7 Survival</h4>
          <p>
            Provisions that, by their nature, should survive termination of these Terms shall survive termination. By
            way of non-exhaustive example, all of the following will survive termination: any obligation you have to pay
            or indemnify Bentley or any third party, any disclaimers or limitations on our liability, any terms
            regarding ownership or Intellectual Property Rights, and terms regarding disputes between you and Bentley.
          </p>
          <h4 className="heading">13.8 Section Headings and Summaries</h4>
          <p>
            Throughout this Agreement, various sections include titles and brief summaries of these Terms of Use. These
            section titles and brief summaries are not legally binding.
          </p>
        </section>
        <section>
          <h3 className="heading">14 Definitions</h3>
          <p>
            As used in this Agreement and the other Terms of Service, the following terms have the meanings given below,
            unless otherwise defined or required in context:
          </p>
          <ul>
            <li>
              "<b>Affiliate</b>" means any entity that, directly or indirectly, through one or more intermediaries,
              controls, is controlled by, or is under common control with Bentley.
            </li>
            <li>
              "<b>Intellectual Property Rights</b>" means all patent rights, copyright rights, mask work rights, moral
              rights, rights of publicity, trademark, trade dress and service mark rights, goodwill, trade secret rights
              and other Intellectual Property Rights as may now exist or hereafter come into existence, and all
              applications therefore and registrations, renewals and extensions thereof, under the laws of any state,
              country, territory or other jurisdiction.
            </li>
            <li>
              "<b>Licensed Data</b>" means any reports that may be provided to you that refer to or include aggregated
              and statistical data and insights.
            </li>
            <li>
              "<b>Registered User</b>" means those who have completed the Registered User registration process for the
              Site and agreed to the terms of this Agreement.
            </li>
            <li>
              "<b>Site</b>" means, collectively, Bentley's website, and affiliated applications, owned and operated by
              Bentley, our predecessors or successors in interest, or our Affiliates.
            </li>
            <li>
              "<b>Site Services</b>" means, collectively, all services, applications and products that are accessible
              through the Site and its subdomains, including the Licensed Data.
            </li>
            <li>
              "<b>Site Visitor</b>" means a person who is only visiting the Site, not a Member or Registered User.
            </li>
            <li>
              "<b>User Submitted Data</b>" is data or other information submitted from or provided by you to Bentley.
            </li>
            <li>
              "<b>Third-Party Websites</b>" means websites which are not part of or produced or endorsed by Bentley.
            </li>
            <li>
              "<b>Third-Party Content</b>" means material on the Site or accessed through the Site that is created
              and/or controlled by parties other than Bentley.
            </li>
            <li>
              "<b>You</b>" means any person who accesses Bentley's Site, including a Site Visitor or Registered User and
              including any entity or agency on whose behalf any such person accesses or uses Bentley's Site.
            </li>
          </ul>
        </section>
        <section>
          <h3 className="heading">15 Contacting Bentley</h3>
          <p>
            If you have questions or need assistance, please contact Customer Support at:
            <br />
            Email: <a href="mailto:support@bentley.com">support@bentley.com</a>.
          </p>
        </section>
      </div>
    </TermsContainer>
  </>
);
