import styled from "@emotion/styled";
import { EditOutlined } from "@mui/icons-material";
import { Chip, Tooltip, Typography } from "@mui/material";
import { Button } from "components_new";
import React, { useCallback, useMemo } from "react";

import { PopoverControlButton } from "features/dataset-folders/PopoverControlButton";

import { Popover, PopoverMenuItem } from "components/atoms/popover/Popover";

import { useAppDispatch, useAppSelector } from "hooks";

import { globalActions } from "store/sections/global";

import { themeColors } from "theme/themeConstants";

import { ROUTES, SelectLinkConfig } from "types";

import { formatCreatedTime, formatCreatedTimeShort } from "utils/format";

import { getFocusArea } from "./select-link/utils";

export interface Props {
  data: SelectLinkConfig;
  canUpdate: boolean;
  canDelete: boolean;
  canEdit: boolean;
  onDeleteConfig: (configId: string) => void;
  onRenameConfig: (configId: string, areaId: string, name: string, description?: string) => void;
}

const ItemContent = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1px 250px 250px 80px 1fr 80px 80px 90px 31px;
  grid-template-rows: 1fr;
  column-gap: 24px;
  align-items: center;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;
  background-color: #ffffff;
  border-bottom: 1px solid var(--color-gray-100);
  height: 50px;

  -webkit-tap-highlight-color: transparent;

  color: var(--color-text);
  white-space: nowrap;

  transform: scale(var(--scale, 1));
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  &:focus-visible {
    box-shadow: var(--box-shadow-inner);
  }
`;

const ItemDate = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: var(--color-gray-600);
  white-space: nowrap;
  flex-direction: column;
`;

const TooltipTypography = ({ value }: any) => {
  return (
    <Tooltip title={value} placement={"bottom-start"}>
      <Typography fontSize={14} color={`${themeColors.gray600}`} noWrap>
        {value}
      </Typography>
    </Tooltip>
  );
};

const TooltipItemDate = ({ createdAt, createdBy }: any) => {
  return (
    <ItemDate>
      {createdAt ? (
        <Tooltip
          placement="left"
          title={
            <>
              Created by {createdBy?.email || "<anonymous>"} <br /> at {formatCreatedTime(new Date(createdAt))}
            </>
          }
        >
          <Typography fontSize={"inherit"} color={"inherit"}>
            {formatCreatedTimeShort(new Date(createdAt))}
          </Typography>
        </Tooltip>
      ) : (
        "-"
      )}
    </ItemDate>
  );
};

export const Item = React.memo(({ data, canUpdate, canDelete, canEdit, onDeleteConfig, onRenameConfig }: Props) => {
  const dispatch = useAppDispatch();

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);

  const focusArea = useMemo(
    () => focusAreas.data && getFocusArea(focusAreas.data, data.licensedAreaId),
    [data, focusAreas.data],
  );

  const onEditSelectLinkAnalysis = () => {
    if (data && data.configId && data.licensedAreaId) {
      const licensedAreaId = data.licensedAreaId.toString();
      dispatch(
        globalActions.setSelectedFocusAreaId({
          focusAreaId: licensedAreaId,
          timePeriod: data.timePeriod,
          redirectUrl: ROUTES.SelectLinkEditor.replaceAll(":configId", data.configId),
        }),
      );
    }
  };

  const onDeleteSelectLinkAnalysis = useCallback(() => {
    onDeleteConfig(data.configId);
  }, [data.configId, onDeleteConfig]);

  const onRenameSelectLinkAnalysis = useCallback(() => {
    onRenameConfig(data.configId, data.licensedAreaId.toString(), data.analysisName, data.description);
  }, [data, onRenameConfig]);

  const popoverMenuItems: PopoverMenuItem[] = useMemo(
    () =>
      ([] as PopoverMenuItem[]).concat(
        canUpdate
          ? [
              {
                children: "Rename",
                onClick: onRenameSelectLinkAnalysis,
              },
            ]
          : [],
        canDelete
          ? [
              {
                children: "Delete",
                onClick: onDeleteSelectLinkAnalysis,
              },
            ]
          : [],
      ),
    [canUpdate, canDelete, onDeleteSelectLinkAnalysis, onRenameSelectLinkAnalysis],
  );

  return (
    <div>
      <ItemContent>
        <span />
        <TooltipTypography value={data.analysisName} />
        <TooltipTypography value={focusArea && focusArea.label} />
        <Chip size={"small"} variant={"outlined"} color={"default"} label={data.timePeriod} />
        <TooltipTypography value={data.description} />
        <TooltipItemDate createdAt={data.createdAt} createdBy={data.createdBy} />
        <TooltipItemDate createdAt={data.updatedAt} createdBy={data.updatedBy} />
        <span>
          <Button
            fullWidth
            variant="outlined"
            size="small"
            disabled={!canEdit}
            onClick={onEditSelectLinkAnalysis}
            startIcon={<EditOutlined />}
          >
            Edit
          </Button>
        </span>
        {popoverMenuItems.length > 0 && <Popover control={PopoverControlButton} menuItems={popoverMenuItems} />}
      </ItemContent>
    </div>
  );
});
