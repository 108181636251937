import { isEqual } from "lodash";
import { Map } from "mapbox-gl";
import { MutableRefObject, useEffect } from "react";

import { useAppDispatch, usePrevious } from "hooks";

export const useResetMap = (
  map: MutableRefObject<Map | null>,
  mapLoaded: boolean,
  onMapLoaded: (loaded: boolean) => void,
  deps: any[],
) => {
  const dispatch = useAppDispatch();
  const previousDeps = usePrevious(deps);

  useEffect(() => {
    if (!isEqual(deps, previousDeps) && mapLoaded) {
      onMapLoaded(false);
      map.current?.remove();
      map.current = null;
    }
  }, [map, deps, previousDeps, mapLoaded, onMapLoaded, dispatch]);
};
