import { Groups, PeopleOutline } from "@mui/icons-material";
import dashboard_corridor_discovery from "assets/png/dashboard/corridor-discovery.png";
import dashboard_overview_quality from "assets/png/dashboard/data-quality.png";
import dashboard_gates from "assets/png/dashboard/dataset-gates.png";
import dashboard_select_link from "assets/png/dashboard/select-link.png";
import dashboard_tutorials from "assets/png/dashboard/tutorial.png";
import dashboard_vmt from "assets/png/dashboard/vmt.png";
import dashboard_webinars from "assets/png/dashboard/webinar.png";

export const getFeatureHighlightsPanel = (isRoadVmtAllowed: boolean) => {
  return {
    title: "Feature tour",
    carousel: [
      {
        event: "vmt",
        title: "Roadway VMT",
        description:
          "Understand the magnitude of Vehicle Miles Traveled (VMT) in locations using selected roadway segments.",
        highlight: "NEW FEATURE",
        imageUrl: dashboard_vmt,
        videoUrl: "https://vimeo.com/990031262/36a48276dd",
        tooltip: "Roadway VMT tutorial",
        buttonUrl: "/analytics/road-vmt",
        buttonText: "Try it out",
        enabled: isRoadVmtAllowed,
      },
      {
        event: "select-link",
        title: "Select Link",
        description: "Understand the use of selected road segments and locations with Select Link & Zone.",
        imageUrl: dashboard_select_link,
        videoUrl: "https://vimeo.com/990037748/6ae8a6ba5a",
        tooltip: "Select Link tutorial",
        buttonUrl: "/analytics/select-link",
        buttonText: "Try it out",
        enabled: true,
      },
      {
        event: "corridor-discovery",
        title: "Corridor Discovery",
        description: "Corridor Discovery reveals the natural travel demand corridors of your region.",
        imageUrl: dashboard_corridor_discovery,
        videoUrl: "https://vimeo.com/990038264/b3e8be07df",
        tooltip: "Corridor Discovery tutorial",
        buttonUrl: "/analytics/corridor-discovery",
        buttonText: "Try it out",
        enabled: true,
      },
      {
        event: "dataset-gates",
        title: "Gate creation and export",
        description:
          "Create subareas and export a fully routable model-ready road network and integrated internal and external OD matrices.",
        imageUrl: dashboard_gates,
        videoUrl: "https://vimeo.com/990036077/241827b078",
        tooltip: "Datasets tutorial",
        buttonUrl: "/datasets",
        buttonText: "Try it out",
        enabled: true,
      },
    ],
  };
};

export const learnMorePanel = {
  title: "Learn more",
  cards: [
    {
      event: "learn-more-tutorial",
      title: "Tutorials",
      imageUrl: dashboard_tutorials,
      urlText: "Get up to speed with OpenPaths Patterns in no time",
      url: "/docs/tutorials",
    },
    {
      event: "learn-more-webinar",
      title: "Webinars",
      imageUrl: dashboard_webinars,
      urlText: "Deep dives into applying OpenPaths Patterns successfully",
      url: "/docs/webinars",
    },
    {
      event: "learn-more-data",
      title: "Data",
      imageUrl: dashboard_overview_quality,
      urlText: "Gain confidence in the data powering OpenPaths Patterns",
      url: "/docs/data-quality",
    },
  ],
};

export const meetUsPanel = {
  title: "Events",
  events: [
    {
      event: "event-aashto",
      title: "AASHTO 2024 Conference",
      subTitle: "St. Louis, Missouri | September 17-20, 2024",
      url: "https://web.cvent.com/event/0d0ef108-bca4-4f5c-acbf-f7565de48825/websitePage:33bb36ed-69a1-42a4-85e6-6b25fb660a71",
      icon: <PeopleOutline />,
    },
  ],
  gettingTrained: {
    title: "FREE TRAINING",
    description: "1:1 training custom tailored to your business goals",
    icon: <Groups />,
    scheduleCallText: "Book now",
    scheduleCallUrl:
      "https://outlook.office.com/bookwithme/user/4f68f8c46a3e4e33afd8cb4d61ea52ce@bentley.com/meetingtype/Py_-02r1F0a4sU_CsnxEmQ2?anonymous&ep=mcard",
  },
};
