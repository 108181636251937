import styled from "@emotion/styled";

import { Card } from "components";

export const RightSidebar = styled(Card)<{
  isOpen: boolean;
  animation?: boolean;
}>`
  min-width: var(--sidebar-width);
  max-width: var(--sidebar-width);
  position: fixed;
  top: 63px;
  bottom: 0;
  left: auto;
  right: 0;
  margin: 1rem;
  border-radius: 12px;
  padding: var(--padding-lg);
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(110%)")};
  transition: ${({ animation }) => (animation ? "0.5s ease" : "0s ease")};
  z-index: 3;
`;
