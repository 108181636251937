import { AnySourceData } from "mapbox-gl";

import { RoadsTileService } from "types";

export const ROADS_SOURCE_ID = "ROADS_SOURCE_ID";

export const getRoadsSources = (tileService: RoadsTileService) => {
  return [
    {
      id: ROADS_SOURCE_ID,
      source: {
        type: "vector",
        tiles: [tileService.url + "/{z}/{x}/{y}.pbf"],
        promoteId: {
          [tileService.layerName]: tileService.fromToSegmentIdField,
        },
        minzoom: tileService.minZoom,
        maxzoom: tileService.maxZoom,
      } as AnySourceData,
    },
  ];
};
