import { useAuth0 } from "@auth0/auth0-react";
import { ChevronLeft, ExpandLess, ExpandMore, HelpCenter } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  ButtonBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
} from "@mui/material";
import { IconButton, Menu, TextField } from "components_new";
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { FlexContainer, HelpForm, Notifications } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { licenseActions } from "store/sections/license";

import { Organization } from "types";

const UserButton = styled(ButtonBase)`
  min-width: 62px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;

const UserControlsContainer = styled(FlexContainer)`
  gap: 24px;
`;

export const UserControls: FC = () => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth0();

  const isOperator = useAppSelector((state) => state.license.user.data?.isOperator);
  const organizations = useAppSelector((state) => state.license.organization.data);
  const licenseTypes = useAppSelector((state) => state.license.licenseTypes.data);
  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);
  const userLicenseType = useAppSelector((state) => state.license.user.data?.softwareLicense);
  const previousUserOrganization = usePrevious(userOrganization);
  const previousUserLicenseType = usePrevious(userLicenseType);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const isUserOrganisationChanged = useMemo(
    () => userOrganization && previousUserOrganization && previousUserOrganization !== userOrganization,
    [userOrganization, previousUserOrganization],
  );

  const isUserLicenseTypeChanged = useMemo(
    () => userLicenseType && previousUserLicenseType && previousUserLicenseType !== userLicenseType,
    [userLicenseType, previousUserLicenseType],
  );

  const sortedOrganizations = useMemo(
    () => [...(organizations || [])].sort((a, b) => a.name.localeCompare(b.name)),
    [organizations],
  );

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchOrganization());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchLicenseTypes());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isUserOrganisationChanged || isUserLicenseTypeChanged) {
      window.location.replace("/");
    }
  }, [isUserOrganisationChanged, isUserLicenseTypeChanged]);

  const changeOrganization = useCallback(
    (code: string) => {
      dispatch(licenseActions.changeUserOrganization(code));
    },
    [dispatch],
  );

  const changeLicenseType = useCallback(
    (code: string) => {
      dispatch(licenseActions.changeUserLicenseType(code));
    },
    [dispatch],
  );

  const handleSelectOrganization = useCallback(
    (event: SyntheticEvent<Element, Event>, org: Organization | null) => {
      if (org) changeOrganization(org.code);
    },
    [changeOrganization],
  );

  const userMenuItems = useMemo(
    () => [
      ...(isOperator
        ? [
            {
              text: "Copy token",
              onClick: () => {
                navigator.clipboard.writeText(sessionStorage.getItem("accessToken") || "");
              },
            },
            {
              text: "Switch organization",
              children: (
                <Box padding={1} width={"350px"} height={"400px"} position={"relative"}>
                  <Autocomplete
                    popupIcon={null}
                    ListboxProps={{
                      sx: {
                        fontSize: "14px",
                        maxHeight: "350px",
                      },
                    }}
                    slotProps={{ paper: { sx: { boxShadow: 0, borderRadius: 0 } } }}
                    options={sortedOrganizations}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(opt) => opt.name}
                    isOptionEqualToValue={(opt: Organization, value: Organization) => opt.id === value.id}
                    value={userOrganization}
                    onChange={handleSelectOrganization}
                    disablePortal
                  />
                </Box>
              ),
            },
            {
              text: "Switch license type",
              divider: true,
              children: (
                <>
                  {licenseTypes?.map((license) => (
                    <MenuItem
                      key={license.id}
                      selected={userLicenseType?.id === license.id}
                      onClick={() => changeLicenseType(license.code)}
                    >
                      {license.name}
                    </MenuItem>
                  ))}
                </>
              ),
            },
          ]
        : []),
      {
        text: "Sign out",
        onClick: () =>
          logout({
            returnTo: window.location.origin,
          }),
      },
    ],
    [
      isOperator,
      sortedOrganizations,
      licenseTypes,
      userLicenseType?.id,
      userOrganization,
      changeLicenseType,
      handleSelectOrganization,
      logout,
    ],
  );

  return (
    <UserControlsContainer>
      <Notifications />
      <IconButton onClick={() => setIsHelpFormOpen(true)}>
        <HelpCenter sx={{ color: "#fff" }} />
      </IconButton>
      <HelpForm isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} />
      <Menu
        control={(handleOpen, open) => (
          <UserButton onClick={handleOpen}>
            <Avatar src={user?.picture} alt="avatar" sx={{ width: 40, height: 40 }} />
            {open ? <ExpandLess sx={{ color: "#fff" }} /> : <ExpandMore sx={{ color: "#fff" }} />}
          </UserButton>
        )}
      >
        {userMenuItems.map((item, index) => (
          <MenuItem dense key={index} divider={item.divider} onClick={item.onClick}>
            {item.children ? (
              <Menu
                control={(handleOpen) => (
                  <ListItem disableGutters key={index} onClick={handleOpen}>
                    <ListItemIcon>
                      <ChevronLeft fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                )}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {item.children}
              </Menu>
            ) : (
              <ListItemText primary={item.text} />
            )}
          </MenuItem>
        ))}
      </Menu>
    </UserControlsContainer>
  );
};
