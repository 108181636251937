import styled from "@emotion/styled";

export const BluePoint = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--color-secondary);
  margin: auto;
`;

export const SubTitle = styled.h4`
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  margin-bottom: 1rem;
  color: var(--color-textSecondary);
`;

export const Footnote = styled.p`
  margin-bottom: 1rem;
  color: var(--color-textSecondary);
  font-size: 12px;
`;

export const HeadCellAlignCenter = styled.th`
  text-align: center !important;
`;

export const CellAlignCenter = styled.td`
  text-align: center !important;
`;

export const TableHeadRow = styled.tr`
  & th:first-of-type {
    border-top-left-radius: 8px;
  }

  & th:last-of-type {
    border-top-right-radius: 8px;
  }
`;

export const BottomBorderRow = styled.tr`
  & td,
  th {
    border-bottom: 1.2px solid var(--color-gray-200);
  }
`;

export const Table = styled("table")`
  width: 100%;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: var(--box-shadow-md);
  margin-bottom: 3rem;

  & td {
    vertical-align: middle !important;
    color: var(--color-textSecondary) !important;
    min-width: 70px;
    padding: 0.5rem 1rem !important;
  }

  & th {
    vertical-align: middle !important;
    background-color: var(--color-gray-50);
  }
`;
