import { Dispatch, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector } from "hooks";

import { getCommonLayers } from "./map-data/layers";
import { getCommonSources } from "./map-data/sources";

interface Props {
  setCommonModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const MapController = ({ setCommonModuleData }: Props) => {
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  // Set module data
  useEffect(() => {
    if (selectedFocusArea) {
      const commonSources = getCommonSources(selectedFocusArea.geometry);
      const commonLayers = getCommonLayers();

      setCommonModuleData((data) => (data ? data : { sources: commonSources, layers: commonLayers }));
    }
  }, [selectedFocusArea, setCommonModuleData]);

  return null;
};
