import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Popup } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector, useStateRef } from "hooks";

import { DataState } from "store/interfaces";

import {
  Counts,
  FiltersType,
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkAnalysisOptions,
  SelectLinkMode,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedVolume,
  Volume,
  ZoneIds,
  ZoneSelectionMode,
} from "types";

import {
  useChangeShowZoneCounts,
  useFilterZonesByRange,
  useGetODCounts,
  useGetODIds,
  useHandleSetColorScale,
  useHandleSetZoningLevel,
} from "../od/ControllerCallbacks";
import { getODLayers } from "../od/map-data/od/layers";
import { getODSources } from "../od/map-data/od/sources";
import {
  useChangeShowRoadVolumes,
  useFilterRoadSegmentsByRange,
  useFilterRoadSegmentsByRoadClasses,
  useGetRoadSegmentIds,
  useGetRoadsVolumes,
  useGetVisibilityStatus,
  useGetVisibleRoadIds,
  useSelectRoadVolume,
  useSelectRoadVolumeId,
  useUpdateFeatureStateForRoads,
} from "../roads/RoadsControllerCallbacks";
import { getRoadsLayers } from "../roads/map-data/layers";
import { getRoadsSources } from "../roads/map-data/sources";
import {
  useDeleteSelectedZone,
  useDeleteSelectedZonesFromFeatureState,
  useGetSelectLinkSegmentCounts,
  useSelectZoneInternal,
  useSelectZoning,
  useUpdateFeatureStateForZone,
  useUpdateFeatureStateWithSelectedLinks,
  useUpdateFeatureStateWithSelectedODs,
  useUpdateFeatureStateWithSelectedZones,
  useUpdateSelectLinkConfiguration,
} from "./ControllerCallbacks";

export const PERMANENT_HIGHLIGHTED_FEATURE = "permanentSelectHighlight";

interface Props {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  zoneSelectionModeRef: MutableRefObject<ZoneSelectionMode>;
  mode: SelectLinkMode;
  selectedLinkGroups: SegmentsGroup[];
  selectLinkOptions: SelectLinkAnalysisOptions;
  selectedOriginsRef: MutableRefObject<SelectedArea[]>;
  selectedDestinationsRef: MutableRefObject<SelectedArea[]>;
  selectLinkConfigLinksModuleData: ModuleData | null;
  addSegmentFromPopup: (selectedVolume: SelectedVolume, selectedRoadSegmentId: string, layerName: string) => void;
  setSelectLinkConfigZonesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkConfigLinksModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkResultsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  setActiveGroupId: Dispatch<SetStateAction<string>>;
  setSelectedOrigins: Dispatch<SetStateAction<SelectedArea[]>>;
  setSelectedDestinations: Dispatch<SetStateAction<SelectedArea[]>>;
  setSelectedLinkGroups: Dispatch<SetStateAction<SegmentsGroup[]>>;
  setMaxSegmentsGroupId: Dispatch<SetStateAction<number>>;
  setSelectLinkPredicateLogic: Dispatch<SetStateAction<SelectLinkPredicateLogic>>;
  setSelectLinkOptions: Dispatch<SetStateAction<SelectLinkAnalysisOptions>>;
  updateFeatureStateForRoads: MutableRefObject<
    ((segmentId: string | null, stateName: string, status?: boolean | undefined) => void) | null
  >;
  updateSelectLinkConfiguration: MutableRefObject<
    (
      selectedLinkGroups: SegmentsGroup[],
      selectedOrigins: SelectedArea[],
      selectedDestinations: SelectedArea[],
      selectLinkPredicateLogic: SelectLinkPredicateLogic,
    ) => void
  >;
  getSelectLinkSegmentCounts: MutableRefObject<
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => void
  >;
}

export const MapController = ({
  map,
  layerManagerRef,
  zoneSelectionModeRef,
  mode,
  selectedLinkGroups,
  selectLinkOptions,
  selectedOriginsRef,
  selectedDestinationsRef,
  selectLinkConfigLinksModuleData,
  addSegmentFromPopup,
  setSelectLinkConfigZonesModuleData,
  setSelectLinkConfigLinksModuleData,
  setSelectLinkResultsModuleData,
  updateODModeCounts,
  updateRoadsModeCounts,
  setActiveGroupId,
  setSelectedOrigins,
  setSelectedDestinations,
  setSelectedLinkGroups,
  setMaxSegmentsGroupId,
  setSelectLinkPredicateLogic,
  setSelectLinkOptions,
  updateFeatureStateForRoads,
  updateSelectLinkConfiguration,
  getSelectLinkSegmentCounts,
}: Props) => {
  const [, setVolumeProps] = useState<Volume[]>([]);

  // Global
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  // OD
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const ODIds = useAppSelector((state) => state.analytics.ODIds);
  const ODCounts = useAppSelector((state) => state.analytics.ODCounts);
  const ODCountsByZoneId = useAppSelector((state) => state.analytics.ODCountsByZoneId);
  const selectedZone = useAppSelector((state) => state.analytics.selectedZone);
  const queryType = useAppSelector((state) => state.filters.queryType);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const colorScheme = useAppSelector((state) => state.map.colorScheme);
  const showZoneCounts = useAppSelector((state) => state.map.showZoneCounts);

  // Roads and road volumes
  const measure = useAppSelector((state) => state.filters.measure);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const roadSegmentIds = useAppSelector((state) => state.analytics.roadSegmentIds);
  const roadSegmentIdsByRoadClass = useAppSelector((state) => state.analytics.roadSegmentIdsByRoadClass);
  const roadsVolumes = useAppSelector((state) => state.analytics.roadsVolumes);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const showRoadVolumes = useAppSelector((state) => state.map.showRoadVolumes);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);

  // Select link analysis
  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);
  const selectLinkConfig = useAppSelector((state) => state.selectLink.selectLinkConfig);

  // Refs
  const ODCountsRef = useRef<Counts | null>(null);
  const ODZoningLevelBlockedZoom = useRef<number | null>(null);
  const queryTypeRef = useStateRef(queryType);
  const showZoneCountsRef = useRef<boolean>(showZoneCounts);
  const colorSchemeRef = useStateRef(colorScheme);

  // const clearSelectedZonesFunction = useRef<() => void>();

  const mapboxSegmentHoverPopupRef = useRef<Popup>(null);
  const mapboxVolumesPopupRef = useRef<Popup>(null);
  const segmentVolumesRef = useRef<Map<string, number>>(new Map());
  const maxSegmentVolumeRef = useRef<number>(1);
  const showRoadVolumesRef = useRef<boolean>(showRoadVolumes);

  const isODDataAvailable = useMemo(
    () =>
      Boolean(
        ODMetadata.state === DataState.AVAILABLE &&
          ODMetadata.data?.tileService &&
          ODIds.state === DataState.AVAILABLE &&
          ODIds.data &&
          ODCounts.state === DataState.AVAILABLE &&
          ODCounts.data,
      ),
    [ODIds, ODCounts, ODMetadata],
  );

  const isODZonesDataAvailable = Boolean(
    ODMetadata.state === DataState.AVAILABLE &&
      ODMetadata.data?.tileService &&
      ODIds.state === DataState.AVAILABLE &&
      ODIds.data,
  );

  const isRoadsDataAvailable = Boolean(
    roadsMetadata.state === DataState.AVAILABLE &&
      roadsMetadata.data?.tileService &&
      roadSegmentIds.state === DataState.AVAILABLE &&
      roadsVolumes.state === DataState.AVAILABLE,
  );

  const zoningLevels = useMemo(() => ODMetadata.data?.zoningLevels, [ODMetadata.data?.zoningLevels]);
  const zoneLayers = useMemo(() => ODMetadata.data?.tileService.layers, [ODMetadata.data?.tileService.layers]);
  const ODLayers = useMemo(() => ODMetadata.data?.tileService.layers, [ODMetadata.data?.tileService.layers]);

  // OD callbacks
  const getODIds = useGetODIds(ODMetadata.data, selectedFocusArea, timePeriod);
  const getODCounts = useGetODCounts(queryType, ODMetadata.data, selectedFocusArea, timePeriod);
  const changeShowZoneCounts = useChangeShowZoneCounts(layerManagerRef, ODLayers, ODZoningLevelBlockedZoom);
  const handleSetColorScale = useHandleSetColorScale();
  const handleSetZoningLevel = useHandleSetZoningLevel();
  const updateFeatureStateForZone = useUpdateFeatureStateForZone(map, zoneLayers);
  const updateFeatureStateWithSelectedZones = useUpdateFeatureStateWithSelectedZones(map, updateFeatureStateForZone);
  const updateFeatureStateWithSelectedODs = useUpdateFeatureStateWithSelectedODs(
    zoneLayers,
    selectedOriginsRef,
    selectedDestinationsRef,
    updateFeatureStateWithSelectedZones,
  );
  const deleteSelectedZonesFromFeatureState = useDeleteSelectedZonesFromFeatureState(
    zoneLayers,
    updateFeatureStateForZone,
  );
  const deleteSelectedZone = useDeleteSelectedZone(
    zoneSelectionModeRef,
    selectedOriginsRef,
    selectedDestinationsRef,
    setSelectedOrigins,
    setSelectedDestinations,
    deleteSelectedZonesFromFeatureState,
  );

  const selectZoneInternal = useSelectZoneInternal(
    selectedOriginsRef,
    selectedDestinationsRef,
    setSelectedOrigins,
    setSelectedDestinations,
    updateFeatureStateForZone,
  );
  const setSelectedZone = useSelectZoning(
    selectedOriginsRef,
    selectedDestinationsRef,
    zoneSelectionModeRef,
    deleteSelectedZone,
    selectZoneInternal,
  );

  // Roads callbacks
  const getRoadSegmentIds = useGetRoadSegmentIds(selectedFocusArea, timePeriod);
  const getVisibleRoadIds = useGetVisibleRoadIds(roadSegmentIds.data, roadsVolumes.data);
  const getVisibleRoadIdsForResults = useGetVisibleRoadIds(roadSegmentIds.data, selectLinkSegmentCounts.data);
  const getRoadsVolumes = useGetRoadsVolumes(selectedFocusArea, timePeriod);
  const selectRoadVolume = useSelectRoadVolume();
  const selectRoadVolumeId = useSelectRoadVolumeId();
  const getVisibilityStatus = useGetVisibilityStatus(showRoadVolumesRef);
  const changeShowRoadVolumes = useChangeShowRoadVolumes(layerManagerRef, updateRoadsModeCounts);
  const filterRoadSegmentsByRoadClasses = useFilterRoadSegmentsByRoadClasses(map, layerManagerRef, roadsMetadata.data);
  const filterRoadSegmentsByRange = useFilterRoadSegmentsByRange(map, layerManagerRef, roadsMetadata.data);
  updateFeatureStateForRoads.current = useUpdateFeatureStateForRoads(map, roadsMetadata.data?.tileService?.layerName);
  const updateFeatureStateWithSelectedLinks = useUpdateFeatureStateWithSelectedLinks(updateFeatureStateForRoads);

  // Configuration callbacks
  updateSelectLinkConfiguration.current = useUpdateSelectLinkConfiguration(
    selectLinkConfig.data,
    selectLinkOptions.minVolume,
  );

  getSelectLinkSegmentCounts.current = useGetSelectLinkSegmentCounts(measure, timePeriod);

  const filterZonesByRange = useFilterZonesByRange(map, layerManagerRef);

  const selectRoadLink = useRef((selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => {});

  // Update setRoadLink method after getting selectedLinkGroups
  useEffect(() => {
    selectRoadLink.current = (selectedRoadVolume: SelectedVolume, selectedRoadVolumeId: string) => {
      if (roadsMetadata.data?.tileService) {
        addSegmentFromPopup(selectedRoadVolume, selectedRoadVolumeId, roadsMetadata.data.tileService.layerName);
      }
    };
  }, [addSegmentFromPopup, selectedLinkGroups, roadsMetadata.data?.tileService]);

  // Set select link data from config
  useEffect(() => {
    if (selectLinkConfig.data) {
      const { segmentsGroups, segmentsGroupsOp, origins, destinations, minCount } = selectLinkConfig.data;
      setSelectedLinkGroups(segmentsGroups || []);
      setSelectLinkPredicateLogic(segmentsGroupsOp || SelectLinkPredicateLogic.And);
      setSelectedOrigins(origins || []);
      selectedOriginsRef.current = origins || [];
      setSelectedDestinations(destinations || []);
      selectedDestinationsRef.current = destinations || [];
      // @TODO get allowedMinVolume from SL metadata
      setSelectLinkOptions({ minVolume: minCount ?? 5 });

      const maxGroupId =
        segmentsGroups && segmentsGroups.length > 0
          ? Math.max(...segmentsGroups.map(({ groupName }) => (isNaN(parseInt(groupName)) ? 0 : parseInt(groupName))))
          : 0;
      setMaxSegmentsGroupId(maxGroupId);
      if (maxGroupId > 0) {
        setActiveGroupId(maxGroupId.toString());
      }
    }
  }, [
    selectedOriginsRef,
    selectedDestinationsRef,
    selectLinkConfig.data,
    setActiveGroupId,
    setSelectedDestinations,
    setSelectedOrigins,
    setSelectedLinkGroups,
    setMaxSegmentsGroupId,
    setSelectLinkOptions,
    setSelectLinkPredicateLogic,
  ]);

  // Fetch OD ids
  useEffect(() => {
    if (ODIds.state === DataState.EMPTY) {
      getODIds();
    }
  }, [ODIds.state, getODIds]);

  // Fetch OD counts
  useEffect(() => {
    if (
      ODCounts.state !== DataState.LOADING &&
      ODCountsByZoneId.state !== DataState.LOADING &&
      selectedFocusArea &&
      currentODFilters
    ) {
      if (ODCounts.state === DataState.EMPTY) {
        getODCounts(currentODFilters);
      }

      if (ODCounts.state === DataState.AVAILABLE) {
        ODCountsRef.current = ODCounts.data;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }

      if (ODCountsByZoneId.state === DataState.AVAILABLE && selectedZone) {
        ODCountsRef.current = ODCountsByZoneId.data.counts;

        if (typeof updateODModeCounts.current === "function") {
          updateODModeCounts.current();
        }
      }
    }
  }, [ODCounts, ODCountsByZoneId, selectedFocusArea, currentODFilters, selectedZone, getODCounts, updateODModeCounts]);

  // Update query showZoneCounts ref
  useEffect(() => {
    showZoneCountsRef.current = showZoneCounts;
  }, [showZoneCounts]);

  // Fetch Segment IDs
  useEffect(() => {
    if (roadSegmentIds.state === DataState.EMPTY) {
      getRoadSegmentIds();
    }
  }, [roadSegmentIds.state, getRoadSegmentIds]);

  // Fetch roads volumes
  useEffect(() => {
    if (roadsVolumes.state !== DataState.LOADING && selectedFocusArea && currentRoadFilters) {
      if (roadsVolumes.state === DataState.EMPTY) {
        getRoadsVolumes(currentRoadFilters, measure);
      }

      if (roadsVolumes.state === DataState.AVAILABLE) {
        segmentVolumesRef.current = roadsVolumes.data?.segmentVolumes;
        maxSegmentVolumeRef.current = roadsVolumes.data?.maxVolume || 1;
      }
    }
  }, [map, measure, currentRoadFilters, roadsVolumes.state, roadsVolumes.data, selectedFocusArea, getRoadsVolumes]);

  // Update showRoadVolumes ref
  useEffect(() => {
    showRoadVolumesRef.current = showRoadVolumes;
  }, [showRoadVolumes]);

  // Set zones module data
  useEffect(() => {
    if (mode === SelectLinkMode.ZONES && selectLinkConfig.state === DataState.AVAILABLE) {
      if (selectedFocusArea && isODDataAvailable) {
        setSelectLinkConfigLinksModuleData(null);
        setSelectLinkResultsModuleData(null);

        const ODTileservice: any = ODMetadata.data?.tileService;
        const ids: ZoneIds | null = ODIds.data;

        const ODSources = getODSources(ODTileservice);
        const ODLayers = getODLayers(ODTileservice).zoningLayers;

        setSelectLinkConfigZonesModuleData((configData) =>
          configData
            ? configData
            : {
                sources: ODSources,
                layers: ODLayers,
                data: {
                  ODTileservice,
                  ids,
                  ODCountsRef,
                  queryTypeRef,
                  ODZoningLevelBlockedZoom,
                  zoningLevels,
                  showZoneCountsRef,
                  colorSchemeRef,
                  isExportPermitted: roadsMetadata.data?.exportPermissions?.allowExport,
                  minAllowedVolume: 5, // @TODO: get from SL metadata
                  updateODModeCounts,
                  handleSetColorScale,
                  handleSetZoningLevel,
                  filterZonesByRange,
                  setSelectedZone,
                  changeShowZoneCounts,
                  deleteSelectedZone,
                  updateFeatureStateWithSelectedODs,
                },
              },
        );
      }
    }
  }, [
    mode,
    selectLinkConfig.state,
    isODDataAvailable,
    selectedFocusArea,
    ODMetadata.data,
    ODIds.data,
    colorSchemeRef,
    queryTypeRef,
    ODCountsRef,
    ODZoningLevelBlockedZoom,
    zoningLevels,
    showZoneCountsRef,
    roadsMetadata.data,
    updateODModeCounts,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    handleSetColorScale,
    handleSetZoningLevel,
    filterZonesByRange,
    setSelectedZone,
    changeShowZoneCounts,
    updateFeatureStateWithSelectedODs,
    deleteSelectedZone,
  ]);

  // Set links module data
  useEffect(() => {
    if (mode === SelectLinkMode.LINKS && selectLinkConfig.state === DataState.AVAILABLE) {
      const isAllDataAvailable = ODMetadata.state === DataState.AVAILABLE && isRoadsDataAvailable;

      if (selectedFocusArea && isAllDataAvailable && roadsVolumes.data?.segmentVolumes) {
        setSelectLinkConfigZonesModuleData(null);
        setSelectLinkResultsModuleData(null);

        segmentVolumesRef.current = roadsVolumes.data?.segmentVolumes;
        maxSegmentVolumeRef.current = roadsVolumes.data?.maxVolume || 1;

        const roadsSources = getRoadsSources(roadsMetadata.data!.tileService);
        const roadsLayers = getRoadsLayers(roadsMetadata.data!.tileService);
        const ODSourses = getODSources(ODMetadata.data!.tileService);
        const selectLinkSelectedZonesLayers = getODLayers(
          ODMetadata.data!.tileService,
          true,
        ).selectLinkSelectedZonesLayers;
        const zoneIds: ZoneIds | null = ODIds.data;

        setSelectLinkConfigLinksModuleData((data) =>
          data
            ? data
            : {
                sources: [...roadsSources, ...ODSourses],
                layers: [...selectLinkSelectedZonesLayers, ...roadsLayers],
                data: {
                  segmentGroups: selectLinkConfig.data?.segmentsGroups,
                  roadsTileService: roadsMetadata.data!.tileService,
                  ODTileservice: ODMetadata.data!.tileService,
                  roadSegmentIdsByRoadClass,
                  segmentVolumesRef,
                  maxSegmentVolumeRef,
                  mapboxSegmentHoverPopupRef,
                  mapboxVolumesPopupRef,
                  showRoadVolumesRef,
                  setVolumeProps,
                  selectRoadVolume,
                  selectRoadLink,
                  ids: getVisibleRoadIds(),
                  zoneIds,
                  roadClasses,
                  minAllowedVolume: 5, // @TODO: get from SL metadata
                  isExportPermitted: roadsMetadata.data?.exportPermissions?.allowExport,
                  getVisibilityStatus,
                  changeShowRoadVolumes,
                  filterRoadSegmentsByRoadClasses,
                  filterRoadSegmentsByRange,
                  updateFeatureStateWithSelectedLinks,
                  updateFeatureStateWithSelectedODs,
                },
              },
        );
      }
    }
  }, [
    mode,
    selectLinkConfig.state,
    selectLinkConfig.data?.segmentsGroups,
    roadsMetadata.data,
    ODMetadata,
    ODIds.data,
    roadsVolumes.data?.maxVolume,
    roadsVolumes.data?.segmentVolumes,
    selectedFocusArea,
    isRoadsDataAvailable,
    roadSegmentIdsByRoadClass,
    segmentVolumesRef,
    maxSegmentVolumeRef,
    mapboxSegmentHoverPopupRef,
    mapboxVolumesPopupRef,
    showRoadVolumesRef,
    roadClasses,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    setVolumeProps,
    selectRoadVolume,
    selectRoadVolumeId,
    getVisibleRoadIds,
    getVisibilityStatus,
    changeShowRoadVolumes,
    filterRoadSegmentsByRoadClasses,
    filterRoadSegmentsByRange,
    updateFeatureStateWithSelectedLinks,
    updateFeatureStateWithSelectedODs,
  ]);

  // Set results module data
  useEffect(() => {
    if (
      mode === SelectLinkMode.RESULTS &&
      selectLinkConfig.state === DataState.AVAILABLE &&
      selectLinkSegmentCounts.state === DataState.AVAILABLE
    ) {
      const isAllDataAvailable = isODZonesDataAvailable && isRoadsDataAvailable;

      if (selectedFocusArea && isAllDataAvailable) {
        setSelectLinkConfigZonesModuleData(null);
        setSelectLinkConfigLinksModuleData(null);

        segmentVolumesRef.current = selectLinkSegmentCounts.data?.segmentVolumes;
        maxSegmentVolumeRef.current = selectLinkSegmentCounts.data?.maxVolume || 1;

        const roadsSources = getRoadsSources(roadsMetadata.data!.tileService);
        const roadsLayers = getRoadsLayers(roadsMetadata.data!.tileService, true);
        const ODSourses = getODSources(ODMetadata.data!.tileService);
        const selectLinkSelectedZonesLayers = getODLayers(
          ODMetadata.data!.tileService,
          true,
        ).selectLinkSelectedZonesLayers;
        const zoneIds: ZoneIds | null = ODIds.data;

        setSelectLinkResultsModuleData((data) =>
          data
            ? data
            : {
                sources: [...roadsSources, ...ODSourses],
                layers: [...selectLinkSelectedZonesLayers, ...roadsLayers],
                data: {
                  isSelectLinkResults: true,
                  segmentGroups: selectLinkConfig.data?.segmentsGroups,
                  roadsTileService: roadsMetadata.data!.tileService,
                  ODTileservice: ODMetadata.data!.tileService,
                  roadSegmentIdsByRoadClass,
                  segmentVolumesRef,
                  maxSegmentVolumeRef,
                  mapboxSegmentHoverPopupRef,
                  mapboxVolumesPopupRef,
                  showRoadVolumesRef,
                  setVolumeProps,
                  ids: getVisibleRoadIdsForResults(),
                  zoneIds,
                  roadClasses,
                  isExportPermitted: roadsMetadata.data?.exportPermissions?.allowExport,
                  minAllowedVolume: 5, // @TODO: get from SL metadata
                  getVisibilityStatus,
                  changeShowRoadVolumes,
                  filterRoadSegmentsByRoadClasses,
                  filterRoadSegmentsByRange,
                  updateFeatureStateWithSelectedLinks,
                  updateFeatureStateWithSelectedODs,
                },
              },
        );
      }
    }
  }, [
    mode,
    selectLinkConfig.state,
    selectLinkConfig.data?.segmentsGroups,
    roadsMetadata.data,
    ODMetadata.data,
    ODIds.data,
    selectedFocusArea,
    selectLinkSegmentCounts,
    isODZonesDataAvailable,
    isRoadsDataAvailable,
    roadSegmentIdsByRoadClass,
    segmentVolumesRef,
    maxSegmentVolumeRef,
    mapboxSegmentHoverPopupRef,
    mapboxVolumesPopupRef,
    showRoadVolumesRef,
    roadClasses,
    setSelectLinkConfigZonesModuleData,
    setSelectLinkConfigLinksModuleData,
    setSelectLinkResultsModuleData,
    setVolumeProps,
    getVisibilityStatus,
    changeShowRoadVolumes,
    filterRoadSegmentsByRoadClasses,
    filterRoadSegmentsByRange,
    updateFeatureStateWithSelectedLinks,
    updateFeatureStateWithSelectedODs,
    getVisibleRoadIdsForResults,
  ]);

  return null;
};
