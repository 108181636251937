import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Feature } from "geojson";
import { LngLatLike, Map, MapLayerMouseEvent, Popup } from "mapbox-gl";
import React, { ReactNode, forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";

import { ValidationMessage, ValidationMessageDetail, ValidationMessageSeverity } from "types";

import { ZoningPopup } from "./ZoningPopup";

interface Props {
  children?: ReactNode;
  map: Map | null;
  mapLoaded: boolean;
  zoningId: string;
  zoningIdField: string;
  validationMessages: ValidationMessage[];
  popup: Popup | null;
  minZoom: number;
  maxZoom: number;
  handleDeselectIssue: () => void;
  handleOpenPopup: (newPopup: Popup, lngLat: LngLatLike, popupContent: ReactNode) => void;
  handleClosePopup: () => void;
}

type ValidationIssues = { [key: string]: ValidationMessageDetail[] | undefined };

export enum ZoningMap {
  SOURCE = "zoning",
  FILL_LAYER = "zoning-bounds",
  BOUNDS_LAYER = "zoning-fill",
}

interface HighlightedFeature {
  id: string | number;
  source: string;
  sourceLayer?: string;
}

const defaultLayers = ["admin_country", "road-label"];

export const MapPreview = forwardRef<any, Props>(
  (
    {
      children,
      map,
      mapLoaded,
      validationMessages,
      zoningId,
      zoningIdField,
      popup,
      minZoom,
      maxZoom,
      handleDeselectIssue,
      handleOpenPopup,
      handleClosePopup,
    },
    mapContainerRef,
  ) => {
    const theme = useTheme();

    const hoveredFeatures = useRef<HighlightedFeature[] | null>(null);
    const selectedFeatures = useRef<HighlightedFeature[] | null>(null);

    const validationIssues = useMemo(
      () =>
        validationMessages.reduce((obj: ValidationIssues, message) => {
          const newMessageDetails = message.details?.map((messageDetail) => ({
            ...messageDetail,
            locations: messageDetail.locations?.map((location) => ({ ...location, id: uuid() })),
          }));
          if (!obj[message.severity]) {
            obj[message.severity] = newMessageDetails;
          } else if (newMessageDetails) {
            obj[message.severity]?.push(...newMessageDetails);
          }
          return obj;
        }, {}),
      [validationMessages],
    );

    const playDownFeatures = useCallback(
      (highlightedFeatures: HighlightedFeature[] | null, featureStateKey: string) => {
        if (highlightedFeatures && map) {
          highlightedFeatures.forEach((feature) => {
            const { id, source, sourceLayer } = feature;
            map.setFeatureState(
              {
                source: source,
                sourceLayer: sourceLayer,
                id: id,
              },
              { [featureStateKey]: false },
            );
          });
          highlightedFeatures = null;
        }
      },
      [map],
    );

    useEffect(() => {
      const mapLayers: string[] = [];

      const handleClick = (e: MapLayerMouseEvent) => {
        if (map) {
          handleDeselectIssue();
          handleClosePopup();
          playDownFeatures(selectedFeatures.current, "select");

          const features = map.queryRenderedFeatures(map?.project(e.lngLat), {
            layers: [ZoningMap.FILL_LAYER, ...mapLayers],
          });

          if (features?.length) {
            const newSelectedFeatures: HighlightedFeature[] = [];

            const topFeatureType = features[0].properties?.type;
            const filteredFeatures = features.filter(({ properties }) =>
              topFeatureType ? properties?.type === topFeatureType : true,
            );

            handleOpenPopup(
              new Popup({ offset: 8 }),
              e.lngLat,
              <ZoningPopup type={topFeatureType ? topFeatureType : "zone"} features={filteredFeatures} />,
            );

            for (let feature of filteredFeatures) {
              const { id, source, sourceLayer } = feature;
              if (id && source) {
                map.setFeatureState(
                  {
                    source: source,
                    sourceLayer: sourceLayer,
                    id: id,
                  },
                  { select: true },
                );
                newSelectedFeatures.push({ id, source, sourceLayer });
              }
            }

            selectedFeatures.current = newSelectedFeatures;
          }
        }
      };

      const handleMove = (e: MapLayerMouseEvent) => {
        if (map) {
          map.getCanvas().style.cursor = "default";

          playDownFeatures(hoveredFeatures.current, "hover");

          const features = e.features;

          if (features?.length) {
            const newHoveredFeatures: HighlightedFeature[] = [];
            const isTopFeatureIssue = Boolean(features[0].properties?.type);

            for (let feature of features) {
              const { id, source, sourceLayer } = feature;
              if (id && source) {
                map.setFeatureState(
                  {
                    source: source,
                    sourceLayer: sourceLayer,
                    id: id,
                  },
                  { hover: true },
                );
                newHoveredFeatures.push({ id, source, sourceLayer });
              }

              if (isTopFeatureIssue) {
                hoveredFeatures.current = newHoveredFeatures;
                return;
              }
            }

            hoveredFeatures.current = newHoveredFeatures;
          }
        }
      };

      const handleLeave = () => {
        if (map) {
          map.getCanvas().style.cursor = "";

          playDownFeatures(hoveredFeatures.current, "hover");
        }
      };

      if (map && mapLoaded && zoningId && zoningIdField) {
        const existingDefaultLayers = defaultLayers.filter((layer) => !!map.getLayer(layer));

        map.addSource(ZoningMap.SOURCE, {
          type: "vector",
          promoteId: zoningIdField,
          tiles: [`${process.env.REACT_APP_API_HOST}/analytics/zoning/${zoningId}/tile/{z}/{x}/{y}.pbf`],
          minzoom: minZoom,
          maxzoom: maxZoom,
        });

        map.addLayer(
          {
            id: ZoningMap.BOUNDS_LAYER,
            type: "line",
            source: ZoningMap.SOURCE,
            "source-layer": "default",
            paint: {
              "line-color": "#0067b0",
              "line-width": 1,
            },
          },
          ...existingDefaultLayers,
        );

        map.addLayer(
          {
            id: ZoningMap.FILL_LAYER,
            type: "fill",
            source: ZoningMap.SOURCE,
            "source-layer": "default",
            paint: {
              "fill-color": "#0067b0",
              "fill-opacity": [
                "case",
                ["boolean", ["feature-state", "select"], false],
                0.4,
                ["boolean", ["feature-state", "hover"], false],
                0.2,
                0.1,
              ],
            },
          },
          ...existingDefaultLayers,
        );

        for (let issuesSeverity in validationIssues) {
          if (map.getLayer(`${issuesSeverity}`)) {
            map.removeLayer(`${issuesSeverity}`);
          }

          if (map.getSource(`${issuesSeverity}-source`)) {
            map.removeSource(`${issuesSeverity}-source`);
          }

          const issuesWithPositions = validationIssues[issuesSeverity]?.filter((issue) => issue.locations);

          if (issuesWithPositions) {
            const issueFeatures = issuesWithPositions.reduce((arr: Feature[], issue) => {
              issue.locations?.forEach((location) =>
                arr.push({
                  type: "Feature",
                  properties: {
                    id: location.id,
                    text: issue.text,
                    type: issuesSeverity,
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [location.lon, location.lat],
                  },
                }),
              );
              return arr;
            }, []);

            map.addSource(`${issuesSeverity}-source`, {
              type: "geojson",
              promoteId: "id",
              data: {
                type: "FeatureCollection",
                features: issueFeatures,
              },
            });

            map.addLayer(
              {
                id: `${issuesSeverity}`,
                type: "circle",
                source: `${issuesSeverity}-source`,
                paint: {
                  "circle-color":
                    issuesSeverity === ValidationMessageSeverity.Warning
                      ? [
                          "case",
                          ["boolean", ["feature-state", "select"], false],
                          theme.palette.warning.dark,
                          ["boolean", ["feature-state", "hover"], false],
                          theme.palette.warning.main,
                          theme.palette.warning.light,
                        ]
                      : [
                          "case",
                          ["boolean", ["feature-state", "select"], false],
                          theme.palette.error.dark,
                          ["boolean", ["feature-state", "hover"], false],
                          theme.palette.error.main,
                          theme.palette.error.light,
                        ],
                  "circle-radius": 6,
                  "circle-stroke-width": 2,
                  "circle-stroke-color": "#ffffff",
                },
              },
              ...existingDefaultLayers,
            );

            mapLayers.push(`${issuesSeverity}`);
          }
        }

        map.on("click", handleClick);
        map.on("mousemove", [ZoningMap.FILL_LAYER, ...mapLayers], handleMove);
        map.on("mouseleave", [ZoningMap.FILL_LAYER, ...mapLayers], handleLeave);
      }

      return () => {
        if (map?.getLayer(ZoningMap.BOUNDS_LAYER)) {
          map?.removeLayer(ZoningMap.BOUNDS_LAYER);
        }

        if (map?.getLayer(ZoningMap.FILL_LAYER)) {
          map?.removeLayer(ZoningMap.FILL_LAYER);
        }

        if (map?.getSource(ZoningMap.SOURCE)) {
          map?.removeSource(ZoningMap.SOURCE);
        }

        mapLayers.forEach((layer) => {
          if (map?.getLayer(layer)) map?.removeLayer(layer);
          if (map?.getSource(`${layer}-source`)) map?.removeSource(`${layer}-source`);
        });

        map?.off("click", handleClick);
        map?.off("mousemove", [ZoningMap.FILL_LAYER, ...mapLayers], handleMove);
        map?.off("mouseleave", [ZoningMap.FILL_LAYER, ...mapLayers], handleLeave);
      };
    }, [
      handleClosePopup,
      handleDeselectIssue,
      handleOpenPopup,
      playDownFeatures,
      map,
      mapLoaded,
      theme.palette.error,
      theme.palette.warning,
      validationIssues,
      zoningId,
      zoningIdField,
      minZoom,
      maxZoom,
    ]);

    useEffect(() => {
      popup?.on("close", () => {
        playDownFeatures(selectedFeatures.current, "select");
      });
    }, [popup, playDownFeatures]);

    return (
      <Box sx={{ height: "540px", borderRadius: "8px", overflow: "hidden" }}>
        {children}
        <Box ref={mapContainerRef} height={"100%"} />
      </Box>
    );
  },
);
