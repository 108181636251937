import scaleCluster from "d3-scale-cluster";

import { sequentialSchemes } from "features/map/layerColors";
import { getSqMiDensity } from "features/map/utils";

import { RoadVmtMetadata, RoadVmtZoneCounts, ZoneIds } from "types";

export const getAvailableLayers = (roadVmtMetadata: RoadVmtMetadata) => {
  const { tileService, detailLevel: roadVmtLevel } = roadVmtMetadata;
  const { layers } = tileService;
  return layers.filter(({ level }) => level === roadVmtLevel);
};

export const getColorScale = (
  layerZoomLevel: string,
  zoneIds: ZoneIds,
  zoneCounts: RoadVmtZoneCounts,
  colorScheme: string,
  byArea: boolean = true,
) => {
  const layerIDs = zoneIds.get(layerZoomLevel) || new Map();
  const density: number[] = [];

  for (const [zoneId, stats] of layerIDs) {
    const count = zoneCounts?.zones?.get(zoneId) ?? 0;
    density.push(byArea ? getSqMiDensity(count, stats.sqKm) : Math.floor(count / (stats?.pop ?? 1.0)));
  }

  const currentColorScheme = sequentialSchemes[colorScheme];
  return scaleCluster().domain(density).range(currentColorScheme);
};
