import styled from "@emotion/styled";
import * as Sentry from "@sentry/react";
import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { DOCS_ROUTES } from "types";

import { DataQuality } from "./DataQuality";
import { Faq } from "./Faq";
import { Footer } from "./Footer";
import { Home } from "./Home";
import { Product } from "./Product";
import { TermsOfUse } from "./TermsOfUse";
import { Tutorials } from "./Tutorials";
import { Webinars } from "./Webinars";

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 64px;
`;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const DocsPage: FC = () => (
  <Container>
    <div>
      <SentryRoutes>
        <Route path={DOCS_ROUTES.Home} element={<Home />} />
        <Route path={DOCS_ROUTES.Tutorials} element={<Tutorials />} />
        <Route path={DOCS_ROUTES.Webinars} element={<Webinars />} />
        <Route path={`${DOCS_ROUTES.Product}/*`} element={<Product />} />
        <Route path={DOCS_ROUTES.DataQuality} element={<DataQuality />} />
        <Route path={DOCS_ROUTES.Faq} element={<Faq />} />
        <Route path={DOCS_ROUTES.Terms} element={<TermsOfUse />} />
      </SentryRoutes>
    </div>
    <Footer />
  </Container>
);
