import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Insights } from "@mui/icons-material";
import { Button, ConfirmDialog, DialogContentText } from "components_new";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { EditSelectLinkOptionsDialog, SelectLinkOptionsDialog } from "features/select-link/SelectLinkOptionsDialog";

import { CardContainer, FlexContainer } from "components";

import { PageContainer } from "components/atoms/page-container/PageContainer";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { DataState } from "store/interfaces";
import { selectLicensedAreasByAllowSelectLink } from "store/permissionsSelectors";
import { globalActions } from "store/sections/global";
import { selectLinkActions } from "store/sections/selectLink";

import { ROUTES, SelectLinkConfigUpdateRequest } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { EmptyItem } from "./EmptyItem";
import { Item } from "./Item";
import { SelectLinkAnalysisDetails } from "./select-link/types";

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin: 2rem 0 1.5rem 0;
`;

const HeaderActions = styled(FlexContainer)`
  gap: 1rem;
`;

const Title = styled.h1`
  font-size: 20px;
  margin: 0;
`;

const TableCard = styled(CardContainer)`
  height: 100%;
  padding: 1rem;
`;

const TableContainer = styled.div`
  min-height: 300px;
  max-height: 100%;
  overflow-y: auto;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1px 250px 250px 80px 1fr 80px 80px 90px 31px;
  grid-template-rows: 1fr;
  column-gap: 24px;
  align-items: center;
  background-color: var(--color-gray-50);
  color: var(--color-textSecondary);
  height: 36px;
  font-size: 12px;
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
`;

const Placeholder = styled(FlexContainer)`
  justify-content: center;
  padding: 200px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-textSecondary);
`;

export const AnalyticsPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [searchParams] = useSearchParams();

  usePageTracking();

  const [isShowSelectLinkOptionsDialog, setIsShowSelectLinkOptionsDialog] = useState(false);
  const [deletedConfigIds, setDeletedConfigIds] = useState<string[]>([]);
  const [configItemToDelete, setConfigItemToDelete] = useState<string | null>(null);
  const [isShowDeleteConfigItemConfirm, setIsShowDeleteConfigItemConfirm] = useState(false);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const selectLinkConfigs = useAppSelector((state) => state.selectLink.configs);
  const newSelectLinkConfigId = useAppSelector((state) => state.selectLink.newConfigId);
  const isConfigUpdated = useAppSelector((state) => state.selectLink.isUpdated);

  const [activeAnalysisName, setActiveAnalysisName] = useState<string>("");
  const [activeAnalysisDescription, setActiveAnalysisDescription] = useState<string>("");
  const [activeFocusAreaId, setActiveFocusAreaId] = useState<string>("");
  const [activeConfigId, setActiveConfigId] = useState<string | undefined>(undefined);

  const permissions = useAppSelector((state) => state.license.permissions.data);
  const aoiAreas = useAppSelector(selectLicensedAreasByAllowSelectLink);

  const sortedAoiAreas = useMemo(() => aoiAreas.sort((a, b) => a.label.localeCompare(b.label)), [aoiAreas]);

  const errorDialogMessage = useAppSelector((state) => state.selectLink.errorDialogMessage);

  const isSelectLinkAllowed = useCallback(
    (licensedAreaId: number): boolean =>
      Boolean(
        permissions?.licensedAreas.find((la) => la.licensedAreaId === licensedAreaId)?.softwarePermissions.selectLink
          .allowSelectLink.Allowed,
      ),
    [permissions?.licensedAreas],
  );

  const onNewAnalysis = () => {
    setIsShowSelectLinkOptionsDialog(true);
    resetActiveConfigDetails();
  };

  const resetActiveConfigDetails = useCallback(() => {
    setActiveConfigId(undefined);
    setActiveAnalysisName("");
    setActiveAnalysisDescription("");

    dispatch(selectLinkActions.clearErrorMessages());
  }, [dispatch]);

  const handleCreateNewAnalysis = (params: SelectLinkAnalysisDetails) => {
    const { analysisName, timePeriod, description, focusAreaId } = params;
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId, timePeriod }));
    }
    dispatch(
      selectLinkActions.createSelectLinkConfig({
        analysisName,
        description,
        timePeriod,
      }),
    );

    addCustomGAEvent("select-link", "analysis", "create-new-analysis", user, userOrganizationName);
  };

  const handleRenameAnalysis = (params: SelectLinkAnalysisDetails) => {
    const { analysisName, description, configId } = params;
    if (configId) {
      const renameRequest = {
        analysisName,
        description,
      } as SelectLinkConfigUpdateRequest;
      dispatch(selectLinkActions.updateSelectLinkConfig(configId, renameRequest));

      addCustomGAEvent("select-link", "analysis", "rename-analysis", user, userOrganizationName);
    }
  };

  const handleCloseAddNewAnalysisDialog = () => {
    setIsShowSelectLinkOptionsDialog(false);
    resetActiveConfigDetails();
  };

  const handleDeleteSelectLinkConfig = (configId: string) => {
    setConfigItemToDelete(configId);
    setIsShowDeleteConfigItemConfirm(true);
  };

  const handleDeleteSelectLinkConfigCancel = () => {
    setConfigItemToDelete(null);
    setIsShowDeleteConfigItemConfirm(false);
  };

  const handleDeleteSelectLinkConfigConfirm = () => {
    if (configItemToDelete) {
      dispatch(selectLinkActions.deleteSelectLinkConfig(configItemToDelete));
      setDeletedConfigIds([...deletedConfigIds, configItemToDelete]);
      setConfigItemToDelete(null);
      setIsShowDeleteConfigItemConfirm(false);
    }
  };

  const onRenameAnalysis = (configId: string, areaId: string, name: string, description?: string) => {
    setActiveConfigId(configId);
    setActiveFocusAreaId(areaId);
    setActiveAnalysisName(name);
    setActiveAnalysisDescription(description || "");
    setIsShowSelectLinkOptionsDialog(true);
  };

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(selectLinkActions.listSelectLinkConfigs());
    }
  }, [isTokenLoaded, dispatch]);

  useEffect(() => {
    if (isConfigUpdated) {
      dispatch(selectLinkActions.listSelectLinkConfigs());
      resetActiveConfigDetails();
      setIsShowSelectLinkOptionsDialog(false);
    }
  }, [dispatch, isConfigUpdated, resetActiveConfigDetails]);

  useEffect(() => {
    if (newSelectLinkConfigId && selectedFocusAreaId) {
      setIsShowSelectLinkOptionsDialog(false);
      dispatch(selectLinkActions.clearNewSelectLinkConfig());
      searchParams.set("id", selectedFocusAreaId);
      navigate(ROUTES.SelectLinkEditor.replaceAll(":configId", newSelectLinkConfigId) + "?" + searchParams.toString());
    }
  }, [newSelectLinkConfigId, selectedFocusAreaId, dispatch, navigate, searchParams]);

  const defaultSelectedFocusArea = useMemo(() => {
    if (!selectedFocusAreaId || !sortedAoiAreas || sortedAoiAreas.length === 0) return null;
    return sortedAoiAreas.find((area) => area.id === selectedFocusAreaId) || sortedAoiAreas[0];
  }, [sortedAoiAreas, selectedFocusAreaId]);

  const configs = (selectLinkConfigs?.data || [])
    .filter(({ configId }) => !deletedConfigIds.includes(configId))
    .sort((a, b) => a.analysisName.localeCompare(b.analysisName));

  return (
    <PageContainer>
      <aside />
      <div>
        <Header>
          <Title>Analyses</Title>
          <HeaderActions>
            <Button startIcon={<Insights />} variant="outlined" size="medium" onClick={onNewAnalysis}>
              New Select Link Analysis
            </Button>
          </HeaderActions>
        </Header>
        <TableCard>
          <TableContainer>
            <TableHeader>
              <div />
              <div>Name</div>
              <div>Area of interest</div>
              <div>Year</div>
              <div>Description</div>
              <div>Created</div>
              <div>Last modified</div>
            </TableHeader>
            {selectLinkConfigs.state === DataState.AVAILABLE && (!configs || configs.length === 0) ? (
              <Placeholder>
                Click{" "}
                <Link onClick={onNewAnalysis} style={{ margin: "0 0.25rem" }} to="">
                  New Select Link Analysis
                </Link>{" "}
                to create first select link analysis
              </Placeholder>
            ) : (
              <>
                {selectLinkConfigs.state === DataState.LOADING || selectLinkConfigs.state === DataState.EMPTY ? (
                  <EmptyItem />
                ) : (
                  configs.map((config, num) => (
                    <Item
                      key={`select-link-item_${num}`}
                      data={config}
                      canUpdate={isSelectLinkAllowed(config.licensedAreaId)}
                      canDelete={user?.email === config.createdBy.email}
                      canEdit={isSelectLinkAllowed(config.licensedAreaId)}
                      onDeleteConfig={handleDeleteSelectLinkConfig}
                      onRenameConfig={onRenameAnalysis}
                    />
                  ))
                )}
              </>
            )}
          </TableContainer>
        </TableCard>
        {isShowSelectLinkOptionsDialog && defaultSelectedFocusArea && sortedAoiAreas && (
          <>
            {activeConfigId ? (
              <EditSelectLinkOptionsDialog
                open
                areas={sortedAoiAreas}
                defaultAreaId={activeFocusAreaId}
                configId={activeConfigId}
                initialName={activeAnalysisName}
                initialDescription={activeAnalysisDescription}
                errorMessage={errorDialogMessage}
                onSubmit={handleRenameAnalysis}
                onClose={handleCloseAddNewAnalysisDialog}
              />
            ) : (
              <SelectLinkOptionsDialog
                open
                title="New Select Link analysis"
                buttonLabel="Create analysis"
                areas={sortedAoiAreas}
                defaultAreaId={defaultSelectedFocusArea.id}
                errorMessage={errorDialogMessage}
                onSubmit={handleCreateNewAnalysis}
                onClose={handleCloseAddNewAnalysisDialog}
              />
            )}
          </>
        )}
      </div>
      <aside />
      {isShowDeleteConfigItemConfirm && configItemToDelete && (
        <ConfirmDialog
          open={Boolean(isShowDeleteConfigItemConfirm && configItemToDelete)}
          title={`Delete select link configuration?`}
          onCancel={handleDeleteSelectLinkConfigCancel}
          onConfirm={handleDeleteSelectLinkConfigConfirm}
        >
          <DialogContentText>{`Are you sure you want to delete the "${
            configs.filter((config) => config.configId === configItemToDelete)?.[0]?.analysisName ?? configItemToDelete
          }" configuration?`}</DialogContentText>
        </ConfirmDialog>
      )}
    </PageContainer>
  );
};
