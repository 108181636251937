import { ThumbUpAltOutlined } from "@mui/icons-material";
import { Alert, Grid, Stack, Typography } from "@mui/material";
import { ScreenlineImportValidationMessage } from "api/analytics/index";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { Map } from "mapbox-gl";
import { FC } from "react";

import { getBounds } from "features/map/utils";

export interface ImportValidationTabProps {
  map: React.MutableRefObject<Map | null>;
  validationMessages: ScreenlineImportValidationMessage[];
  preparedFeatures: Feature<Geometry, GeoJsonProperties>[];
}

export const ImportValidationTab: FC<ImportValidationTabProps> = ({ map, validationMessages, preparedFeatures }) => {
  return (
    <Stack spacing={1} marginY={3} sx={{ height: "210px", overflowY: "auto" }}>
      {validationMessages?.length ? (
        validationMessages.map((msg, i) => {
          const featureGeometry = preparedFeatures?.find((f) => f.properties?.__index === msg.featureIndex)
            ?.geometry as any;

          return (
            <Alert
              key={i}
              severity={msg.severity}
              sx={{
                padding: "0 16px",
                fontSize: 11,
                alignItems: "center",
                cursor: msg.featureIndex ? "pointer" : "default",
              }}
              onClick={() => featureGeometry && map.current?.fitBounds(getBounds(featureGeometry), { padding: 20 })}
            >
              {msg.text}
            </Alert>
          );
        })
      ) : (
        <Grid container justifyContent={"center"} alignItems={"center"} height={140}>
          <ThumbUpAltOutlined color="secondary" /> <Typography marginLeft={1}>Validation successful</Typography>
        </Grid>
      )}
    </Stack>
  );
};
