import { FolderOpenOutlined, Polyline } from "@mui/icons-material";
import { Autocomplete, Collapse, Grid, Stack, Typography } from "@mui/material";
import { Button, Checkbox, Dialog, TextField } from "components_new";
import { FC, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions } from "store/sections/screenlines";
import { datasetFoldersActions } from "store/sections/userFolders";

import { ConfigDocument } from "types";

interface ConfigItem {
  item: ConfigDocument;
  folderName: string;
  folderId: string;
}

type LoadOptions = "replace" | "append";

export const ScreenlineLoadDialog: FC = () => {
  const dispatch = useAppDispatch();

  const serchedConfigDocuments = useAppSelector((state) => state.datasetFolders.searchedConfigDocuments);
  const isLoadScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isLoadScreenlinesDialogOpen);

  const screenlines = useAppSelector((state) => state.screenlines.screenlines);

  const [selectedConfigItem, setSelectedConfigItem] = useState<ConfigItem | null>(null);
  const [selectedOption, setSelectedOption] = useState<LoadOptions>("replace");

  const areOptionsVisible = Boolean(screenlines.length);

  const handleCloseDialog = () => {
    dispatch(screenlinesActions.setIsLoadScreenlinesDialogOpen(false));
    setSelectedConfigItem(null);
    setSelectedOption("replace");
  };

  const handleLoadScreenlines = () => {
    if (selectedConfigItem) {
      dispatch(datasetFoldersActions.loadConfigDocument(selectedConfigItem.item, { screenlines: selectedOption }));
      dispatch(screenlinesActions.setSelectedScreenlineId(null));
      handleCloseDialog();
    }
  };

  const handleToggleAppendOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.checked ? "append" : "replace");
  };

  useEffect(() => {
    dispatch(
      datasetFoldersActions.searchConfigDocuments({
        type: "screenlines",
        filterByLicensedArea: true,
        excludeEmptyFolders: true,
        folderId: null,
        timePeriod: null,
      }),
    );
  }, [dispatch]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isLoadScreenlinesDialogOpen}
      onClose={handleCloseDialog}
      title="Open saved screenlines"
      actions={
        <Stack direction={"row"} spacing={1}>
          <Button color="secondary" variant="outlined" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button color="secondary" disabled={!selectedConfigItem} onClick={handleLoadScreenlines}>
            Open
          </Button>
        </Stack>
      }
    >
      <Stack spacing={1} marginTop={1}>
        <Autocomplete
          options={
            serchedConfigDocuments.data?.reduce((arr: ConfigItem[], folder) => {
              folder.items.forEach((item) =>
                arr.push({ item, folderName: folder.folderName, folderId: folder.folderId }),
              );
              return arr;
            }, []) || []
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Screenline documents"
              placeholder="Select a document..."
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          )}
          getOptionLabel={(option: ConfigItem) => option.item.name}
          groupBy={(option: ConfigItem) => option.folderName}
          renderGroup={(params) => (
            <div key={params.key}>
              <Grid container alignItems={"center"} paddingX={1} gap={1} color={"text.secondary"}>
                <FolderOpenOutlined fontSize="small" />
                <Typography>{params.group}</Typography>
              </Grid>
              <Grid paddingLeft={1}>{params.children}</Grid>
            </div>
          )}
          renderOption={(props, option) => (
            <Grid container alignItems={"center"} gap={1} component={"li"} {...props}>
              <Polyline fontSize="inherit" color="secondary" />
              <Typography variant={"subtitle2"}>{option.item.name}</Typography>
            </Grid>
          )}
          value={selectedConfigItem}
          noOptionsText={"No screenline documents found for this area"}
          onChange={(event: any, newValue: ConfigItem | null) => {
            setSelectedConfigItem(newValue);
          }}
        />
        <Collapse in={areOptionsVisible}>
          <Checkbox
            label="Append to existing screenlines"
            checked={selectedOption === "append"}
            onChange={handleToggleAppendOption}
          />
        </Collapse>
      </Stack>
    </Dialog>
  );
};
