import { Box } from "@mui/material";
import { ScreenlineImportValidationMessage } from "api/analytics/index.d";
import { Feature } from "geojson";
import { Map, NavigationControl } from "mapbox-gl";
import { FC, MutableRefObject, useEffect, useRef } from "react";

import { MapBoxStyles } from "features/map/baseMapStyles";
import { getBounds } from "features/map/utils";

import { getMapHomeControl } from "components";

import theme from "theme";

import { ValidationMessageSeverity } from "types";

import { getScreenlinesBboxPolygon } from "./utils";

export interface ImportScreenlinesPreviewMapProps {
  map: MutableRefObject<Map | null>;
  screenlinesFeatures: Feature[];
  validationMessages: ScreenlineImportValidationMessage[];
}

export const ImportScreenlinesPreviewMap: FC<ImportScreenlinesPreviewMapProps> = ({
  map,
  screenlinesFeatures,
  validationMessages,
}) => {
  //   const [mapLoaded, setMapLoaded] = useState(false);

  const mapContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (map.current || !mapContainerRef.current) return; // initialize map only once

    const boundingBox = getScreenlinesBboxPolygon(screenlinesFeatures as any);

    map.current = new Map({
      container: mapContainerRef.current,
      style: MapBoxStyles.Default,
      bounds: getBounds(boundingBox),
      fitBoundsOptions: {
        padding: 20,
      },
      pitch: 0,
      bearing: 1,
      logoPosition: "bottom-right",
      transformRequest: (url, resourceType): any => {
        if (resourceType === "Tile" && url.startsWith(process.env.REACT_APP_API_HOST as string)) {
          return {
            headers: {
              authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
            url,
          };
        }
      },
    });
    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    // Add full extent control to the map.
    map.current.addControl(
      getMapHomeControl(map.current, getBounds(boundingBox), {
        padding: 20,
      }),
      "bottom-left",
    );

    // Add zoom controls to the map.
    map.current.addControl(new NavigationControl({ showCompass: false }), "bottom-left");

    map.current.on("load", () => {
      map.current?.addSource("screenlines", {
        type: "geojson",
        data: {
          type: "FeatureCollection",

          features: screenlinesFeatures.map((f) => ({
            ...f,
            id: f.properties?.__index,
            properties: {
              ...f.properties,
              errorSeverity: validationMessages.find((m) => String(m.featureIndex) === String(f.properties?.__index))
                ?.severity,
            },
          })),
        },
      });

      map.current?.addLayer({
        id: "screenlines",
        type: "line",
        source: "screenlines",
        paint: {
          "line-color": [
            "case",
            ["==", ["get", "errorSeverity"], ValidationMessageSeverity.Error],
            `${theme.palette.error.main}`,
            ["==", ["get", "errorSeverity"], ValidationMessageSeverity.Warning],
            `${theme.palette.warning.main}`,
            `${theme.palette.screenline.line}`,
          ],
          "line-width": ["interpolate", ["exponential", 1.6], ["zoom"], 6, 2, 20, 35],
        },
      });

      //   setMapLoaded(true);
    });
  }, [map, screenlinesFeatures, validationMessages]);

  return (
    <Box sx={{ height: "536px", borderRadius: "8px", overflow: "hidden" }}>
      <Box ref={mapContainerRef} height={"100%"} />
    </Box>
  );
};
