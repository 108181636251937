import { Api } from "api";
import { produce } from "immer";
import { Reducer } from "redux";
import { all, call, getContext, put, takeLatest } from "redux-saga/effects";

import { Action, ActionsUnion, createAction } from "store/actionHelpers";
import { DataState, LoadingErrorData, ResponseError } from "store/interfaces";
import { extendODMetadata } from "store/utils";

import {
  FiltersType,
  RoadVmtMetadata,
  RoadVmtMetadataRequest,
  RoadVmtZoneCounts,
  RoadVmtZoneCountsRequest,
  RoadVmtZoneDetails,
  RoadVmtZoneDetailsRequest,
  SelectedZone,
  ZoneIds,
  ZoneIdsArguments,
} from "types";

import { reportAboutErrorState } from "utils/reports";

import { RoadVmtActionType } from "./actionTypes";

export interface RoadVmtState {
  roadVmtMetadata: LoadingErrorData<RoadVmtMetadata>;
  roadVmtZoneCounts: LoadingErrorData<RoadVmtZoneCounts>;
  roadMileageZoneCounts: LoadingErrorData<RoadVmtZoneCounts>;
  filters: FiltersType | null;
  selectedZone: SelectedZone | null;
  roadVmtZoneDetails: LoadingErrorData<RoadVmtZoneDetails>;
  mileageZoneDetails: LoadingErrorData<RoadVmtZoneDetails>;
  zoneIds: LoadingErrorData<ZoneIds>;
}

export type RoadVmtAction = ActionsUnion<typeof roadVmtActions>;

export const roadVmtActions = {
  fetchRoadVmtMetadata: (config: RoadVmtMetadataRequest) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_METADATA, config),
  fetchRoadVmtMetadataSucceeded: (roadVmtMetadata: RoadVmtMetadata) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_METADATA_SUCCEEDED, {
      roadVmtMetadata,
    }),
  fetchRoadVmtMetadataFailed: (error: ResponseError) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_METADATA_FAILED, error),

  clearRoadVmtMetadata: () => createAction(RoadVmtActionType.CLEAR_ROAD_VMT_METADATA),

  fetchRoadVmtZoneCounts: (level: string, config: RoadVmtZoneCountsRequest) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS, { level, config }),
  fetchRoadVmtZoneCountsSucceeded: (roadVmtZoneCounts: RoadVmtZoneCounts) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_SUCCEEDED, {
      roadVmtZoneCounts,
    }),
  fetchRoadVmtZoneCountsFailed: (error: ResponseError) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_FAILED, error),

  fetchRoadMileageZoneCounts: (level: string, config: RoadVmtZoneCountsRequest) =>
    createAction(RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS, { level, config }),
  fetchRoadMileageZoneCountsSucceeded: (roadMileageZoneCounts: RoadVmtZoneCounts) =>
    createAction(RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_SUCCEEDED, {
      roadMileageZoneCounts,
    }),
  fetchRoadMileageZoneCountsFailed: (error: ResponseError) =>
    createAction(RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED, error),

  updateCurrentFilters: (filters: FiltersType | null) => createAction(RoadVmtActionType.SET_ROAD_VMT_FILTERS, filters),

  setSelectedZone: (selectedZone: SelectedZone | null) =>
    createAction(RoadVmtActionType.SET_SELECTED_ZONE, selectedZone),

  fetchRoadVmtZoneDetails: (config: RoadVmtZoneDetailsRequest) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS, config),

  fetchRoadVmtZoneDetailsSucceeded: (roadVmtZoneDetails: RoadVmtZoneDetails) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_SUCCEEDED, {
      roadVmtZoneDetails,
    }),

  fetchRoadVmtZoneDetailsFailed: (error: ResponseError) =>
    createAction(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_FAILED, error),

  fetchMileageZoneDetails: (config: RoadVmtZoneDetailsRequest) =>
    createAction(RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS, config),

  fetchMileageZoneDetailsSucceeded: (mileageZoneDetails: RoadVmtZoneDetails) =>
    createAction(RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_SUCCEEDED, {
      mileageZoneDetails,
    }),

  fetchMileageZoneDetailsFailed: (error: ResponseError) =>
    createAction(RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_FAILED, error),

  // Zone Ids Actions
  fetchZoneIds: (levels: string[], config: ZoneIdsArguments) =>
    createAction(RoadVmtActionType.FETCH_ZONE_IDS, { levels, config }),
  fetchZoneIdsSucceeded: (zoneIds: ZoneIds) =>
    createAction(RoadVmtActionType.FETCH_ZONE_IDS_SUCCEEDED, {
      zoneIds,
    }),
  fetchZoneIdsFailed: (error: ResponseError) => createAction(RoadVmtActionType.FETCH_ZONE_IDS_FAILED, error),
};

const initialState: RoadVmtState = {
  roadVmtMetadata: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadVmtZoneCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadMileageZoneCounts: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  roadVmtZoneDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  mileageZoneDetails: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  zoneIds: {
    state: DataState.EMPTY,
    data: null,
    error: null,
  },
  filters: null,
  selectedZone: null,
};

const roadVmtReducer: Reducer<RoadVmtState, RoadVmtAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RoadVmtActionType.FETCH_ROAD_VMT_METADATA: {
        draft.roadVmtMetadata = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_METADATA_SUCCEEDED: {
        draft.roadVmtMetadata = {
          state: DataState.AVAILABLE,
          data: action.payload.roadVmtMetadata,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_METADATA_FAILED: {
        draft.roadVmtMetadata = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadVmtActionType.CLEAR_ROAD_VMT_METADATA: {
        draft.roadVmtMetadata = initialState.roadVmtMetadata;
        draft.roadVmtZoneCounts = initialState.roadVmtZoneCounts;
        draft.roadMileageZoneCounts = initialState.roadMileageZoneCounts;
        draft.selectedZone = initialState.selectedZone;
        draft.roadVmtZoneDetails = initialState.roadVmtZoneDetails;
        draft.mileageZoneDetails = initialState.mileageZoneDetails;
        draft.zoneIds = initialState.zoneIds;

        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS: {
        draft.roadVmtZoneCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_SUCCEEDED: {
        draft.roadVmtZoneCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.roadVmtZoneCounts,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_FAILED: {
        draft.roadVmtZoneCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS: {
        draft.roadMileageZoneCounts = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_SUCCEEDED: {
        draft.roadMileageZoneCounts = {
          state: DataState.AVAILABLE,
          data: action.payload.roadMileageZoneCounts,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED: {
        draft.roadMileageZoneCounts = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadVmtActionType.SET_ROAD_VMT_FILTERS: {
        draft.filters = action.payload;
        draft.roadVmtZoneCounts = initialState.roadVmtZoneCounts;
        draft.roadMileageZoneCounts = initialState.roadMileageZoneCounts;
        draft.roadVmtZoneDetails = initialState.roadVmtZoneDetails;
        draft.mileageZoneDetails = initialState.mileageZoneDetails;
        return;
      }
      case RoadVmtActionType.SET_SELECTED_ZONE: {
        draft.selectedZone = action.payload;
        draft.roadVmtZoneDetails = initialState.roadVmtZoneDetails;
        draft.mileageZoneDetails = initialState.mileageZoneDetails;
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS: {
        draft.roadVmtZoneDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_SUCCEEDED: {
        draft.roadVmtZoneDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.roadVmtZoneDetails,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_FAILED: {
        draft.roadVmtZoneDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS: {
        draft.mileageZoneDetails = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_SUCCEEDED: {
        draft.mileageZoneDetails = {
          state: DataState.AVAILABLE,
          data: action.payload.mileageZoneDetails,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_FAILED: {
        draft.mileageZoneDetails = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ZONE_IDS: {
        draft.zoneIds = {
          state: DataState.LOADING,
          data: null,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ZONE_IDS_SUCCEEDED: {
        draft.zoneIds = {
          state: DataState.AVAILABLE,
          data: action.payload.zoneIds,
          error: null,
        };
        return;
      }
      case RoadVmtActionType.FETCH_ZONE_IDS_FAILED: {
        draft.zoneIds = {
          state: DataState.ERROR,
          error: action.payload,
          data: null,
        };
        return;
      }

      default:
        return state;
    }
  });

export default roadVmtReducer;

function* fetchRoadVmtMetadata(action: Action<string, RoadVmtMetadataRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchRoadVmtMetadata },
      tileServiceApi: { getTileServiceMetadata },
    } = api as Api;
    const roadVmtMetadata: any = yield call(fetchRoadVmtMetadata, action.payload);
    const tileServiceMetadata: any = yield call(getTileServiceMetadata, roadVmtMetadata.tileService.url);
    const extendedTileServiceMetadata = extendODMetadata(roadVmtMetadata.tileService, tileServiceMetadata);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_METADATA_SUCCEEDED,
      payload: {
        roadVmtMetadata: { ...roadVmtMetadata, tileService: extendedTileServiceMetadata },
      },
    });
  } catch (error) {
    reportAboutErrorState(error, RoadVmtActionType.FETCH_ROAD_VMT_METADATA_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_METADATA_FAILED,
      payload: error,
    });
  }
}

function* fetchRoadVmtZoneCounts(
  action: Action<string, { level: string; config: RoadVmtZoneCountsRequest }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchRoadVmtZoneCounts },
    } = api as Api;
    const roadVmtZoneCounts: any = yield call(fetchRoadVmtZoneCounts, action.payload.level, action.payload.config);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_SUCCEEDED,
      payload: { roadVmtZoneCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchRoadMileageZoneCounts(
  action: Action<string, { level: string; config: RoadVmtZoneCountsRequest }>,
): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchRoadVmtZoneCounts },
    } = api as Api;
    const roadMileageZoneCounts: any = yield call(fetchRoadVmtZoneCounts, action.payload.level, action.payload.config);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_SUCCEEDED,
      payload: { roadMileageZoneCounts },
    });
  } catch (e) {
    reportAboutErrorState(e, RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS_FAILED,
      payload: e,
    });
  }
}

function* fetchRoadVmtZoneDetails(action: Action<string, RoadVmtZoneDetailsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchRoadVmtZoneDetails },
    } = api as Api;
    const roadVmtZoneDetails = yield call(fetchRoadVmtZoneDetails, action.payload);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_SUCCEEDED,
      payload: { roadVmtZoneDetails },
    });
  } catch (e) {
    reportAboutErrorState(e, RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* fetchMileageZoneDetails(action: Action<string, RoadVmtZoneDetailsRequest>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { fetchRoadVmtZoneDetails },
    } = api as Api;
    const mileageZoneDetails = yield call(fetchRoadVmtZoneDetails, action.payload);

    yield put({
      type: RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_SUCCEEDED,
      payload: { mileageZoneDetails },
    });
  } catch (e) {
    reportAboutErrorState(e, RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS_FAILED,
      payload: e,
    });
  }
}

function* fetchZoneIds(action: Action<string, { levels: string[]; config: ZoneIdsArguments }>): Generator {
  try {
    const api = yield getContext("api");
    const {
      analyticsApi: { getODIds },
    } = api as Api;
    const zoneIds = yield call(getODIds, action.payload.levels, action.payload.config);
    yield put({
      type: RoadVmtActionType.FETCH_ZONE_IDS_SUCCEEDED,
      payload: { zoneIds },
    });
  } catch (e) {
    reportAboutErrorState(e, RoadVmtActionType.FETCH_ZONE_IDS_FAILED);

    yield put({
      type: RoadVmtActionType.FETCH_ZONE_IDS_FAILED,
      payload: e,
    });
  }
}

export function* roadVmtSaga() {
  yield all([
    takeLatest(RoadVmtActionType.FETCH_ROAD_VMT_METADATA, fetchRoadVmtMetadata),
    takeLatest(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_COUNTS, fetchRoadVmtZoneCounts),
    takeLatest(RoadVmtActionType.FETCH_ROAD_VMT_ZONE_DETAILS, fetchRoadVmtZoneDetails),
    takeLatest(RoadVmtActionType.FETCH_ROAD_MILEAGE_ZONE_COUNTS, fetchRoadMileageZoneCounts),
    takeLatest(RoadVmtActionType.FETCH_MILEAGE_ZONE_DETAILS, fetchMileageZoneDetails),
    takeLatest(RoadVmtActionType.FETCH_ZONE_IDS, fetchZoneIds),
  ]);
}
