import { Chip as MuiChip, ChipProps as MuiChipProps, alpha, styled } from "@mui/material";
import * as React from "react";

export interface BadgeProps extends MuiChipProps {}

const StyledMuiChip = styled(MuiChip)(({ theme }) => ({
  "&.MuiChip-outlined": {
    "&.MuiChip-colorSecondary": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.05),
    },
    "&.MuiChip-colorSuccess": {
      backgroundColor: alpha(theme.palette.success.light, 0.05),
    },
    "&.MuiChip-colorWarning": {
      backgroundColor: alpha(theme.palette.warning.light, 0.05),
    },
    "&.MuiChip-colorInfo": {
      backgroundColor: alpha(theme.palette.info.light, 0.05),
    },
    "&.MuiChip-colorError": {
      backgroundColor: alpha(theme.palette.error.light, 0.05),
    },
  },
}));

export const Badge: React.FC<BadgeProps> = ({ color = "primary", variant = "outlined", size = "small", ...props }) => {
  return <StyledMuiChip color={color} variant={variant} size={size} {...props} />;
};
