import { AnySourceData } from "mapbox-gl";

import { ODTileService } from "types";

export const OD_ZONE_SOURCE_ID = "OD_ZONE_SOURCE_ID";
export const OUT_ZONES_SOURCE_ID = "OUT_ZONES_SOURCE_ID";

export const getODSources = (zonesTileService: ODTileService) => {
  const ODSources: {
    id: string;
    source: AnySourceData;
  }[] = [
    {
      id: OUT_ZONES_SOURCE_ID,
      source: {
        type: "vector",
        tiles: [zonesTileService.layers[0].url + "/{z}/{x}/{y}.pbf"],
        promoteId: zonesTileService.layers[0].idField,
        minzoom: zonesTileService.minZoom,
        maxzoom: zonesTileService.layers[0].maxzoom,
      } as AnySourceData,
    },
  ];

  zonesTileService.layers.forEach((layer) => {
    ODSources.push({
      id: `${OD_ZONE_SOURCE_ID} ${layer.name}`,
      source: {
        type: "vector",
        tiles: [layer.url + "/{z}/{x}/{y}.pbf"],
        promoteId: layer.idField,
        minzoom: zonesTileService.minZoom,
        maxzoom: layer.maxzoom,
      } as AnySourceData,
    });
  });

  return ODSources;
};
