import styled from "@emotion/styled";
import { CircularProgress } from "components_new";
import { FC, memo, useMemo } from "react";

import { RoadVmtAnalytics } from "features/analytics/RoadVmtAnalytics";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

interface ZoneAnalyticsPanelProps {
  closeZoneAnalyticsPanelRef: React.RefObject<() => void>;
}

const RightSidebarLoaderWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ZoneAnalyticsPanel: FC<ZoneAnalyticsPanelProps> = memo(({ closeZoneAnalyticsPanelRef }) => {
  const selectedZone = useAppSelector((state) => state.roadVmt.selectedZone);
  const roadVmtZoneCountsState = useAppSelector((state) => state.roadVmt.roadVmtZoneCounts.state);
  const roadVmtZoneDetails = useAppSelector((state) => state.roadVmt.roadVmtZoneDetails);
  const mileageZoneDetails = useAppSelector((state) => state.roadVmt.mileageZoneDetails);
  const roadVmtDimensions = useAppSelector((state) => state.roadVmt.roadVmtMetadata.data?.measures[0].dimensions);

  const isLoading = useMemo(() => {
    return (
      roadVmtZoneCountsState === DataState.LOADING ||
      roadVmtZoneDetails.state === DataState.LOADING ||
      mileageZoneDetails.state === DataState.LOADING
    );
  }, [roadVmtZoneCountsState, roadVmtZoneDetails.state, mileageZoneDetails.state]);

  const isShowRightSidebar = useMemo(
    () =>
      Boolean(
        selectedZone &&
          (roadVmtZoneDetails.state === DataState.AVAILABLE || roadVmtZoneDetails.state === DataState.LOADING) &&
          (mileageZoneDetails.state === DataState.AVAILABLE || mileageZoneDetails.state === DataState.LOADING),
      ),
    [selectedZone, roadVmtZoneDetails.state, mileageZoneDetails.state],
  );

  const isZoneDetailsLoading = Boolean(selectedZone?.zoneId && isLoading);

  const handleClosePanel = () => {
    if (typeof closeZoneAnalyticsPanelRef.current === "function") {
      closeZoneAnalyticsPanelRef.current();
    }
  };

  return (
    <RightSidebarPanel
      title={isZoneDetailsLoading || !selectedZone ? "Loading..." : `${selectedZone.name} (${selectedZone.zoneId})`}
      cardType="od"
      onClose={handleClosePanel}
      isOpen={isShowRightSidebar || isZoneDetailsLoading}
    >
      {!isZoneDetailsLoading && selectedZone && roadVmtZoneDetails.data && mileageZoneDetails.data ? (
        <RoadVmtAnalytics
          zoneDetails={[
            {
              name: "Annual average daily VMT",
              ...roadVmtZoneDetails.data,
            },
            {
              name: "Road mileage",
              ...mileageZoneDetails.data,
            },
          ]}
          roadVmtDimensions={roadVmtDimensions!}
        />
      ) : null}
      {isZoneDetailsLoading && (
        <RightSidebarLoaderWrapper>
          <CircularProgress />
        </RightSidebarLoaderWrapper>
      )}
    </RightSidebarPanel>
  );
});
