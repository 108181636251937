import { FolderOpenOutlined, ViewQuiltOutlined, WarningAmberOutlined } from "@mui/icons-material";
import { Grid, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { TextField } from "components_new";
import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { datasetFoldersActions } from "store/sections/userFolders";

interface Props {
  activeItem: string | undefined;
  zoningOptions: string[];
  onItemSelect: (zoning: string, customZoningId?: string) => void;
  customZoningId?: string;
}

interface ZoningSelectorItem {
  id: string;
  name: string;
  folderName?: string;
  zoningId?: string;
  enabled?: boolean;
  disabledReason?: string;
}

export const ZoningSelector = ({ activeItem, zoningOptions, customZoningId, onItemSelect }: Props) => {
  const dispatch = useAppDispatch();

  const [options, setOptions] = React.useState<ZoningSelectorItem[]>([]);

  const defaultZoningLevels = useAppSelector((state) => state.analytics.ODMetadata.data?.zoningLevels);

  const customZoningSelectorFoldersState = useAppSelector(
    (state) => state.datasetFolders.customZoningSelectorFolders.state,
  );
  const customZoningSelectorFolders = useAppSelector((state) => state.datasetFolders.customZoningSelectorFolders.data);

  useEffect(() => {
    dispatch(datasetFoldersActions.getCustomZoningSelectorFolders());
  }, [dispatch]);

  useEffect(() => {
    if (customZoningSelectorFoldersState === DataState.AVAILABLE && zoningOptions.length > 0 && options.length === 0) {
      const optionsList: ZoningSelectorItem[] = [];

      zoningOptions.forEach((zoningOption) => {
        const zoningName = defaultZoningLevels?.find(
          (zoningLevel) => zoningLevel.id === zoningOption,
        )?.shortNameSingular;
        optionsList.push({
          id: zoningOption,
          name: zoningName || zoningOption,
          enabled: true,
        });
      });

      customZoningSelectorFolders?.folders.forEach((folder) => {
        optionsList.push({
          id: folder.folderId,
          name: folder.folderName,
          folderName: folder.folderName,
        });

        folder.customZonings.forEach((customZoning) => {
          optionsList.push({
            id: customZoning.customZoningId,
            name: customZoning.name,
            zoningId: customZoning.zoningId,
            enabled: customZoning.enabled,
            disabledReason: customZoning.disabledReason,
          });
        });
      });

      setOptions(optionsList);
    }
  }, [
    dispatch,
    options,
    customZoningSelectorFoldersState,
    customZoningSelectorFolders,
    zoningOptions,
    defaultZoningLevels,
  ]);

  return (
    <TextField
      fullWidth
      select
      disabled={
        customZoningSelectorFoldersState === DataState.LOADING ||
        customZoningSelectorFoldersState !== DataState.AVAILABLE
      }
      value={options.length ? (activeItem === "Custom" ? customZoningId : activeItem) : ""}
      SelectProps={{ MenuProps: { sx: { maxHeight: "320px" } } }}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
          disabled={Boolean(option.folderName || !option.enabled)}
          onClick={() => (option.zoningId ? onItemSelect("Custom", option.id) : onItemSelect(option.id))}
          sx={{ marginLeft: option.zoningId ? "16px" : 0, "&.Mui-disabled": { opacity: 0.8 } }}
        >
          <Grid container alignItems={"center"}>
            <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>
              {option.folderName ? (
                <FolderOpenOutlined fontSize="small" />
              ) : option.enabled ? (
                <ViewQuiltOutlined fontSize="small" color="secondary" />
              ) : (
                <WarningAmberOutlined fontSize="small" color="warning" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={option.name}
              primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
              secondary={
                !option.enabled && !option.folderName
                  ? "Custom zoning is not available for Essentials-tier entitlement"
                  : ""
              }
              secondaryTypographyProps={{ color: "warning.main", fontSize: 10 }}
              sx={{ margin: 0 }}
            />
          </Grid>
        </MenuItem>
      ))}
    </TextField>
  );
};
