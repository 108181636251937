import { ToggleButton as MuiToggleButton, ToggleButtonProps as MuiToggleButtonProps, styled } from "@mui/material";
import * as React from "react";

export interface ToggleButtonProps extends MuiToggleButtonProps {
  // Add your custom props here
}

const StyledToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  textTransform: "none",
  padding: theme.spacing(0.5, 1),
}));

export const ToggleButton: React.FC<ToggleButtonProps> = ({ size = "small", color = "secondary", ...props }) => {
  return <StyledToggleButton size={size} color={color} {...props} />;
};
