import { styled } from "@mui/material";

export const DocsIcon = styled("div")`
  grid-area: docs-icon;
  margin-bottom: 0;
  padding: 12px;
  background-color: rgba(19, 158, 236, 0.2);
  border-radius: 8px;

  & > svg:not([fill]) {
    color: #0067b0;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
`;
