import { Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/system/Box";
import { GeoJsonProperties } from "geojson";
import { FC, ReactNode } from "react";

import { AttributeStatistics } from "types";

export interface AttributesTableProps {
  attributes: GeoJsonProperties | undefined;
  maxHeight?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  tableHeadLabels?: ReactNode[];
  columnsRatio?: [number, number];
  headerClick?: () => void;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-of-type": {
    [`& .${tableCellClasses.body}`]: {
      border: 0,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[50],
    fontSize: 11,
    padding: "4px 14px",
    lineHeight: "1.5em",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "4px 14px",
    verticalAlign: "top",
  },
}));

const getAttributeStatLabel = (name: keyof AttributeStatistics | string) => {
  switch (name) {
    case "notNullCount":
      return "Non-NULL value count";
    case "nullCount":
      return "NULL value count";
    case "distinctValueCount":
      return "Number of distinct values";
    case "hasUniqueValues":
      return "Values are unique";
    case "minValue":
      return "Minimum value";
    case "maxValue":
      return "Maximum value";

    //Numeric
    case "avgValue":
      return "Average value";
    case "topNonUniqueValues":
      return "Top 10 non-unique values";

    //Text
    case "minLength":
      return "Minimum text length";
    case "maxLength":
      return "Maximum text length";
    default:
      return name.replaceAll("_", " ");
  }
};

const formatAttributeStatValue = (value: string | number | boolean) => {
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  return value;
};

const getStringWidth = (string: string, font?: string) => {
  // Create a dummy element
  const element = document.createElement("span");
  // Set its font, if provided
  if (font) {
    element.style.font = font;
  }
  // Set its text content to the string
  element.textContent = string;
  // Set its display style to 'inline' to ensure it wraps to the content width
  element.style.display = "inline";
  // Append it to the document body (off-screen)
  document.body.appendChild(element);
  // Get its width
  const width = element.offsetWidth;
  // Remove the dummy element
  document.body.removeChild(element);
  // Return the width
  return width;
};

const SUMMED_CELL_X_PADDING = 56;

export const AttributesTable: FC<AttributesTableProps> = ({
  attributes,
  maxHeight,
  minWidth,
  maxWidth,
  tableHeadLabels,
  columnsRatio = [40, 60],
  headerClick,
}) => {
  const valueContentMaxWidth =
    Number(maxWidth?.toString().replace("px", "")) * (columnsRatio[1] / 100) - SUMMED_CELL_X_PADDING;

  return (
    <TableContainer sx={{ maxHeight, minWidth, maxWidth }}>
      <Table stickyHeader={maxHeight ? true : false} size="small">
        {tableHeadLabels && (
          <TableHead onClick={headerClick}>
            <TableRow>
              {tableHeadLabels.map((label, i) => (
                <StyledTableCell key={i}>{label}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {Object.entries(attributes || {}).map(([key, value]) => (
            <StyledTableRow key={key}>
              <StyledTableCell sx={{ width: `${columnsRatio[0]}%` }}>
                <Typography fontSize={12}>{getAttributeStatLabel(key)}</Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ width: `${columnsRatio[1]}%` }}>
                {key === "topNonUniqueValues" ? (
                  <Box mr={"-14px"}>
                    <AttributesTable
                      minWidth={260}
                      maxWidth={260}
                      tableHeadLabels={["Value", "Count"]}
                      attributes={value.reduce((obj: any, o: any) => {
                        obj[o.value] = o.count;
                        return obj;
                      }, {})}
                    />
                  </Box>
                ) : (
                  <Tooltip
                    disableHoverListener={
                      getStringWidth(formatAttributeStatValue(value).toString()) <= valueContentMaxWidth
                    }
                    title={<Typography fontSize={12}>{formatAttributeStatValue(value)}</Typography>}
                  >
                    <Typography maxWidth={valueContentMaxWidth} fontSize={12} noWrap>
                      {formatAttributeStatValue(value)}
                    </Typography>
                  </Tooltip>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
