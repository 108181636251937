import { Card, List, ListItem, Skeleton } from "@mui/material";
import { Screenline, ScreenlineGeometry } from "api/analytics/index.d";
import { PaddingOptions } from "mapbox-gl";
import { FC } from "react";

import { useAppSelector } from "hooks";

import { ScreenlineItem } from "./ScreenlineItem";

export interface ScreenlineListProps {
  loading: boolean;
  editMode: boolean;
  drawMode: boolean;
  screenlines: Screenline[];
  selectedScreenline: Screenline | null;
  setSelectedScreenlineId: ((screenlineId: string | null) => void) | null;
  handleZoomOnBounds: (geometry: ScreenlineGeometry, padding?: number | PaddingOptions) => void;
  handleDeleteScreenline: (screenline: Screenline) => void;
  handleToggleScreenlineVisibility: (screenline: Screenline) => void;
}

export const ScreenlineList: FC<ScreenlineListProps> = ({
  loading,
  editMode,
  drawMode,
  screenlines,
  selectedScreenline,
  setSelectedScreenlineId,
  handleZoomOnBounds,
  handleDeleteScreenline,
  handleToggleScreenlineVisibility,
}) => {
  const loadingCreateScreenline = useAppSelector((state) => state.screenlines.loading);

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <List
        sx={{
          overflow: "auto",
          height: "100%",
          padding: 0,
        }}
      >
        {loadingCreateScreenline
          ? Array.from({ length: screenlines.length + 1 }, (_, index) => 0 + index).map((i) => (
              <ListItem
                key={i}
                disablePadding
                disableGutters
                secondaryAction={<Skeleton variant="rectangular" height={12} width={12} sx={{ marginRight: 1 }} />}
                sx={{ padding: "6px 10px" }}
              >
                <Skeleton variant="text" width={40} height={12} sx={{ marginX: 1 }} />{" "}
                <Skeleton variant="text" width={60} height={12} />
              </ListItem>
            ))
          : screenlines.map((screenline) => (
              <ScreenlineItem
                key={screenline.id}
                screenlineId={screenline.id}
                name={screenline.name}
                selected={selectedScreenline?.id === screenline.id}
                disabled={editMode || drawMode || loading}
                visible={screenline.visible}
                onClick={() => setSelectedScreenlineId?.(screenline.id)}
                handleZoomOnBounds={() => handleZoomOnBounds(screenline.geometry)}
                handleDeleteScreenline={() => handleDeleteScreenline(screenline)}
                handleToggleScreenlineVisibility={() => handleToggleScreenlineVisibility(screenline)}
              />
            ))}
      </List>
    </Card>
  );
};
