import { Grid, ListItemIcon, ListItemText, MenuItem, styled } from "@mui/material";
import { Divider, TextField } from "components_new";
import { Map } from "mapbox-gl";
import React, { FC } from "react";

import { MAPBOX_STYLE_OPTIONS, MapBoxStyles } from "features/map/baseMapStyles";

import { useAppDispatch, useAppSelector } from "hooks";

import { mapActions } from "store/sections/map";

interface BaseMapStylesProps {
  map: Map | null;
  disabled?: boolean;
}

const BaseMapStylesWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BaseMapStyles: FC<BaseMapStylesProps> = ({ map, disabled }) => {
  const dispatch = useAppDispatch();
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  const handleChangeBaseMapStyle = (style: MapBoxStyles) => {
    const bounds: any = map?.getBounds().toArray();

    if (bounds) {
      dispatch(mapActions.setMapBounds(bounds));
    }
    dispatch(mapActions.setBaseMapStyle(style));
  };

  return (
    <BaseMapStylesWrapper>
      <Divider sx={{ marginBottom: 1 }}>Base Map Styles</Divider>
      <TextField select disabled={disabled} value={baseMapStyle}>
        {MAPBOX_STYLE_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleChangeBaseMapStyle(option.value as MapBoxStyles)}
          >
            <Grid container alignItems={"center"}>
              <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>{option.icon}</ListItemIcon>
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
                sx={{ margin: 0 }}
              />
            </Grid>
          </MenuItem>
        ))}
      </TextField>
    </BaseMapStylesWrapper>
  );
};
