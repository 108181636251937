import { produce } from "immer";
import { LngLatBounds } from "mapbox-gl";
import { Reducer } from "redux";
import { all } from "redux-saga/effects";

import { MapBoxStyles } from "features/map/baseMapStyles";

import { ActionsUnion, createAction } from "../actionHelpers";
import { MapActionType } from "./actionTypes";

export interface MapState {
  showZoneCounts: boolean;
  showRoadVolumes: boolean;
  baseMapStyle: MapBoxStyles;
  colorScheme: string;
  colorScale: any;
  mapBounds: LngLatBounds | null;
}

const initialState: MapState = {
  showZoneCounts: true,
  showRoadVolumes: true,
  baseMapStyle: MapBoxStyles.Default,
  colorScheme: "Default",
  colorScale: null,
  mapBounds: null,
};

export type MapAction = ActionsUnion<typeof mapActions>;

export const mapActions = {
  setBaseMapStyle: (baseMapStyle: MapBoxStyles) => createAction(MapActionType.SET_BASE_MAP_STYLE, baseMapStyle),
  setColorScheme: (colorScheme: string) => createAction(MapActionType.SET_COLOR_SCHEME, colorScheme),
  setColorScale: (colorScale: string) => createAction(MapActionType.SET_COLOR_SCALE, colorScale),
  setMapBounds: (bounds: LngLatBounds) => createAction(MapActionType.SET_MAP_BOUNDS, bounds),
  clearMapBounds: () => createAction(MapActionType.CLEAR_MAP_BOUNDS),
  setShowZoneCounts: (showZoneCounts: boolean) => createAction(MapActionType.SET_SHOW_ZONE_COUNTS, showZoneCounts),
  setShowRoadVolumes: (showRoadVolumes: boolean) => createAction(MapActionType.SET_SHOW_ROAD_VOLUMES, showRoadVolumes),
};

const reducer: Reducer<MapState, MapAction> = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MapActionType.SET_BASE_MAP_STYLE: {
        draft.baseMapStyle = action.payload;
        return;
      }
      case MapActionType.SET_COLOR_SCHEME: {
        draft.colorScheme = action.payload;
        return;
      }
      case MapActionType.SET_COLOR_SCALE: {
        draft.colorScale = action.payload;
        return;
      }
      case MapActionType.SET_MAP_BOUNDS: {
        draft.mapBounds = action.payload;
        return;
      }
      case MapActionType.CLEAR_MAP_BOUNDS: {
        draft.mapBounds = null;
        return;
      }
      case MapActionType.SET_SHOW_ZONE_COUNTS: {
        draft.showZoneCounts = action.payload;
        return;
      }
      case MapActionType.SET_SHOW_ROAD_VOLUMES: {
        draft.showRoadVolumes = action.payload;
        return;
      }
      default:
        return state;
    }
  });

export default reducer;

export function* mapSaga() {
  yield all([]);
}
