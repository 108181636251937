import { AccessTime, CalendarMonth, FilterAlt, WarningAmber } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";
import { Button, Divider } from "components_new";
import { isEqual } from "lodash";
import React, { ChangeEvent, FC, useCallback, useDeferredValue, useEffect, useMemo, useState } from "react";

import { FlexContainer } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { filtersActions } from "store/sections/filters";

import {
  FilterItems,
  FiltersType,
  MapVisualizationMode,
  MapVisualizationType,
  Measure,
  MeasureType,
  QueryType,
  SelectLinkMode,
} from "types";

import { ODFiltersPanel } from "./ODFiltersPanel";
import { RoadsFiltersPanel } from "./RoadsFiltersPanel";
import { getCurrentMeasure } from "./utils";

export interface FiltersProps {
  mode: MapVisualizationMode;
  selectLinkMode: SelectLinkMode | null;
  loading: boolean;
  isAnalysis?: boolean;
  disabled?: boolean;
}

const FiltersControls = styled(FlexContainer)`
  margin-top: 0.5rem;
  justify-content: space-between;
`;

const WarningFilterBox = styled(FlexContainer)`
  margin: 0.5rem 0;

  & > p.MuiTypography-root {
    font-size: 0.75rem;
    color: var(--color-text);
  }
`;

const getFiltersByMeasure = (measure: Measure): FiltersType => {
  const newFilters: FiltersType = {};
  if (measure) {
    for (let i = 0, { length } = measure.dimensions; i < length; i++) {
      const dimension = measure.dimensions[i];
      if (dimension.enabled) {
        newFilters[dimension.columnName] = {
          isChecked: true,
          label: dimension.label,
          icon: getIconByDimension(dimension.columnName),
          items: Object.entries(dimension.categories || {}).reduce((newItems: FilterItems, [itemKey, itemValue]) => {
            const item = itemValue as any;
            newItems[itemKey] = {
              ...item,
              label: item.label || item.value,
              isChecked: true,
            };
            return newItems;
          }, {}),
        };
      }
    }
  }

  return newFilters;
};

export const getIconByDimension = (dimension: string) => {
  switch (dimension) {
    case "day_type":
      return <CalendarMonth fontSize="small" color="secondary" />;
    case "hour":
      return <AccessTime fontSize="small" color="secondary" />;
    default:
      return <FilterAlt fontSize="small" color="secondary" />;
  }
};

export const areAllItemsUnChecked = (items: FilterItems) => Object.values(items).every((item) => !item.isChecked);

export const Filters: FC<FiltersProps> = ({ mode, selectLinkMode, loading, isAnalysis, disabled }) => {
  const dispatch = useAppDispatch();

  const currentQueryType = useAppSelector((state) => state.filters.queryType);
  const measure = useAppSelector((state) => state.filters.measure);

  const [filters, setFilters] = useState<FiltersType | null>(null);
  const deferredFilters = useDeferredValue(filters);

  const [queryType, setQueryType] = useState<QueryType>(currentQueryType);

  const [filterUpdatingWarning, setFilterUpdatingWarning] = useState<string | null>(null);

  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  //OD
  const ODCountsState = useAppSelector((state) => state.analytics.ODCounts.state);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const currentODMeasure = useMemo(
    () => getCurrentMeasure(ODMetadata.data?.measures, MeasureType.AADT), //Temporary fix. Need to implement separate measure for each mode
    [ODMetadata.data?.measures],
  );
  const isOD = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea && !selectedFocusArea?.datasetId,
    [mode, selectedFocusArea],
  );

  //Roads
  const roadVolumesState = useAppSelector((state) => state.analytics.roadsVolumes.state);
  const roadMeasuresData = useAppSelector((state) => state.analytics.roadsMetadata.data?.measures);
  const roadMeasures = useMemo(
    () =>
      roadMeasuresData
        ? roadMeasuresData.filter((measure) => !(timePeriod === "2019" && measure.columnName === MeasureType.TRUCKS))
        : [],
    [roadMeasuresData, timePeriod],
  );

  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const currentRoadMeasure = useMemo(() => getCurrentMeasure(roadMeasures, measure), [roadMeasures, measure]);

  const isRoads = useMemo(() => mode === MapVisualizationType.ROADS, [mode]);

  //Dataset
  const datasetCountsState = useAppSelector((state) => state.analytics.datasetCounts.state);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const currentDatasetFilters = useAppSelector((state) => state.filters.datasetFilters);
  const currentDatasetMeasure = useMemo(
    () => getCurrentMeasure(datasetMetadata.data?.measures, MeasureType.AADT), //Temporary fix. Need to implement separate measure for each mode
    [datasetMetadata.data?.measures],
  );
  const isDataset = useMemo(
    () => mode === MapVisualizationType.OD && selectedFocusArea?.datasetId,
    [mode, selectedFocusArea?.datasetId],
  );

  const isRevertFiltersOn = useMemo(
    () =>
      filters &&
      ((isOD && (!isEqual(filters, currentODFilters) || queryType !== currentQueryType)) ||
        (isDataset && (!isEqual(filters, currentDatasetFilters) || queryType !== currentQueryType)) ||
        (isRoads && !isEqual(filters, currentRoadFilters))),
    [
      currentDatasetFilters,
      currentODFilters,
      currentRoadFilters,
      filters,
      isOD,
      isRoads,
      isDataset,
      queryType,
      currentQueryType,
    ],
  );

  const areInvalidFilters = useMemo(() => {
    if (filters) {
      return Object.values(filters).some((filter) => areAllItemsUnChecked(filter.items));
    }

    return false;
  }, [filters]);

  const updateFilters = useCallback(
    (filters: FiltersType, unavailableFilterLabels: string[]) => {
      setFilters(filters);

      if (unavailableFilterLabels.length > 0) {
        setFilterUpdatingWarning(
          `${unavailableFilterLabels.join(", ")} not available for this time period, filter not applied`,
        );
      }
    },
    [setFilters, setFilterUpdatingWarning],
  );

  // Shows if filters have unavailable dimensions and require updates
  const checkIfFiltersHasUnavailableDimensions = (
    prevFilters: FiltersType,
    filters: FiltersType,
  ): { shouldUpdate: boolean; filters?: FiltersType; unavailableFilterLabels?: string[] } => {
    if (!isEqual(Object.keys(prevFilters), Object.keys(filters))) {
      const unavailableFilterLabels: string[] = [];

      for (const [key] of Object.entries(prevFilters)) {
        if (filters[key]) {
          filters[key] = prevFilters[key];
        } else if (!prevFilters[key].isChecked && !filters[key]) {
          unavailableFilterLabels.push(prevFilters[key].label);
        }
      }

      return { shouldUpdate: true, filters, unavailableFilterLabels };
    }

    return { shouldUpdate: false };
  };

  const updateFiltersByMeasure = useCallback(
    (measure: Measure, currentFilters: FiltersType, isActiveMode: boolean, callback: Function) => {
      const newFilters: FiltersType = getFiltersByMeasure(measure);

      const { shouldUpdate, filters, unavailableFilterLabels } = checkIfFiltersHasUnavailableDimensions(
        currentFilters,
        newFilters,
      );

      if (shouldUpdate && filters && unavailableFilterLabels) {
        callback(filters);

        if (isActiveMode) {
          updateFilters(filters, unavailableFilterLabels);
        }
      }
    },
    [updateFilters],
  );

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (filters) {
      const namesArr = event.target.name.split("-");
      const groupName = namesArr[0];
      const itemName = namesArr[1];

      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: Object.entries(filters[groupName].items).every(([itemKey, item]) => {
            return itemKey === itemName ? !filters[groupName].items[itemName].isChecked : item.isChecked;
          }),
          items: {
            ...filters[groupName].items,
            [itemName]: {
              ...filters[groupName].items[itemName],
              isChecked: !filters[groupName].items[itemName].isChecked,
            },
          },
        },
      });
    }
  };

  const handleChangeAllFilters = (isChecked: boolean) => (groupName: string) => {
    if (filters) {
      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: isChecked,
          items: Object.entries(filters[groupName].items).reduce((newItems, [itemKey, itemValue]) => {
            newItems[itemKey] = {
              ...itemValue,
              isChecked: isChecked,
            };
            return newItems;
          }, {} as FilterItems),
        },
      });
    }
  };

  const handleSubmit = () => {
    if (filters && selectedFocusArea) {
      if (isOD && ODMetadata.state === DataState.AVAILABLE && timePeriod) {
        dispatch(filtersActions.setQueryType(queryType));
        dispatch(filtersActions.setODFilters({ filter: filters, queryType }));
      }

      if (isDataset && datasetMetadata.state === DataState.AVAILABLE && selectedFocusAreaId) {
        dispatch(filtersActions.setQueryType(queryType));
        dispatch(filtersActions.setDatasetFilters({ filter: filters, datasetId: selectedFocusAreaId, queryType }));
      }

      if (isRoads) {
        dispatch(filtersActions.setRoadFilters({ filter: filters }));
      }

      if (filterUpdatingWarning) {
        setFilterUpdatingWarning(null);
      }
    }
  };

  const handleChangeQueryType = (type: QueryType) => {
    setQueryType(type);
  };

  const handleChangeMeasure = useCallback(
    (newMeasure: MeasureType) => {
      const newMeasureData = getCurrentMeasure(roadMeasures, newMeasure);

      if (newMeasureData && newMeasure !== measure) {
        const newFilters = getFiltersByMeasure(newMeasureData);
        dispatch(filtersActions.setMeasure(newMeasure));
        dispatch(filtersActions.setRoadFilters({ filter: newFilters }));
        setFilters(newFilters);
      }
    },
    [measure, roadMeasures, dispatch],
  );

  const handleRevertFilters = () => {
    setQueryType(currentQueryType);
    if (isOD) setFilters(currentODFilters);
    if (isDataset) setFilters(currentDatasetFilters);
    if (isRoads) setFilters(currentRoadFilters);
  };

  //Update filters if there is a difference between current filters and dimensions from the measure
  useEffect(() => {
    if (currentODFilters && currentODMeasure && !selectedFocusArea?.datasetId) {
      updateFiltersByMeasure(
        currentODMeasure,
        currentODFilters,
        mode === MapVisualizationType.OD,
        (filters: FiltersType) => {
          dispatch(filtersActions.setODFilters({ filter: filters, queryType }));
        },
      );
    } else if (currentDatasetFilters && currentDatasetMeasure && selectedFocusArea?.datasetId) {
      updateFiltersByMeasure(
        currentDatasetMeasure,
        currentDatasetFilters,
        mode === MapVisualizationType.OD,
        (filters: FiltersType) => {
          dispatch(
            filtersActions.setDatasetFilters({
              filter: filters,
              datasetId: selectedFocusArea.datasetId!,
              queryType,
            }),
          );
        },
      );
    }

    if (currentRoadFilters && currentRoadMeasure) {
      updateFiltersByMeasure(
        currentRoadMeasure,
        currentRoadFilters,
        mode === MapVisualizationType.ROADS,
        (filters: FiltersType) => {
          dispatch(filtersActions.setRoadFilters({ filter: filters }));
        },
      );
    }
  }, [
    mode,
    currentODFilters,
    currentODMeasure,
    currentDatasetFilters,
    currentDatasetMeasure,
    currentRoadFilters,
    currentRoadMeasure,
    queryType,
    filterUpdatingWarning,
    measure,
    selectedFocusArea?.datasetId,
    updateFilters,
    updateFiltersByMeasure,
    dispatch,
  ]);

  //Get OD filters from measure and set redux state
  useEffect(() => {
    if (!currentODFilters && currentODMeasure && !selectedFocusArea?.datasetId && mode === MapVisualizationType.OD) {
      const newODFilters: FiltersType = getFiltersByMeasure(currentODMeasure);
      dispatch(filtersActions.setODFilters({ filter: newODFilters, queryType }));
    }
  }, [selectedFocusArea?.datasetId, currentODFilters, currentODMeasure, queryType, mode, dispatch]);

  //Get dataset filters from measure and set redux state
  useEffect(() => {
    if (
      !currentDatasetFilters &&
      currentDatasetMeasure &&
      selectedFocusArea?.datasetId &&
      mode === MapVisualizationType.OD
    ) {
      const newDatasetFilters: FiltersType = getFiltersByMeasure(currentDatasetMeasure);

      dispatch(
        filtersActions.setDatasetFilters({
          filter: newDatasetFilters,
          datasetId: selectedFocusArea?.datasetId,
          queryType,
        }),
      );
    }
  }, [selectedFocusArea?.datasetId, currentDatasetFilters, currentDatasetMeasure, queryType, mode, dispatch]);

  //Get roads filters from measure and set redux state
  useEffect(() => {
    if (!currentRoadFilters && currentRoadMeasure && mode === MapVisualizationType.ROADS) {
      const newRoadFilters: FiltersType = getFiltersByMeasure(currentRoadMeasure);

      dispatch(filtersActions.setRoadFilters({ filter: newRoadFilters }));
    }
  }, [currentRoadFilters, currentRoadMeasure, mode, dispatch]);

  //Clear component filters on mode change
  useEffect(() => {
    if (selectedFocusAreaId) {
      setFilters(null);
    }
  }, [mode, selectedFocusAreaId]);

  useEffect(() => {
    if (!filters && filterUpdatingWarning) {
      setFilterUpdatingWarning(null);
    }
  }, [filters, filterUpdatingWarning]);

  //Set filters for OD on first render
  useEffect(() => {
    if (
      mode === MapVisualizationType.OD &&
      ODMetadata.state === DataState.AVAILABLE &&
      currentODFilters &&
      !filters &&
      selectedFocusArea &&
      !selectedFocusArea.datasetId
    ) {
      setFilters(currentODFilters);
    }
  }, [mode, filters, currentODFilters, selectedFocusArea, ODMetadata, ODCountsState, measure]);

  //Set filters for dataset on first render
  useEffect(() => {
    if (
      datasetMetadata.state === DataState.AVAILABLE &&
      mode === MapVisualizationType.OD &&
      currentDatasetFilters &&
      !filters &&
      selectedFocusArea?.datasetId
    ) {
      setFilters(currentDatasetFilters);
    }
  }, [mode, filters, currentDatasetFilters, selectedFocusArea, datasetMetadata, datasetCountsState, measure]);

  //Set filters for roads on first render
  useEffect(() => {
    if (mode === MapVisualizationType.ROADS && currentRoadFilters && !filters && selectedFocusArea) {
      setFilters(currentRoadFilters);
    }
  }, [mode, filters, currentRoadFilters, selectedFocusArea, roadVolumesState, measure]);

  //Reset filters to last applied state when switching to select link result mode
  useEffect(() => {
    if (isAnalysis && selectLinkMode === SelectLinkMode.RESULTS) {
      setFilters(null);
    }
  }, [isAnalysis, selectLinkMode]);

  return (
    <>
      <div>
        <Divider sx={{ marginBottom: 1 }}>Filters</Divider>
        {(isOD || isDataset) && (
          <ODFiltersPanel
            filters={deferredFilters}
            queryType={queryType}
            loading={loading}
            handleChangeFilter={handleChangeFilter}
            handleChangeAllFilters={handleChangeAllFilters}
            handleChangeQueryType={handleChangeQueryType}
          />
        )}
        {isRoads && (
          <RoadsFiltersPanel
            isAnalysis={isAnalysis}
            filters={deferredFilters}
            roadMeasures={roadMeasures}
            currentRoadMeasure={currentRoadMeasure}
            measure={measure}
            loading={loading}
            disabled={disabled}
            setMeasure={handleChangeMeasure}
            handleChangeFilter={handleChangeFilter}
            handleChangeAllFilters={handleChangeAllFilters}
          />
        )}
      </div>

      {filterUpdatingWarning && (
        <WarningFilterBox>
          <WarningAmber color="warning" />
          <Typography marginLeft={1}>{filterUpdatingWarning}</Typography>
        </WarningFilterBox>
      )}

      <FiltersControls>
        {isRevertFiltersOn ? (
          <Button variant="outlined" color="secondary" onClick={handleRevertFilters}>
            Revert
          </Button>
        ) : (
          <div />
        )}

        <Button
          type="submit"
          color="secondary"
          disabled={areInvalidFilters || (currentQueryType === queryType && !isRevertFiltersOn)}
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </FiltersControls>
    </>
  );
};
