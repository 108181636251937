import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
  styled,
} from "@mui/material";
import * as React from "react";

export interface DialogContentTextProps extends MuiDialogContentTextProps {
  // Add your custom props here
}

export const StyledDialogContentText = styled(MuiDialogContentText)(({ theme }) => ({
  fontSize: "14px",
}));

export const DialogContentText: React.FC<DialogContentTextProps> = ({ ...props }) => {
  return <StyledDialogContentText {...props} />;
};
