import JSZip from "jszip";

export const createZip = async (files: File[]) => {
  const zip = new JSZip();

  files?.forEach((file) => {
    zip?.file(file.name, file);
  });

  return zip.generateAsync({ type: "blob" });
};
