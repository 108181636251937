import { FC } from "react";

import { LicenseDataTable } from "./LicenseDataTable";
import { LicenseSoftwareTable } from "./LicenseSoftwareTable";
import { Description, SubTitle } from "./Table";

export const LicenseTier: FC = () => (
  <>
    <SubTitle>License Tiers - Data</SubTitle>
    <Description>
      OpenPaths Patterns is offered in the following data license tiers, differentiated by the depth and richness of the
      available data.
    </Description>
    <LicenseDataTable />

    <SubTitle>License Tiers - Software</SubTitle>
    <Description>
      OpenPaths Patterns can be licensed at two different software license tiers available. Basic offers view-only
      capabilities, while Pro offers viewing, dataset creation, and export capabilities.{" "}
    </Description>
    <LicenseSoftwareTable />
  </>
);
