import { ZONE_DETAILS_ARROWS_SOURCE, ZONE_DETAILS_CENTROIDS_SOURCE } from "./sources";

export const ZONE_DETAILS_CENTROIDS_LAYER = "ZONE_DETAILS_CENTROIDS_LAYER";
export const ZONE_DETAILS_ARROWS_LAYER = "ZONE_DETAILS_ARROWS_LAYER";
export const ZONE_DETAILS_ARROWS_HOVER_LAYER = "ZONE_DETAILS_ARROWS_HOVER_LAYER";

export const getTopFlowLayers = () => [
  {
    id: ZONE_DETAILS_ARROWS_LAYER,
    type: "line",
    source: ZONE_DETAILS_ARROWS_SOURCE,
    paint: {
      "line-width": ["get", "width"],
      "line-opacity": 1,
      "line-pattern": ["get", "pattern"],
    },
  },
  {
    id: ZONE_DETAILS_ARROWS_HOVER_LAYER,
    type: "line",
    source: ZONE_DETAILS_ARROWS_SOURCE,
    paint: {
      "line-width": ["get", "width"],
      "line-opacity": 1,
      "line-pattern": "arrow-hover-pattern",
    },
    filter: ["==", ["get", "id"], ""],
  },
  {
    id: ZONE_DETAILS_CENTROIDS_LAYER,
    type: "circle",
    source: ZONE_DETAILS_CENTROIDS_SOURCE,
    paint: {
      "circle-radius": ["get", "radius"],
      "circle-color": ["get", "color"],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  },
];

export const getTopFlowLayerIds = () => [
  ZONE_DETAILS_CENTROIDS_LAYER,
  ZONE_DETAILS_ARROWS_LAYER,
  ZONE_DETAILS_ARROWS_HOVER_LAYER,
];
