import { Check, Clear } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";
import { IconButton } from "components_new";
import { FC, MutableRefObject } from "react";

export interface ScreenlineGeometryPopupContentProps {
  draw: MutableRefObject<any>;
  onClose: () => void;
}

export const ScreenlineGeometryPopupContent: FC<ScreenlineGeometryPopupContentProps> = ({ draw, onClose }) => {
  const handleDeleteVertex = () => {
    onClose();
    draw.current?.trash();
  };

  return (
    <Grid container alignItems={"center"} padding={1}>
      <Typography fontSize={12} fontWeight={500} marginRight={2}>
        Delete vertex
      </Typography>
      <>
        <IconButton size="small" color="success" onClick={handleDeleteVertex}>
          <Tooltip placement="bottom" title={"Confirm"}>
            <Check fontSize="inherit" />
          </Tooltip>
        </IconButton>
        <IconButton size="small" color="error" onClick={onClose}>
          <Tooltip placement="bottom" title="Cancel">
            <Clear fontSize="inherit" />
          </Tooltip>
        </IconButton>
      </>
    </Grid>
  );
};
