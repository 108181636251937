import { East, North, NorthEast, NorthWest, South, SouthEast, SouthWest, West } from "@mui/icons-material";
import { SvgIconProps } from "@mui/material";
import { SegmentDirection } from "api/analytics/index.d";
import { FC } from "react";

export interface DirectionIconProps extends SvgIconProps {
  direction: SegmentDirection;
}

export const DirectionIcon: FC<DirectionIconProps> = ({ direction, ...props }) => {
  switch (direction) {
    case SegmentDirection.N:
      return <North {...props} />;
    case SegmentDirection.NE:
      return <NorthEast {...props} />;
    case SegmentDirection.E:
      return <East {...props} />;
    case SegmentDirection.SE:
      return <SouthEast {...props} />;
    case SegmentDirection.S:
      return <South {...props} />;
    case SegmentDirection.SW:
      return <SouthWest {...props} />;
    case SegmentDirection.W:
      return <West {...props} />;
    case SegmentDirection.NW:
      return <NorthWest {...props} />;
    default:
      return null;
  }
};
