import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Switch } from "components_new";
import { ChangeEvent } from "react";

import { NumberTextFied } from "components";

import { DistanceSimplifications, DistanceTypes } from "types";

import { InfoBox } from "./UploadZoningFiles";

interface Props {
  distanceType: DistanceTypes;
  disabled: boolean;
  simplification: DistanceSimplifications;
  isSimplificationActive: boolean;
  updateSimplification: (simplification: Partial<DistanceSimplifications>) => void;
  handleChangeDistanceType: (e: ChangeEvent<HTMLInputElement>) => void;
  toggleSimplification: (isActive: boolean) => void;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export const OptionsTab = ({
  distanceType,
  disabled,
  simplification,
  isSimplificationActive,
  updateSimplification,
  handleChangeDistanceType,
  toggleSimplification,
}: Props) => {
  const handleChangeSqrRootAreaFactor = (value: string) => {
    updateSimplification({
      sqrRootAreaFactor: value,
      maxIntervalM: simplification.maxIntervalM,
    });
  };

  const handleChangeMaxIntervalM = (value: string) => {
    updateSimplification({
      sqrRootAreaFactor: simplification.sqrRootAreaFactor,
      maxIntervalM: value,
    });
  };

  const handleChangeIntervalM = (value: string) => {
    updateSimplification({
      intervalM: value,
    });
  };

  return (
    <Box sx={{ px: 2 }}>
      <Switch
        label="Generalization"
        checked={isSimplificationActive}
        disabled={disabled}
        size="small"
        color="secondary"
        onChange={(e) => toggleSimplification(e.target.checked)}
        sx={{ marginY: 1 }}
      />

      <InfoBox>
        Generalization reduces the number of vertices (points along the boundary) of individual zones. The least
        significant vertices are removed, making sure that the resulting zone boundary is within the specified distance
        from the original.
        <br />
        <br />A <strong>larger</strong> distance results in zones having <strong>fewer</strong> vertices and a{" "}
        <strong>simpler</strong> geometry
      </InfoBox>
      <FormControl sx={{ mt: 1, minWidth: 420 }} disabled={disabled || !isSimplificationActive}>
        <RadioGroup value={distanceType} name="radio-buttons-group" onChange={handleChangeDistanceType} row>
          <StyledFormControlLabel
            value={DistanceTypes.Constant}
            control={<Radio size="small" color="secondary" />}
            label="Constant distance"
          />
          <StyledFormControlLabel
            value={DistanceTypes.AreaDependent}
            control={<Radio size="small" color="secondary" />}
            label="Area-dependent distance (advanced)"
          />
        </RadioGroup>
      </FormControl>
      {distanceType === DistanceTypes.AreaDependent && (
        <>
          <Grid container columnSpacing={2} mt={2}>
            <Grid item>
              <NumberTextFied
                label="Area ratio"
                size="small"
                min={0.01}
                disabled={disabled || !isSimplificationActive}
                InputLabelProps={{ shrink: true }}
                value={simplification.sqrRootAreaFactor}
                onChange={(e) => handleChangeSqrRootAreaFactor(e.target.value)}
              />
            </Grid>
            <Grid item>
              <NumberTextFied
                label="Maximum distance (offset)"
                size="small"
                min={0.01}
                disabled={disabled || !isSimplificationActive}
                InputProps={{
                  endAdornment: <InputAdornment position="end">ft.</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                value={simplification.maxIntervalM}
                onChange={(e) => handleChangeMaxIntervalM(e.target.value)}
              />
            </Grid>
          </Grid>
          <InfoBox>
            For each zone, the simplification distance is computed as <br />
            <br />
            &nbsp;&nbsp; <b>square root of zone area * area ratio</b>
            <br />
            <br />
            making sure the value does not exceed <b>maximum distance</b>.<br /> For a boundary between two zones, the
            value from the smaller zone is used.
          </InfoBox>
        </>
      )}
      {distanceType === DistanceTypes.Constant && (
        <Box mt={2}>
          <NumberTextFied
            label="Distance (maximum offset)"
            size="small"
            min={0.01}
            disabled={disabled || !isSimplificationActive}
            InputProps={{
              endAdornment: <InputAdornment position="end">ft.</InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }}
            value={simplification.intervalM}
            onChange={(e) => handleChangeIntervalM(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};
