import { MenuItem } from "@mui/material";
import { TextField } from "components_new";
import { FC, useEffect } from "react";

import { useAppSelector } from "hooks";

interface TimePeriodProps {
  timePeriods: string[];
  selectedTimePeriod: string;
  disabled: boolean;
  defaultTimePeriod?: string;
  setSelectedTimePeriod: (timePeriod: string) => void;
}

export const TimePeriodSelector: FC<TimePeriodProps> = ({
  selectedTimePeriod,
  timePeriods,
  defaultTimePeriod,
  disabled,
  setSelectedTimePeriod,
}) => {
  const globalTimePeriod = useAppSelector((state) => state.global.timePeriod);

  useEffect(() => {
    if (globalTimePeriod && timePeriods.length) {
      const isGlobalTimePeriodAvailable = timePeriods.includes(globalTimePeriod);
      const timePeriod = defaultTimePeriod
        ? defaultTimePeriod
        : isGlobalTimePeriodAvailable
        ? globalTimePeriod
        : timePeriods?.[0];
      setSelectedTimePeriod(timePeriod);
    }
  }, [globalTimePeriod, timePeriods, defaultTimePeriod, setSelectedTimePeriod]);

  return (
    <TextField
      fullWidth
      select
      label="Time period"
      disabled={timePeriods?.length <= 1 || disabled}
      value={selectedTimePeriod}
    >
      {timePeriods.map((timePeriod) => (
        <MenuItem key={timePeriod} value={timePeriod} onClick={() => setSelectedTimePeriod(timePeriod)}>
          {timePeriod}
        </MenuItem>
      ))}
    </TextField>
  );
};
