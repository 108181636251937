import { Alert, Button, Dialog, DialogContentText, DialogProps, TextField } from "components_new";
import React, { ChangeEvent, FC, SyntheticEvent, useCallback, useMemo, useState } from "react";

enum Steps {
  Error = "error",
  ChooseName = "choose-name",
}

export interface SaveErrorDialogProps extends DialogProps {
  error: any;
  handleDiscardChanges: () => void;
  handleOverwrite: () => void;
  handleCreateNewDataset: (name: string) => void;
  onClose: (event: SyntheticEvent<HTMLElement, Event>) => void;
}

export const SaveErrorDialog: FC<SaveErrorDialogProps> = ({
  error,
  onClose,
  handleDiscardChanges,
  handleOverwrite,
  handleCreateNewDataset,
  ...props
}) => {
  const [step, setStep] = useState<Steps>(Steps.Error);
  const [name, setName] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSave = useCallback(() => {
    handleCreateNewDataset(name);
  }, [handleCreateNewDataset, name]);

  const { title, body, actions } = useMemo(() => {
    const datasetNameErrorMsg =
      (error?.status === 422 && error?.body?.reason !== "read-only" && error?.body?.what) || "";

    if (step === Steps.Error) {
      switch (true) {
        case error?.status === 404:
          return {
            title: "This dataset has been deleted",
            body: <DialogContentText>Do you want to save a new copy?</DialogContentText>,
            actions: (
              <>
                <Button variant="outlined" onClick={onClose}>
                  No
                </Button>
                <Button color="secondary" onClick={() => setStep(Steps.ChooseName)}>
                  Yes
                </Button>
              </>
            ),
          };
        case error?.status === 409:
          return {
            title: "This dataset has been changed elsewhere in the meantime",
            body: <DialogContentText>What do you want to do?</DialogContentText>,
            actions: (
              <>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button color="secondary" onClick={handleDiscardChanges}>
                  Discard your changes
                </Button>
                <Button color="secondary" onClick={handleOverwrite}>
                  Overwrite other changes
                </Button>
                <Button color="secondary" onClick={() => setStep(Steps.ChooseName)}>
                  Keep both
                </Button>
              </>
            ),
          };

        case error?.status === 422 && error?.body?.reason === "read-only":
          return {
            title: error?.body?.what,
            body: <DialogContentText>Do you want to save a new copy?</DialogContentText>,
            actions: (
              <>
                <Button variant="outlined" onClick={onClose}>
                  No
                </Button>
                <Button color="secondary" onClick={() => setStep(Steps.ChooseName)}>
                  Yes
                </Button>
              </>
            ),
          };

        default:
          return {
            title: "Error",
            body: (
              <DialogContentText>
                {error?.body?.what || error?.body?.msg || "Something went wrong. Please retry later."}
              </DialogContentText>
            ),
            actions: (
              <Button color="secondary" onClick={onClose}>
                Close
              </Button>
            ),
          };
      }
    }
    if (step === Steps.ChooseName)
      return {
        title: "Choose a name for the new dataset",
        body: (
          <>
            <TextField fullWidth label="Name" value={name} onChange={handleChange} sx={{ marginY: 1 }} />
            {datasetNameErrorMsg && <Alert severity="error">{datasetNameErrorMsg}</Alert>}
          </>
        ),
        actions: (
          <>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button color="secondary" onClick={handleSave}>
              Save
            </Button>
          </>
        ),
      };
    return {
      title: "",
      body: "",
      actions: null,
    };
  }, [name, step, error, setStep, onClose, handleDiscardChanges, handleOverwrite, handleSave]);

  return (
    <Dialog maxWidth="md" title={title} actions={actions} {...props}>
      {body}
    </Dialog>
  );
};
