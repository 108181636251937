import { Tab as MuiTab, TabProps as MuiTabProps, styled } from "@mui/material";
import * as React from "react";

export interface TabProps extends MuiTabProps {
  // Add your custom props here
}

const StyledTab = styled(MuiTab)(({ theme }) => ({
  textTransform: "none",
  minHeight: 0,
}));

export const Tab: React.FC<TabProps> = ({ iconPosition = "start", ...props }) => {
  return <StyledTab iconPosition={iconPosition} {...props} />;
};
