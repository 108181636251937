import { Feature, LineString, MultiLineString, Polygon } from "geojson";

import { ConfigDocumentType, FilterArguments, GeoCoordinates, ValidationMessageSeverity } from "../../types/index";

/**
 * Common types for analytics endpoints
 */

export type MeasureBreakdownArgs = {
  dimensions: string[];
  includeUnfiltered: boolean;
};

export type MeasureSummaryArgs = {
  breakdowns: MeasureBreakdownArgs[];
  filteredTotal: boolean;
  unfilteredTotal?: boolean;
};

export interface MeasureBreakdownResult {
  dimensions: string[];
  rows: Array<{ categories: string[]; value: number; unfilteredValue?: number; featureId?: string }>;
}

export interface MeasureSummaryResults {
  filteredTotal?: number;
  unfilteredTotal?: number;
  breakdowns: MeasureBreakdownResult[];
}

export enum SegmentDirection {
  N = "N",
  NE = "NE",
  E = "E",
  SE = "SE",
  S = "S",
  SW = "SW",
  W = "W",
  NW = "NW",
}

/** A folder in the catalog, corresponding to ch.teralytics.analytics.catalog.Folder in the backend */
export interface CatalogFolder {
  folderId: string;
  folderName: string;
  items: CatalogItem[];
  permissions: Permissions;
  createdAt: Date;
  createdBy?: TedBy;
  updatedAt?: Date;
  updatedBy?: TedBy;
}

/*
 * Configuration document API types
 */

/** Request arguments to create a new configuration document */
export interface ConfigDocumentCreationRequest {
  /** The uuid of the catalog folder where the configuration document should be stored */
  folderId: string;
  /** The document type, used when retrieving documents */
  configDocumentType: ConfigDocumentType;
  /** The name of the document. this has to be unique per parent folder and document type */
  configDocumentName: string;
  /** An optional description of the document */
  description?: string;
  /** The json object to store in the document */
  configPayload: object;
  /** The schema version (initially this can be a constant like '1.0', until the first breaking schema change) */
  configSchemaVersion: string;
  /** An optional bounding box (polygon) for the configuration (e.g. the bounding box of all screenline geometries).
   * If this is defined, then it can be used to filter search results, e.g. to those relevant for a licensed area.
   * Documents without bounding box are always included when applying a spatial filter */
  boundingBox?: Polygon;
  /** Optional time period, only needed for document types that are bound to a given time period (like selectlink, currently) */
  timePeriod?: string;
}

/** Request arguments to update name/description of a configuration document.
 *  To be used from the catalog UI, to allow renaming and adding descriptions to documents */
export interface ConfigDocumentUpdateRequest {
  configDocumentName: string;
  description?: string;
}

/** Request arguments to update the payload (actual configuration) in a configuration document */
export interface ConfigDocumentPayloadUpdateRequest {
  /** The updated json object to store in the document */
  configPayload: object;
  /** The schema version of the uploaded payload */
  configSchemaVersion: string;
  /** The optional bounding box */
  boundingBox?: Polygon;
  /** If an optimistic concurrency check should be applied (which can be needed for big configurations like a map): the expected
   * version of the document. If the document has been updated in the meantime, the update will fail with a 409 conflict error. */
  expectedVersion?: number;
}

/** Response of getting the payload of a configuration document */
export interface GetConfigDocumentPayloadResponse {
  /** The json object stored in the document */
  configPayload: any;
  /** The schema version of the stored object. This can be used to tag incompatible schema version, so that a corresponding
   * decoding strategy can be selected */
  configSchemaVersion: string;
  /** The optional bounding box corresponding to the returned payload */
  boundingBox?: Polygon;
  /** The row version of the returned payload. Can be passed back in the next payload update request as 'expectedVersion' if
   * an optimistic concurrency check is needed.
   */
  version: number;
}

/** Request arguments to search configuration documents */
export interface SearchConfigDocumentsRequest {
  type: ConfigDocumentType;
  filterByLicensedArea: boolean;
  excludeEmptyFolders: boolean;
  folderId: string | null;
  timePeriod: string | null;
}

/** Search response for configuration documents */
export interface SearchConfigDocumentsResponse {
  folders: CatalogFolder[];
}

/*
 *  Screenline API types
 *
 * - can/should we use namespaces for types involved in different groups of endpoints? (e.g. screenlines, corridor etc.)
 * - would it make sense to separate API types from other types used in the app?
 */

export type ScreenlineGeometry = LineString | Polygon;

export enum IntersectionDirection {
  left = "left",
  right = "right",
}

/** Reference to a segment that intersects the screenline in a given direction, with an associated weight.
 * This is dynamically computed when querying for counts (and screenline details), since it depends on the current state of the road network,
 * and optionally returned with counts, and can be passed back to these endpoints to avoid recomputation */
export interface WeightedIntersectingSegment {
  id: string;
  dir: IntersectionDirection;
  weight: number;
}

/** Information about an intersection of a segment with the screenline, which should allow to resolve the actual intersections after changes to the network */
export interface SegmentIntersection {
  segmentId: string;
  timePeriod: string;
  roadName: string;
  roadClass: number;
  segmentDirection: SegmentDirection;
  intersection: IntersectionPoint;
}

/** The precise location of the intersection (WGS84), with the intersection direction (left/right) */
export interface IntersectionPoint {
  lat: number;
  lon: number;
  direction: IntersectionDirection;
  /** The distance along the screenline from the start point to the intersection point, as a fraction of the total screenline length (0 to 1). */
  fraction: number;
}

/** The full definition of a screenline (corresponds to `ScreenlineProperties` in backend) */
export interface Screenline {
  /** Unique screenline id. Counts will be returned for these ids */
  id: string;
  /** The screenline geometry, must be a valid and simple linestring or polygon (with no interior rings) */
  geometry: ScreenlineGeometry;
  /** Optional name of the screenline, which could be shown in hover popups and the like */
  name?: string;
  /** Label to be used for the left intersection direction */
  leftLabel: string;
  /** Label to be used for the right intersection direction */
  rightLabel: string;
  /** Optional screenline description, to give additional context in the screenline editor */
  description?: string;
  /** The segment intersections to be considered when determining counts (optional in backend for tests only) */
  segmentIntersections: SegmentIntersection[];
  /** Optional filter to restrict screenline results to only one direction */
  intersectionDirectionFilter?: IntersectionDirection;
  /** Cached intersecting segments, can be returned by the counts endpoint, and passed back to counts/details requests
   * to avoid computing these intersections.
   * Important: if the screenline is modified in any way then the cached intersections should be discarded -> set to null */
  weightedIntersectingSegments?: WeightedIntersectingSegment[];
  /** Option to show/hide a screenline */
  visible: boolean;
}

/**
 * Getting intersection points/segment geometries for a screenline geometry that is being defined
 */

export interface CandidateScreenlineIntersectionsRequest {
  /** The screenline geometry (linestring or polygon) */
  geometry: ScreenlineGeometry;
  /** The time period, used to identify the road network version on which to resolve segment intersections */
  timePeriod: string;
  /** The optional road classes to filter by (if not defined, all entitled road classes are included) */
  roadClasses?: number[];
}

export interface CandidateScreenlineSegment {
  id: string;
  geometry: ScreenlineGeometry;
  intersections: {
    lon: number;
    lat: number;
    direction: IntersectionDirection;
  }[];
}

export interface CandidateScreenlineIntersectionsResponse {
  /** intersecting segments with corresponding intersection locations/crossing directions */
  segments: CandidateScreenlineSegment[];
}

/**
 *  Creating a screenline
 */
export interface CreateScreenlineRequest {
  /** Unique screenline id. The app has to make sure that these ids are unique */
  id: string;
  geometry: ScreenlineGeometry;
  /** Time period to identify the road network based on which the screenline should be initially defined */
  timePeriod: string;
  /** Optional subset of entitled road classes, only segments of these road classes will be included */
  roadClasses?: number[];
}

export interface CreateScreenlineResponse {
  screenline: Screenline;
}

/**
 * Validating a screenline
 */

export interface ScreenlineValidationRequest {
  /** The screenline to validate */
  screenline: Screenline;
  /** Time period to identify the road network based on which the screenline should be validated */
  timePeriod: string;
  /** The optional list of road classes to filter candidate segments by (segments that are not included in the
   *   screenline, but should be returned as candidates). Default: all entitled road classes. */
  roadClasses?: number[];
}

export interface ScreenlineValidationResponse {
  /** The validated segment intersections, with the resolved intersection on the road network if validation was successful */
  validatedSegmentIntersections: ValidatedSegmentIntersection[];
  /** Validation messages, optionally referencing individual segments and/or locations*/
  validationMessages: ScreenlineValidationMessage[];
  /** The properties of segments involved in resolved screenline intersections */
  resolvedSegments: ScreenlineSegmentProperties[];
  /** The properties of segments that intersect the screenline, but are not included in the resolved intersections */
  candidateSegments: ScreenlineSegmentProperties[];
}

/** The validation result for an individual screenline intersection */
export interface ValidatedSegmentIntersection {
  /** The segment identifier as stored in the screenline intersection */
  segmentId: string;
  /** The road class of the segment, as stored in the screenline intersection */
  roadClass: number;
  /** The intersection point, as stored in the screenline intersection */
  intersectionPoint: IntersectionPoint;
  /** If the intersection was successfully resolved for the road network specified by the requested time period: the
   * resolved segment intersection, otherwise `None` */
  resolvedIntersection?: ResolvedSegmentIntersection;
}

/** A resolved intersection of a screenline segment with a segment in the road network specified by the requested time period */
export interface ResolvedSegmentIntersection {
  /** The segment identifier in the road network specified by the requested time period */
  segmentId: string;
  /** The road name in the requested road network */
  roadName: string;
  /** The road class in the requested road network */
  roadClass: number;
  /** The segment direction */
  segmentDirection: SegmentDirection;
  /** The intersection location and crossing direction, in the requested road network */
  intersection: IntersectionPoint;
  /** The distance from the intersection point stored in the screenline to the resolved intersection point, in meters */
  distanceM: number;
}

/** The properties of a segment in a screenline validation response (either assigned and resolved, or merely intersecting,
 *  i.e. a candidate for inclusion) */
export interface ScreenlineSegmentProperties {
  segmentId: string;
  roadClass: number;
  /** The unique identifier of the road feature the segment belongs to (a single segment for one-way roads, or two
   * segments in opposite directions for bi-directional roads) */
  roadFeatureId: string;
  /** The segment geometry, oriented in the direction of the segment (start point is where the segment starts) */
  geometry: LineString | MultiLineString;
  /** list of screenline intersections for the segment - When adding a candidate to the screenline, it can inserted in
   * the `segmentIntersections`, before the first intersection with a higher `fraction` value of the intersection point
   * (or at the end of the list, if no such intersection exists) */
  segmentIntersections: SegmentIntersection[];
}

export interface ScreenlineValidationMessage {
  text: string;
  severity: "warning" | "error";
  /** The segment this message refers to (if any) */
  segmentId?: string;
  /** The location(s) this message refers to (if any) */
  locations?: GeoCoordinates[];
}

/**
 * Updating the geometry of an existing screenline
 */

export interface UpdateScreenlineGeometryRequest {
  /** The screenline to update */
  screenline: Screenline;
  /** The new geometry for the screenline (linestring or polygon, as GeoJSON) */
  updatedGeometry: ScreenlineGeometry;
  /** The time period, used to identify the road network version on which to resolve segment intersections */
  timePeriod: string;
  /** The optional road classes to filter by (if not defined, all entitled road classes are included) */
  roadClasses?: number[];
  /** If true the response property segments contains the list of segment ids/geometries */
  includeSegments?: boolean;
}

export interface UpdateScreenlineGeometryResponse {
  /** The updated screenline */
  screenline: Screenline;
  segments?: {
    id: string;
    geometry: Geometry;
  }[];
}

/**
 * Getting counts for a list of screenlines
 */
export interface AOIScreenlineCountsRequest {
  /** The relevant screenline properties for counts requests */
  screenlines: {
    id: string;
    geometry: ScreenlineGeometry;
    segmentIntersections: SegmentIntersection[]; // note: optional in backend for testing purposes only
    intersectionDirectionFilter?: IntersectionDirection;
    weightedIntersectingSegments?: WeightedIntersectingSegment[];
  }[];
  timePeriod: string;
  measure: string;
  /** optional per-request road class filter to further restrict the segments used to compute screenline counts */
  roadClasses?: number[];
  filter?: FilterArguments;
  /** indicates if the response should include the weighted intersecting segments */
  includeSegments?: boolean;
}

/** counts response for an individual screenline */
export interface ScreenlineCounts {
  /** the screenline id */
  id: string;
  /** the volume of vehicles crossing the screenline to the left */
  toLeft: number;
  /** the volume of vehicles crossing the screenline to the right */
  toRight: number;
  /** optional computed weights of intersecting segments */
  weightedIntersectingSegments?: WeightedIntersectingSegment[];
}

export interface ScreenlineCountsResponse {
  screenlines: ScreenlineCounts[];
}

/**
 *  Getting details (totals and breakdowns) for an individual screenline
 */
export interface AOIScreenlineDetailsRequest {
  /** The relevant screenline properties for details requests */
  screenline: {
    id: string;
    geometry: ScreenlineGeometry;
    segmentIntersections: SegmentIntersection[];
    intersectionDirectionFilter?: IntersectionDirection;
    weightedIntersectingSegments?: WeightedIntersectingSegment[];
  };
  timePeriod: string;
  measure: string;
  summary: MeasureSummaryArgs;
  /** optional per-request road class filter to further restrict the summarized segments */
  roadClasses?: number[];
  filter?: FilterArguments;
}

export interface ScreenlineDirectionDetails {
  filteredTotals: number;
  unfilteredTotal?: number;
  breakdowns: MeasureBreakdownResult[];
}

export interface ScreenlineDetails {
  toLeft?: ScreenlineDirectionDetails;
  toRight?: ScreenlineDirectionDetails;
  total?: ScreenlineDirectionDetails;
}

export interface ScreenlineDetailsResponse {
  screenlineDetails: ScreenlineDetails;
}

/**
 * Importing screenline features from shapefiles and converting them to screenlines
 */

/** Validation message used in the context of screenline import, with an optional reference to a screenline in the validated
 *  input, by index */
export interface ScreenlineImportValidationMessage {
  /** The message text */
  text: string;
  /** Severity of the validation message. If the severity is `error` for a message referring to a specific screenline
   *  feature, that feature is invalid and cannot be converted to a screenline */
  severity: ValidationMessageSeverity;
  /** Optional index of the feature in the input list of screenline features, in case the message refers to a specific
   *  screenline. If undefined, the message is not specific to a screenline */
  featureIndex?: number;
}

/** Response payload for reading a shapefile with screenlines */
export interface ReadScreenlineShapefileResponse {
  /** The validated and prepared GeoJson screenline features read from the shapefile. Two fields are added to each feature:
   *   - `__valid`: indicates if the feature is valid for conversion to a screenline
   *   - `__index`: the index of the screenline in the original list of features. This index is referred to by
   *                screenline-specific validation messages */
  preparedFeatures: Feature[];
  /** The list of all candidate fields for the id field: integer and text fields with unique, non-null values, excluding
   *  fields matching a configured expression (to exclude fields with prefixes L_ and R_ that are present in exported
   *  screenline shapefiles) */
  idFieldCandidates: string[];
  /** The default id field selected from the shapefile fields or `null` if no suitable id field was found */
  idField?: string;
  /** The list of all candidate fields for the name field: text fields with unique values (ignoring nulls), excluding
   *  fields matching a configured expression */
  nameFieldCandidates: string[];
  /** The default name field selected from the shapefile fields or `null` if no suitable name field was found */
  nameField?: string;
  /** The list of all candidate fields for the description field: all text fields, excluding fields matching a configured
   *  expression */
  descriptionFieldCandidates: string[];
  /** The default description field selected from the shapefile fields or `null` if no suitable description field was found */
  descriptionField?: string;
  /** The list of validation messages, optionally referring to specific screenlines by list index */
  validationMessages: ScreenlineImportValidationMessage[];
  /** The number of features outside the current licensed area */
  disjointCount: number;
}

/** Request payload to convert prepared GeoJson features to screenlines */
export interface ConvertFeaturesToScreenlinesRequest {
  /**  The time period, used to identify the road network version on which to resolve segment intersections */
  timePeriod: string;
  /** The optional road classes to filter intersected segments by (if not defined, all entitled road classes are included) */
  roadClasses?: int[];
  /** Optional name of field from which to read the screenline ids. If not provided, unique numerical ids are generated
   * for each screenline */
  idField?: string;
  /** Optional name of field from which to read the screenline names. If not provided, screenlines are created without names */
  nameField?: string;
  /** Optional name of field from which to read the screenline descriptions. . If not provided, screenlines are created
   * without descriptions */
  descriptionField?: string;
  /** The validated and prepared GeoJson screenline features to convert to screenlines. If the list includes invalid
   *  features, validation errors will be returned in the response, and the invalid features will be excluded from the
   *  conversion. To avoid this, it is recommended to filter this list to just valid prepared features (based on the
   *  `__valid` field in each feature) */
  preparedFeatures: Feature[];
  /**
   * Optional list of existing screenline ids (e.g. in a list of existing screenlines to import to), to make sure that
   * assigned screenline ids are not only unique within the new screenlines, but also with respect to this list
   */
  existingIds?: string[];
  /**
   * Optional flag to ignore screenlines that are disjoint from the current licensed area.
   */
  ignoreDisjointFeatures?: boolean;
}

/** Response payload to a request to convert a list of validated/prepared screenline features to screenlines */
export interface ConvertFeaturesToScreenlinesResponse {
  /** The successfully converted screenlines */
  screenlines: Screenline[];
  /** The list of validation messages, optionally referring to specific screenlines by list index in the input list of
   *  the request */
  validationMessages: ScreenlineImportValidationMessage[];
}
