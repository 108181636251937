import styled from "@emotion/styled";
import React, { FC } from "react";
import ReactPlayer from "react-player/vimeo";

import { usePageTracking } from "hooks";

import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";

const TutorialsContainer = styled(PageContent)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const VideoContainer = styled.div`
  margin: 1rem 1rem 4rem 1rem;
  width: 512px;
  height: 288px;
`;

const VideoTitle = styled.h3`
  margin: 1rem 0 0 1.5rem;
`;

const videos = [
  {
    title: "Overview",
    url: "https://vimeo.com/990028503/46e96f7bcf",
  },
  {
    title: "OD Matrix",
    url: "https://vimeo.com/990043886/9b9071f5b2",
  },
  {
    title: "Volumes",
    url: "https://vimeo.com/990045161/d965b53820",
  },
  {
    title: "Export OD Data",
    url: "https://vimeo.com/990037239/e87bfda7b6",
  },
  {
    title: "Datasets",
    url: "https://vimeo.com/990036077/241827b078",
  },
  {
    title: "Custom Zones",
    url: "https://vimeo.com/990030248/e0d7353ecc",
  },
  {
    title: "Corridor Discovery",
    url: "https://vimeo.com/990038264/b3e8be07df",
  },
  {
    title: "Select Link",
    url: "https://vimeo.com/990037748/6ae8a6ba5a",
  },
  {
    title: "Roadway VMT",
    url: "https://vimeo.com/990031262/36a48276dd",
  },
];

export const Tutorials: FC = () => {
  usePageTracking();

  return (
    <>
      <PageHeader title="OpenPaths Patterns: Tutorials" subtitle="Introduction to OpenPaths Patterns" />
      <TutorialsContainer>
        {videos.map(({ title, url }, index) => (
          <VideoContainer key={index}>
            <ReactPlayer title={title} url={url} controls width="100%" height="100%" />
            <VideoTitle>{title}</VideoTitle>
          </VideoContainer>
        ))}
      </TutorialsContainer>
    </>
  );
};
