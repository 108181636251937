import { Box, Switch as MuiSwitch, SwitchProps as MuiSwitchProps, styled } from "@mui/material";
import * as React from "react";

import { FormControlLabel } from "../FormControlLabel/FormControlLabel";

export interface SwitchProps extends MuiSwitchProps {
  label?: string | React.ReactNode;
  labelPlacement?: "end" | "start";
}

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const Switch: React.FC<SwitchProps> = ({
  label,
  labelPlacement = "end",
  color = "secondary",
  size = "small",
  sx,
  ...props
}) => {
  return (
    <Box sx={sx}>
      {label ? (
        <FormControlLabel
          control={<StyledSwitch color={color} size={size} {...props} />}
          label={label}
          labelPlacement={labelPlacement}
        />
      ) : (
        <StyledSwitch color={color} size={size} {...props} />
      )}
    </Box>
  );
};
