import { Close } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  styled,
} from "@mui/material";
import { IconButton } from "components_new";
import * as React from "react";

export interface DialogProps extends Omit<MuiDialogProps, "title"> {
  closeOnEscapeKeyDown?: boolean;
  closeOnBackdropClick?: boolean;
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
}

const StyledDialog = styled(MuiDialog)(({ theme }) => ({
  "&.zoning-info-dialog .MuiDialog-paper": {
    minHeight: 640,
  },

  "& .MuiDialogTitle-root": {
    paddingTop: theme.spacing(4),
    lineHeight: 1,
    fontSize: "1rem",
  },
}));

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const Dialog: React.FC<DialogProps> = ({
  children,
  title,
  maxWidth = false,
  actions,
  onClose,
  closeOnEscapeKeyDown = false,
  closeOnBackdropClick = false,
  ...props
}) => {
  return (
    <StyledDialog
      maxWidth={maxWidth}
      onClose={(e: any, reason) => {
        if (!closeOnEscapeKeyDown && reason === "escapeKeyDown") return;
        if (!closeOnBackdropClick && reason === "backdropClick") return;

        if (onClose) {
          onClose(e, reason);
        }
      }}
      {...props}
    >
      {onClose && (
        <StyledIconButton size="small" onClick={onClose as () => {}}>
          <Close fontSize="inherit" />
        </StyledIconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </StyledDialog>
  );
};
