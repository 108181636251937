import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Tooltip, styled } from "@mui/material";
import { Dialog } from "components_new";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayerIcon = styled(YouTubeIcon)`
  position: absolute;
  top: 50%;
  margin-top: -4.5rem;
  left: 50%;
  margin-left: -4.5rem;
  width: 9rem;
  height: 9rem;
  opacity: 50%;
  &:hover {
    opacity: 75%;
    cursor: pointer;
  }
`;

const VideoPlayerIconBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

interface VideoPlayerDialogProps {
  videoDetails?: { event?: string; title: string; videoUrl: string };
  onClose: () => void;
  triggerGAEvent?: (event: string) => void;
}

const VideoPlayerDialog: React.FC<VideoPlayerDialogProps> = ({ videoDetails, onClose, triggerGAEvent }) => {
  // only trigger google analytics event, when video is open for 30 seconds
  // NOTE: this is not a perfect solution, but it would avoid some
  useEffect(() => {
    if (triggerGAEvent !== undefined && videoDetails !== undefined) {
      const openVideoDetails = videoDetails;
      const timer = setTimeout(() => {
        if (triggerGAEvent !== undefined && videoDetails !== undefined && videoDetails === openVideoDetails) {
          triggerGAEvent(`play-video-${videoDetails.event}`);
        }
      }, 30000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [videoDetails, triggerGAEvent]);

  return (
    <Dialog title={videoDetails?.title} open={videoDetails !== undefined} onClose={() => onClose()}>
      {videoDetails !== undefined && (
        <ReactPlayer
          playing={true}
          title={videoDetails.title}
          url={videoDetails.videoUrl}
          width="75vw"
          height="75vh"
          controls
        />
      )}
    </Dialog>
  );
};

interface VideoIconProps {
  event?: string;
  title: string;
  imageUrl: string;
  videoUrl: string;
  tooltip?: string;
  fill: "horizontal" | "vertical";
  triggerGAEvent?: (event: string) => void;
}

export const VideoIcon: React.FC<VideoIconProps> = ({
  event,
  title,
  imageUrl,
  videoUrl,
  tooltip,
  fill,
  triggerGAEvent,
}) => {
  const [videoDetails, setVideoDetails] = useState<{ event?: string; title: string; videoUrl: string } | undefined>(
    undefined,
  );
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <VideoPlayerDialog
        videoDetails={videoDetails}
        onClose={() => setVideoDetails(undefined)}
        triggerGAEvent={triggerGAEvent}
      ></VideoPlayerDialog>
      <VideoPlayerIconBox onClick={() => setVideoDetails({ event: event, title: title, videoUrl: videoUrl })}>
        {/* Video player: image  */}
        {fill === "horizontal" ? (
          <Box
            sx={{ width: "auto", height: "100%", borderRadius: "1rem" }}
            component="img"
            alt={title}
            src={imageUrl}
          />
        ) : (
          <Box
            sx={{ width: "100%", height: "auto", borderRadius: "1rem" }}
            component="img"
            alt={title}
            src={imageUrl}
          />
        )}
        {/* Player player: hover icon  */}
        <Tooltip
          title={tooltip}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -25],
                  },
                },
              ],
            },
          }}
          placement="bottom"
        >
          <VideoPlayerIcon />
        </Tooltip>
      </VideoPlayerIconBox>
    </Box>
  );
};
