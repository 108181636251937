export const ODVolumesFileColumns = [
  {
    column: "start_id",
    type: "Text",
    description: "Zone ID of the flow start (zone or gate)",
    notes: "",
  },
  {
    column: "start_idseq",
    type: "Integer",
    description:
      "Renumbered ID of the flow start (zone or gate), mapped into 1-32,000 range as Bentley CUBE allows maximum value for OD zone IDs of 32,000 ",
    notes: "",
  },
  {
    column: "end_id",
    type: "Text",
    description: "Zone ID of the flow end (zone or gate)",
    notes: "",
  },
  {
    column: "end_idseq",
    type: "Integer",
    description:
      "Renumbered ID of the flow end(zone or gate), mapped into 1-32,000 range as Bentley CUBE allows maximum value for OD zone IDs of 32,000 ",
    notes: "",
  },
  {
    column: "<entitled dimension>",
    type: "",
    description: 'Textual attribute value (e.g. "NHB" for "Purpose")',
    notes: "",
  },
  {
    column: "<entitled dimension>_code",
    type: "",
    description: 'Numeric attribute value (e.g. 7 for "NHB" for "Purpose")',
    notes: "",
  },
  {
    column: "trip_type",
    type: "Text",
    description: (
      <div>
        Sub-area traversal type for the trips included in the flow:
        <ul style={{ paddingLeft: "2rem" }}>
          <li>I-I: Internal/Internal (from internal zone to internal zone)</li>
          <li>I-X: Internal/External (from internal zone to gate)</li>
          <li>X-I: External/Internal (from gate to internal zone)</li>
          <li>X-X: External/External (between gates)</li>
        </ul>
      </div>
    ),
    notes: "",
  },
  {
    column: "starts_at_gate",
    type: "Text",
    description: "If the flow starts at a gate (Yes/No)",
    notes: "",
  },
  {
    column: "ends_at_gate",
    type: "Text",
    description: "If the flow ends at a gate (Yes/No)",
    notes: "",
  },
  {
    column: "count",
    type: "Double",
    description: "Vehicle count",
    notes: "",
  },
];
