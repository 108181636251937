import { Button, Dialog } from "components_new";
import { FC } from "react";

export interface EmptyResultWarningProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EmptyResultWarning: FC<EmptyResultWarningProps> = ({ isOpen, onClose }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    title="Select link query did not return any trips"
    actions={
      <Button size="medium" color="secondary" onClick={onClose}>
        Ok
      </Button>
    }
  ></Dialog>
);
