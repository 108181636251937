import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import background from "assets/svg/dashboard/image.svg";
import React, { FC, useEffect, useMemo } from "react";

import { BookTraining } from "features/dashboard/BookTraining";
import { FeatureHighlightItem } from "features/dashboard/FeatureHighlight";
import { FocusAreaPreview } from "features/dashboard/FocusAreaPreview";
import { LearnMoreCard } from "features/dashboard/LearnModeCard";
import { MeetUsEvent } from "features/dashboard/MeetUsEvent";

import { HighligthPageContainer } from "components/atoms/page-container/HighlightPageContainer";
import { PageContainerPanel } from "components/atoms/page-container/PageContainerPanel";
import Carousel from "components/molecules/carousel/Carrousel";

import { useAppDispatch, useAppSelector, usePageTracking } from "hooks";

import { selectAtLeastOneRoadVmtDataPermission } from "store/permissionsSelectors";
import { analyticsActions } from "store/sections/analytics";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { getFeatureHighlightsPanel, learnMorePanel, meetUsPanel } from "./DashboardConfigs";

const DashboardPageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr 460px;
  grid-auto-rows: min-content;
  gap: 2rem;
  row-gap: 3rem;
`;

const LearnMoreContiner = styled(Box)`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  gap: 1rem;
`;

const MeetUsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 1.5rem;
`;

export const DashboardPage: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const isRoadVmtAllowed = useAppSelector(selectAtLeastOneRoadVmtDataPermission);

  const featureHighlights = useMemo(() => getFeatureHighlightsPanel(isRoadVmtAllowed), [isRoadVmtAllowed]);

  const dashboardTriggerGAEvent = (event: string) => {
    addCustomGAEvent("dashboard", "click", event, user, userOrganizationName);
  };

  usePageTracking();

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, dispatch]);

  return (
    <HighligthPageContainer>
      <DashboardPageContainer>
        <PageContainerPanel title={featureHighlights.title} titleColor="white" backgroundImage={`url(${background})`}>
          <Carousel>
            {featureHighlights.carousel
              .filter((item) => item.enabled)
              .map((item, index) => (
                <FeatureHighlightItem
                  key={`carousel-slide-${index}`}
                  item={item}
                  triggerGAEvent={dashboardTriggerGAEvent}
                />
              ))}
          </Carousel>
        </PageContainerPanel>

        <PageContainerPanel title="Focus area" titleColor="white" compact={true}>
          <FocusAreaPreview triggerGAEvent={dashboardTriggerGAEvent} />
        </PageContainerPanel>

        <PageContainerPanel title={learnMorePanel.title} compact={true}>
          <LearnMoreContiner>
            {learnMorePanel.cards.map((item, index) => (
              <LearnMoreCard key={`learn-more-${index}`} item={item} triggerGAEvent={dashboardTriggerGAEvent} />
            ))}
          </LearnMoreContiner>
        </PageContainerPanel>

        <PageContainerPanel title={meetUsPanel.title} compact={true} noRoundCorners={true}>
          <MeetUsContainer>
            {meetUsPanel.events.map((item, idx) => (
              <MeetUsEvent key={`events-${idx}`} item={item} triggerGAEvent={dashboardTriggerGAEvent}></MeetUsEvent>
            ))}
            <BookTraining item={meetUsPanel.gettingTrained} triggerGAEvent={dashboardTriggerGAEvent} />
          </MeetUsContainer>
        </PageContainerPanel>
      </DashboardPageContainer>
    </HighligthPageContainer>
  );
};
