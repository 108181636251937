import { MenuItem } from "@mui/material";
import { TextField } from "components_new";
import React, { useEffect, useState } from "react";

import { FocusAreaItem } from "types";

interface Props {
  areas: FocusAreaItem[];
  defaultSelectedAreaId: string;
  onAreaChange: (areaId: string) => void;
  disabled: boolean;
}

interface AreaDetails {
  id: string;
  text: string;
  areaIds: string[];
}

export const AreaOfFocusSelector = ({ areas, defaultSelectedAreaId, onAreaChange, disabled }: Props) => {
  const [selectedAreaId, setSelectedAreaId] = useState(defaultSelectedAreaId);
  const [subareas, setSubareas] = React.useState<AreaDetails[]>([]);

  useEffect(() => {
    if (areas) {
      setSubareas(
        areas.map((area) => ({
          id: area.id,
          text: area.label,
          areaIds: area?.areas || [],
        })),
      );
    }
  }, [areas]);

  const handleItemChange = (area: AreaDetails) => {
    setSelectedAreaId(area.id);
    onAreaChange(area.id);
  };

  return (
    <>
      {subareas.length > 0 && (
        <TextField select label="Area of interest" disabled={disabled || subareas.length <= 1} value={selectedAreaId}>
          {subareas.map((area) => (
            <MenuItem key={area.id} value={area.id} onClick={() => handleItemChange(area)}>
              {area.text}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};
