import { FeedbackPayload, NotificationsInboxResponse, NotificationsInboxState } from "types";

import RestHandler from "./RestHandler";

export interface AccountApiType {
  getNotificationsInboxState(): Promise<NotificationsInboxState>;
  updateNotificationsInboxState(lastAccessed: string): Promise<void>;
  getNotificationsInbox(): Promise<NotificationsInboxResponse>;
  sendFeedback(payload: FeedbackPayload): Promise<any>;
}

export default function AccountApi(restHandler: RestHandler) {
  return {
    async getNotificationsInboxState() {
      /*
        The suppressEvents `true` parameter is used to indicate that the request is
        a frequent background request and should be ignored by event trackers.
      */
      const body = await restHandler.get<NotificationsInboxState>("my-inbox", undefined, true);
      return body;
    },

    async updateNotificationsInboxState(lastAccessed: string) {
      await restHandler.put("my-inbox", {
        lastAccessed,
      });
    },

    async getNotificationsInbox() {
      const body = await restHandler.get<NotificationsInboxResponse>("my-inbox/notification");
      return body;
    },

    async sendFeedback(payload: FeedbackPayload) {
      const body = await restHandler.post("feedback", payload);
      return body;
    },
  };
}
