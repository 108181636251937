import { styled } from "@mui/material";
import { CountBadge } from "components_new";
import React, { FC, ReactElement } from "react";

export interface VolumeCountProps {
  icon?: ReactElement;
  count: number;
  couldBeAdded?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const Container = styled("td")`
  padding: 0 0.25rem;
`;

export const VolumeCount: FC<VolumeCountProps> = ({ icon, count, couldBeAdded, disabled, onClick }) => {
  return (
    <Container onClick={onClick}>
      <CountBadge icon={icon} disabled={disabled} label={count} />
    </Container>
  );
};
