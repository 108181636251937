import { ConfirmDialog, DialogContentText } from "components_new";
import { FC } from "react";

export interface ConfirmDeleteGroupDialogProps {
  isOpen: boolean;
  segmentsCount: number;
  onClose: () => void;
  deleteSegmentsGroup: () => void;
}

export const ConfirmDeleteGroupDialog: FC<ConfirmDeleteGroupDialogProps> = ({
  isOpen,
  segmentsCount,
  onClose,
  deleteSegmentsGroup,
}) => (
  <ConfirmDialog
    fullWidth
    maxWidth="xs"
    open={isOpen}
    confirmButtonText="Yes"
    cancelButtonText="No"
    onConfirm={deleteSegmentsGroup}
    onCancel={onClose}
    title={`This group contains ${segmentsCount} segment${segmentsCount > 1 ? "s" : ""}`}
  >
    <DialogContentText>Are you sure you want to delete it?</DialogContentText>
  </ConfirmDialog>
);
