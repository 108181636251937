import styled from "@emotion/styled";
import { DragIndicator } from "@mui/icons-material";
import React, { forwardRef } from "react";

import { Action, ActionProps } from "./Action";

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  cursor: grab;
`;

export const Handle = forwardRef<HTMLButtonElement, ActionProps>((props, ref) => {
  return (
    <Action ref={ref} cursor="grab" data-cypress="draggable-handle" {...props}>
      <ActionWrapper>
        <DragIndicator sx={{ color: "text.secondary" }} fontSize="small" />
      </ActionWrapper>
    </Action>
  );
});
