import { AnySourceData } from "mapbox-gl";

import { getODSource } from "features/map-visualization";
import { OUT_ZONES_SOURCE_ID } from "features/map/modules/od/map-data/od/sources";
import { getMaxZoom, getODPromoteIds } from "features/map/utils";

import { RoadVmtMetadata } from "types";

import { getAvailableLayers } from "../utils";

export const ROAD_VMT_ZONE_SOURCE_ID = "ROAD_VMT_ZONE_SOURCE_ID";

export const getRoadVmtSources = (roadVmtMetadata: RoadVmtMetadata) => {
  const roadVmtSources: {
    id: string;
    source: AnySourceData;
  }[] = [];

  const { tileService } = roadVmtMetadata;
  const availableLayers = getAvailableLayers(roadVmtMetadata);
  const maxZoom = getMaxZoom(availableLayers);

  roadVmtSources.push({
    id: ROAD_VMT_ZONE_SOURCE_ID,
    source: getODSource(tileService, false, availableLayers, maxZoom),
  });

  const externalZonesTileService = roadVmtMetadata.tileService;

  roadVmtSources.push({
    id: OUT_ZONES_SOURCE_ID,
    source: {
      type: "vector",
      tiles: [externalZonesTileService.layers[0].url + "/{z}/{x}/{y}.pbf"],
      promoteId: getODPromoteIds(externalZonesTileService.layers),
      minzoom: externalZonesTileService.layers[0].minzoom,
      maxzoom: externalZonesTileService.layers[0].maxzoom,
    },
  });

  return roadVmtSources;
};
