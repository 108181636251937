import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { Badge } from "components_new";
import find from "lodash/find";
import React, { FC } from "react";

// import { FlowsSettingsControls } from "../filters/FlowsSettingsControls";
import { TopFlowsChart } from "features/map-visualization/TopFlowsChart";
import { getCustomFlowColor, getFlowPattern } from "features/map/utils";

import { BarChart, Legend } from "components";

import {
  FlowPattern,
  FlowsSettings,
  HoveredFlow,
  Measure,
  QueryType,
  SelectedArea,
  ZoneDetails,
  ZoningLevel,
} from "types";

interface ODAnalyticsProps {
  isCustomDataset: boolean;
  zoneDetails: ZoneDetails;
  selectedZone: SelectedArea;
  flowsDirection: QueryType;
  flowsSettings: FlowsSettings;
  zoningLevels: ZoningLevel[] | undefined;
  setFlowsSettings: (settings: FlowsSettings) => void;
  hoveredFlow: HoveredFlow | null;
  setHoveredFlow: (hoveredTopFlow: HoveredFlow | null) => void;
  ODMetadataDimensions: Measure[] | undefined;
}

const TopFlowCountBadge = styled(Badge)`
  width: 50px;
`;

const ODAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-lg);
  overflow-y: auto;
  height: calc(100% - 100px);
`;

const GraphContainer = styled.div`
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  overflow-y: hide;
`;

const ChartWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid var(--color-border);
  &:not(div:first-of-type) {
    margin-bottom: var(--padding-lg);
  }
`;

const ChartTitle = styled.div`
  margin: 10px 10px 0 20px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`;

const EmptyState = styled.div`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 20px;
  margin: 20px;
  font-size: var(--font-size-sm);
  font-weight: bold;
`;

const TopFlowLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px 20px 20px;

  & > div {
    display: flex;
    flex-direction: row;

    & > h5 {
      margin: 0 4px 0 4px;
    }
  }
`;

const LegendTitle = styled.h5`
  font-weight: 500;
  padding: 0 4px 0 6px;
  margin-left: 4px;
  border-left: 1.5px solid #abb3bf;
`;

export const getLabelByDimension = (dimension: string, ODMetadataDimensions: Measure[]): string => {
  return find(ODMetadataDimensions, { columnName: dimension })?.label || dimension;
};

export const getLabelByCategories = (category: string, dimension: string, ODMetadataDimensions: Measure[]): string => {
  const categories = find(ODMetadataDimensions, {
    columnName: dimension,
  })?.categories;

  if (categories && categories.length > 0) {
    return find(categories, { value: category })?.label || category;
  }

  return category;
};

const getFlowLegendLabel = (flowPattern: FlowPattern) => {
  switch (flowPattern) {
    case FlowPattern.internal:
      return "Internal";
    case FlowPattern.gate:
      return "Gates";
    case FlowPattern.counties:
      return "Counties";
    case FlowPattern.internalRound:
      return "Internal & Round";
    default:
      return "Unknown";
  }
};

const ODAnalytics: FC<ODAnalyticsProps> = ({
  isCustomDataset,
  selectedZone,
  flowsDirection,
  flowsSettings,
  setFlowsSettings,
  zoneDetails,
  hoveredFlow,
  ODMetadataDimensions,
  zoningLevels,
  setHoveredFlow,
}) => {
  const flowTitleByDimension = `Top ${flowsDirection.charAt(0).toUpperCase() + flowsDirection.slice(1)} ${
    flowsDirection === QueryType.INCOMING ? "Origins" : "Destinations"
  }`;

  const flowData = zoneDetails?.topFlows?.[flowsDirection]?.flows.map((flow, index) => ({
    id: flow.id,
    name: `${index + 1}`,
    value: flow.value,
    flowPattern: getFlowPattern(flow, selectedZone.id, selectedZone.itemType),
    level: flow.level,
    isGate: !!flow.isGate,
    areaName: flow.areaName || flow.id,
    levelName: flow.levelName,
    countryName: flow.countryName,
    external: !!flow.external,
  }));

  const getTopFlowsLegend = () => (
    <>
      <Legend
        key={FlowPattern.internal}
        label={getFlowLegendLabel(FlowPattern.internal)}
        height={15}
        color={getCustomFlowColor(FlowPattern.internal)}
        isSquare
      />
      <LegendTitle>External:</LegendTitle>
      {isCustomDataset && (
        <Legend
          key={FlowPattern.gate}
          label={getFlowLegendLabel(FlowPattern.gate)}
          height={15}
          color={getCustomFlowColor(FlowPattern.gate)}
          isSquare
        />
      )}
      {
        <Legend
          key={FlowPattern.counties}
          label={getFlowLegendLabel(FlowPattern.counties)}
          height={15}
          color={getCustomFlowColor(FlowPattern.counties)}
          isSquare
        />
      }
    </>
  );

  return (
    <ODAnalyticsContainer>
      <GraphContainer>
        <ChartWrapper key="top-flows">
          <ChartTitle>
            <Typography variant="subtitle2">{flowTitleByDimension}</Typography>
            <Stack direction={"row"} spacing={1}>
              <TopFlowCountBadge
                clickable
                onClick={() =>
                  setFlowsSettings({
                    ...flowsSettings,
                    n: 10,
                  })
                }
                variant={flowsSettings.n === 10 ? "filled" : "outlined"}
                label={"10"}
              />
              <TopFlowCountBadge
                clickable
                onClick={() =>
                  setFlowsSettings({
                    ...flowsSettings,
                    n: 20,
                  })
                }
                variant={flowsSettings.n === 20 ? "filled" : "outlined"}
                label={"20"}
              />
            </Stack>
          </ChartTitle>
          {flowData && flowData.length > 0 ? (
            <TopFlowsChart
              data={flowData}
              flowsDirection={flowsDirection}
              hoveredFlow={hoveredFlow}
              zoningLevels={zoningLevels}
              setHoveredFlow={setHoveredFlow}
              getCustomColor={getCustomFlowColor}
            />
          ) : (
            <EmptyState>No Trips Available</EmptyState>
          )}
          <TopFlowLegend>{getTopFlowsLegend()}</TopFlowLegend>
        </ChartWrapper>
        {zoneDetails?.summaries?.[flowsDirection]?.breakdowns.map((breakdown, index) => {
          const dimension = breakdown.dimensions[0];
          const data = breakdown.rows.map((row) => ({
            name: getLabelByCategories(row.categories[0], dimension, ODMetadataDimensions || []),
            value: row.value,
          }));

          return data.length > 0 ? (
            <ChartWrapper key={index}>
              <BarChart
                key={index}
                data={data}
                layout="vertical"
                title={getLabelByDimension(dimension, ODMetadataDimensions || [])}
              />
            </ChartWrapper>
          ) : null;
        })}
      </GraphContainer>
    </ODAnalyticsContainer>
  );
};

export { ODAnalytics };
