import { useAuth0 } from "@auth0/auth0-react";
import { FC, useEffect, useState } from "react";

import { RangeSlider } from "components";

import { useAppSelector, useDebounce } from "hooks";

import { MeasureRange } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

export interface RangeFilterProps {
  label?: string;
  range?: [number, number];
  availableRange?: MeasureRange;
  quantile?: number;
  loading?: boolean;
  filterByRange: (range: [number, number]) => void;
  setRange: (newRange: [number, number], availableRange: MeasureRange) => void;
}

export const RangeFilter: FC<RangeFilterProps> = ({
  label,
  range = [0, 0],
  availableRange = { min: 0, max: 0 },
  quantile,
  loading,
  filterByRange,
  setRange,
}) => {
  const { user } = useAuth0();

  const [errors, setErrors] = useState({
    min: "",
    max: "",
  });
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const validateRange = (range: [number, number], availableRange: MeasureRange) => {
    const min = range[0];
    const max = range[1];
    setErrors({ min: "", max: "" });

    if (min < availableRange.min) {
      setErrors((prevState) => ({ ...prevState, min: "Less than min value" }));
    }
    if (min > max) {
      setErrors((prevState) => ({ ...prevState, min: "Min > max" }));
    }
    if (max > availableRange.max) {
      setErrors((prevState) => ({ ...prevState, max: "Exceeds max value" }));
    }
    if (max < min) {
      setErrors((prevState) => ({ ...prevState, max: "Max < min" }));
    }
  };

  const debouncedFilterByRange = useDebounce(filterByRange, 100);

  const handleSetRange = (newRange: [number, number]) => {
    validateRange(newRange, availableRange);
    setRange(newRange, availableRange);

    addCustomGAEvent("corridor", "edges", "change_range", user, userOrganizationName);
  };

  useEffect(() => {
    if (!loading) debouncedFilterByRange();
  }, [range, debouncedFilterByRange, loading]);

  return (
    <RangeSlider
      label={label}
      range={range}
      availableRange={availableRange}
      errors={errors}
      resetMin={quantile || availableRange.min}
      loading={Boolean(loading)}
      handleSetRange={handleSetRange}
    />
  );
};
