import arrowHover from "assets/svg/arrow-hover.svg";
import countiesArrow from "assets/svg/counties-arrow.svg";
import externalArrow from "assets/svg/external-arrow.svg";
import gateArrow from "assets/svg/gate-arrow.svg";
import internalArrow from "assets/svg/internal-arrow.svg";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { Map } from "mapbox-gl";

export const ZONE_DETAILS_ARROWS_SOURCE = "ZONE_DETAILS_ARROWS_SOURCE";
export const ZONE_DETAILS_CENTROIDS_SOURCE = "ZONE_DETAILS_CENTROIDS_SOURCE";

export const getTopFlowSources = (
  arrowsGeoJson: FeatureCollection<Geometry, GeoJsonProperties>,
  centroidsGeoJson: FeatureCollection<Geometry, GeoJsonProperties>,
) => {
  return [
    {
      id: ZONE_DETAILS_ARROWS_SOURCE,
      source: {
        type: "geojson",
        data: arrowsGeoJson,
        lineMetrics: true,
        maxzoom: 15,
      },
    },
    {
      id: ZONE_DETAILS_CENTROIDS_SOURCE,
      source: {
        type: "geojson",
        data: centroidsGeoJson,
      },
    },
  ];
};

export const getTopFlowSourceIds = () => [ZONE_DETAILS_ARROWS_SOURCE, ZONE_DETAILS_CENTROIDS_SOURCE];

export const initSVG = (map: Map) => {
  if (!map.hasImage("internal-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("internal-pattern", arrowSVG);
    arrowSVG.src = internalArrow;
  }

  if (!map.hasImage("gate-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("gate-pattern", arrowSVG);
    arrowSVG.src = gateArrow;
  }

  if (!map.hasImage("counties-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("counties-pattern", arrowSVG);
    arrowSVG.src = countiesArrow;
  }

  if (!map.hasImage("external-pattern")) {
    const arrowSVG = new Image(64, 64);
    arrowSVG.onload = () => map.addImage("external-pattern", arrowSVG);
    arrowSVG.src = externalArrow;
  }

  if (!map.hasImage("arrow-hover-pattern")) {
    const arrowHoverSVG = new Image(64, 64);
    arrowHoverSVG.onload = () => map.addImage("arrow-hover-pattern", arrowHoverSVG);
    arrowHoverSVG.src = arrowHover;
  }
};
