import styled from "@emotion/styled";
import errorSvg from "assets/svg/error.svg";
import { Button } from "components_new";
import React, { FC } from "react";

import { FlexContainer, StatePageContainer } from "components";

export interface MapErrorPageProps {
  size?: "lg" | "sm";
}

const MapErrorPageContainer = styled(StatePageContainer)`
  position: relative;
  z-index: 3;
  background-color: var(--color-secondaryLight);
`;

const ErrorMsgContainer = styled(FlexContainer)<MapErrorPageProps>`
  flex-direction: column;
  justify-content: center;

  & h1 {
    font-size: ${({ size }) => (size === "lg" ? "72px" : "30px")};
    font-weight: 600;
    color: var(--color-secondary);
    margin: 0 0 ${({ size }) => (size === "lg" ? "2rem" : "1rem")} 0;
  }

  & p {
    margin: 1rem 0 1.5rem 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-gray-500);
  }

  & button {
    margin-bottom: 1rem;
  }
`;

export const MapErrorPage: FC<MapErrorPageProps> = ({ size = "lg" }) => (
  <MapErrorPageContainer>
    <ErrorMsgContainer size={size}>
      <h1>Oh no!</h1>
      <img src={errorSvg} alt="error" width={size === "lg" ? 450 : 150} />
      <p>It looks like something went wrong.</p>
      <Button size="large" onClick={() => window.location.reload()}>
        Try again
      </Button>
    </ErrorMsgContainer>
  </MapErrorPageContainer>
);
