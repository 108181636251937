import { combineReducers } from "@reduxjs/toolkit";
import { all, fork } from "redux-saga/effects";

import analyticsReducer, { analyticsSaga } from "./sections/analytics";
import corridorReducer, { corridorSaga } from "./sections/corridor";
import exportReducer, { exportSaga } from "./sections/export";
import filtersReducer, { filtersSaga } from "./sections/filters";
import globalReducer, { globalSaga } from "./sections/global";
import licenseReducer, { licenseSaga } from "./sections/license";
import mapReducer, { mapSaga } from "./sections/map";
import roadVmtReducer, { roadVmtSaga } from "./sections/roadVmt";
import screenlinesReducer, { screenlinesSaga } from "./sections/screenlines";
import selectLinkReducer, { selectLinkSaga } from "./sections/selectLink";
import datasetFoldersReducer, { datasetFoldersSaga } from "./sections/userFolders";

export function* rootSaga() {
  yield all([
    fork(globalSaga),
    fork(analyticsSaga),
    fork(exportSaga),
    fork(licenseSaga),
    fork(datasetFoldersSaga),
    fork(selectLinkSaga),
    fork(mapSaga),
    fork(filtersSaga),
    fork(corridorSaga),
    fork(roadVmtSaga),
    fork(screenlinesSaga),
  ]);
}

export const rootReducer = combineReducers({
  global: globalReducer,
  analytics: analyticsReducer,
  export: exportReducer,
  license: licenseReducer,
  datasetFolders: datasetFoldersReducer,
  selectLink: selectLinkReducer,
  map: mapReducer,
  filters: filtersReducer,
  corridor: corridorReducer,
  roadVmt: roadVmtReducer,
  screenlines: screenlinesReducer,
});
