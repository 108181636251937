import { bbox, bboxPolygon } from "@turf/turf";
import { Screenline, ScreenlineSegmentProperties, SegmentIntersection } from "api/analytics/index.d";
import { Map } from "mapbox-gl";

import {
  SCREENLINES_INTERSECTIONS_LAYER_ID,
  SCREENLINES_SEGMENTS_LAYER_ID,
  SCREENLINES_VOLUMES_LAYER_ID,
  getIntersectionsLayerCircleColorExpression,
  getScreenlinesLeftVolumesLayerOffsetExpression,
  getScreenlinesLeftVolumesLayerWidthExpression,
  getScreenlinesRightVolumesLayerOffsetExpression,
  getScreenlinesRightVolumesLayerWidthExpression,
  getSegmentsLayerLineColorExpression,
} from "features/map/modules/screenlines/map-data/layers";

export const getIntersectionGroupId = (segment: ScreenlineSegmentProperties, intersection: SegmentIntersection) =>
  `${segment.roadFeatureId} ${intersection.intersection.fraction}`;

export const getIntersectionLabel = (intersection: SegmentIntersection, leftLabel: string, rightLabel: string) =>
  intersection.intersection.direction === "left" ? leftLabel : rightLabel;

export const getIntersectionGroups = (
  candidateSegments: ScreenlineSegmentProperties[],
  resolvedSegments: ScreenlineSegmentProperties[],
  filterFractions?: number[],
) => {
  const intersectionGroups: { [key: string]: { intersection: SegmentIntersection; candidate: boolean }[] } = {};

  candidateSegments.forEach((segment) => {
    segment.segmentIntersections.forEach((intersection) => {
      if (
        filterFractions?.length &&
        !filterFractions.find((fraction) => fraction === intersection?.intersection.fraction)
      )
        return;

      const intersectionGroupId = getIntersectionGroupId(segment, intersection);
      if (!intersectionGroups[intersectionGroupId])
        intersectionGroups[intersectionGroupId] = [{ intersection, candidate: true }];
      else intersectionGroups[intersectionGroupId].push({ intersection, candidate: true });
    });
  });

  resolvedSegments.forEach((segment) => {
    segment.segmentIntersections.forEach((intersection) => {
      if (
        filterFractions?.length &&
        !filterFractions.find((fraction) => fraction === intersection?.intersection.fraction)
      )
        return;

      const intersectionGroupId = getIntersectionGroupId(segment, intersection);
      if (!intersectionGroups[intersectionGroupId])
        intersectionGroups[intersectionGroupId] = [{ intersection, candidate: false }];
      else intersectionGroups[intersectionGroupId].push({ intersection, candidate: false });
    });
  });

  return Object.entries(intersectionGroups).sort(
    ([aKey, aValue], [bKey, bValue]) => Number(aKey.split(" ")[1]) - Number(bKey.split(" ")[1]),
  );
};

export const setScreenlineLayersWidthFactor = (map: Map, widthFactor: number, maxVolume: number) => {
  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
    "line-width",
    getScreenlinesLeftVolumesLayerWidthExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_left`,
    "line-offset",
    getScreenlinesLeftVolumesLayerOffsetExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
    "line-width",
    getScreenlinesRightVolumesLayerWidthExpression(widthFactor, maxVolume),
  );

  map.setPaintProperty(
    `${SCREENLINES_VOLUMES_LAYER_ID}_right`,
    "line-offset",
    getScreenlinesRightVolumesLayerOffsetExpression(widthFactor, maxVolume),
  );
};

export const setScreenlineLayersRoadClasses = (map: Map, roadClasses: number[] | null) => {
  map.setPaintProperty(SCREENLINES_SEGMENTS_LAYER_ID, "line-color", getSegmentsLayerLineColorExpression(roadClasses));

  map.setPaintProperty(
    SCREENLINES_INTERSECTIONS_LAYER_ID,
    "circle-color",
    getIntersectionsLayerCircleColorExpression(roadClasses),
  );
};

export const getScreenlinesBboxPolygon = (screenlines: Screenline[]) => {
  const geometries = screenlines.map(({ geometry }) => geometry);
  const box = bbox({ type: "GeometryCollection", geometries });
  const polygon = bboxPolygon(box).geometry;

  return polygon;
};

export const mergeScreenlines = (screenlines: Screenline[], newScreenlines: Screenline[]) => {
  const sortedScreenlines = [...screenlines].sort((a, b) => Number(a.id) - Number(b.id));

  newScreenlines.forEach((screenline: Screenline) => {
    const lastId = Number(sortedScreenlines[sortedScreenlines.length - 1].id);
    if (sortedScreenlines.find((s) => s.id === screenline.id))
      sortedScreenlines.push({ ...screenline, id: String(lastId + 1) });
    else sortedScreenlines.push(screenline);
  });
  return sortedScreenlines;
};

export const normalizeLoadedScreenlines = (screenlines: Screenline[]) => {
  return screenlines.map((screenline) => ({
    ...screenline,
    visible: screenline.visible === undefined ? true : screenline.visible,
  }));
};
