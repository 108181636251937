import { Divider as MuiDivider, DividerProps as MuiDividerProps } from "@mui/material";
import * as React from "react";

export interface DividerProps extends MuiDividerProps {
  // Add your custom props here
}

export const Divider: React.FC<DividerProps> = ({ ...props }) => {
  return <MuiDivider {...props} />;
};
