import { styled } from "@mui/material/styles";
import { Button, Divider, TextField, TextFieldProps } from "components_new";
import React, { useCallback, useEffect, useState } from "react";

import { FlexContainer } from "components";

import { useAppSelector } from "hooks";

import { SegmentsGroup, SelectLinkAnalysisOptions } from "types";

const TopMarginDivider = styled(Divider)`
  margin-top: 32px;
`;

const SelectLinkOptions = styled("div")`
  display: flex;
  gap: 10px;
  padding-top: 12px;
`;

const ActionButtons = styled(FlexContainer)`
  margin: 1rem 0;
  justify-content: space-between;
`;

const ComputeButton = styled(Button)`
  width: 100px;
`;

interface Props {
  isResults: boolean;
  loadingResults: boolean;
  minVolume: number;
  minAllowedVolume: number;
  selectedLinkGroups: SegmentsGroup[];
  onChangeOptions: (options: SelectLinkAnalysisOptions) => void;
  onSaveConfiguration: (options: SelectLinkAnalysisOptions) => void;
  onRunAnalysis: (options: SelectLinkAnalysisOptions) => void;
  onConfigureAnalysis: () => void;
}

const MinVolumeTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    fontSize: "10px",
    margin: "0px",
    marginLeft: "2px",
    lineHeight: "10px",
    padding: "0px",
    paddingTop: "2px",
    color: theme.palette.warning.main,
  },
}));

export const SelectLinkConfigSettings = ({
  isResults,
  loadingResults,
  minVolume,
  minAllowedVolume,
  selectedLinkGroups,
  onChangeOptions,
  onSaveConfiguration,
  onRunAnalysis,
  onConfigureAnalysis,
}: Props) => {
  const [errors, setErrors] = useState({
    min: "",
  });

  const isLoading = useAppSelector((state) => state.selectLink.loading);

  const selectedLinks = selectedLinkGroups.flatMap((group) => group.segments);
  const canRunAnalysis = selectedLinks.length > 0;
  const isVolumeValid = isResults || minVolume >= minAllowedVolume;

  const handleMinVolumeChange = (e: any) => {
    const newMinVolume = +e.target.value;
    validateMinValue(newMinVolume, minAllowedVolume);
    onChangeOptions({ minVolume: newMinVolume });
  };

  const validateMinValue = useCallback(
    (value: number, minValue: number) => {
      setErrors({ min: "" });

      if (value < minValue) {
        setErrors((prevState) => ({ ...prevState, min: `Less than ${minAllowedVolume}` }));
      }
    },
    [minAllowedVolume],
  );

  useEffect(() => {
    validateMinValue(minVolume, minAllowedVolume);
  }, [validateMinValue, minVolume, minAllowedVolume]);

  return (
    <>
      <TopMarginDivider />
      <SelectLinkOptions>
        <MinVolumeTextField
          type="number"
          name="minVolume"
          size="small"
          label="Min Volume"
          value={minVolume ?? 0}
          disabled={typeof minVolume !== "number" || !canRunAnalysis || isResults || isLoading}
          InputLabelProps={{ shrink: true }}
          onChange={handleMinVolumeChange}
          helperText={errors.min === "" ? " " : errors.min}
          color={errors.min !== "" ? "warning" : "secondary"}
          sx={{
            maxWidth: 110,
          }}
        />
      </SelectLinkOptions>
      <Divider />
      <ActionButtons>
        <ComputeButton
          type="button"
          disabled={!canRunAnalysis || !isVolumeValid || loadingResults}
          onClick={() => (isResults ? onConfigureAnalysis() : onRunAnalysis({ minVolume }))}
        >
          <>{isResults ? "Configure" : "Run Analysis"}</>
        </ComputeButton>
        <Button
          variant="outlined"
          type="submit"
          disabled={isResults || isLoading || !isVolumeValid}
          onClick={() => onSaveConfiguration({ minVolume })}
        >
          Save
        </Button>
      </ActionButtons>
    </>
  );
};
