import { Grid, Stack, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { ToggleButton } from "components_new";
import { ChangeEvent, FC } from "react";

import { CheckboxDropdown } from "components";

import { FiltersType, QueryType } from "types";

import { areAllItemsUnChecked } from "./Filters";

export interface ODFiltersPanelProps {
  filters: FiltersType | null;
  queryType: QueryType;
  loading: boolean;
  disabled?: boolean;
  handleChangeQueryType: (type: QueryType) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({ fontSize: 12 }));

export const ODFiltersPanel: FC<ODFiltersPanelProps> = ({
  filters,
  queryType,
  loading,
  disabled,
  handleChangeQueryType,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  const handleQueryTypeChange = (event: React.MouseEvent<HTMLElement>, newQueryType: QueryType) => {
    handleChangeQueryType(newQueryType);
  };

  return (
    <>
      <Grid container justifyContent={"space-between"} alignItems={"center"} marginBottom={2}>
        <Typography fontSize={11} fontWeight={700} color="text.secondary">
          Trip Direction
        </Typography>

        <ToggleButtonGroup exclusive value={queryType} onChange={handleQueryTypeChange}>
          <StyledToggleButton value={QueryType.INCOMING}>Incoming</StyledToggleButton>
          <StyledToggleButton value={QueryType.OUTGOING}>Outgoing</StyledToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Stack spacing={2}>
        {filters &&
          Object.entries(filters).map(([groupKey, group], i) => (
            <CheckboxDropdown
              key={i}
              isGroupChecked={group.isChecked}
              groupName={groupKey}
              groupLabel={group.label}
              groupIcon={group.icon}
              items={group.items}
              placement="top"
              disabled={loading || disabled}
              error={areAllItemsUnChecked(group.items)}
              onChange={handleChangeFilter}
              selectAll={handleChangeAllFilters(true)}
              clearAll={handleChangeAllFilters(false)}
            />
          ))}
      </Stack>
    </>
  );
};
