import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { initialFlowsSettings } from "features/map/utils";

import { HoveredFlow } from "types";

import { MapController } from "./MapController";
import { ODAnalyticsPanel } from "./ODAnalyticsPanel";

interface ODModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  setODModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  hoveredTopFlow: HoveredFlow | null;
  setHoveredTopFlow: (hoveredTopFlow: HoveredFlow | null) => void;
}

export const ODModuleComponent = ({
  map,
  layerManagerRef,
  setODModuleData,
  updateODModeCounts,
  hoveredTopFlow,
  setHoveredTopFlow,
}: ODModuleComponentProps) => {
  const [flowsSettings, setFlowsSettings] = useState(initialFlowsSettings);

  const closeODAnalyticsPanelRef = useRef<() => void>(null);

  useEffect(() => {
    const closeODAnalyticsPanel = closeODAnalyticsPanelRef.current;

    return () => {
      if (closeODAnalyticsPanel) {
        closeODAnalyticsPanel();
      }

      setODModuleData(null);
    };
  }, [setODModuleData]);

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        flowsSettings={flowsSettings}
        closeODAnalyticsPanelRef={closeODAnalyticsPanelRef}
        setODModuleData={setODModuleData}
        updateODModeCounts={updateODModeCounts}
      />
      <ODAnalyticsPanel
        flowsSettings={flowsSettings}
        closeODAnalyticsPanelRef={closeODAnalyticsPanelRef}
        hoveredTopFlow={hoveredTopFlow}
        setHoveredTopFlow={setHoveredTopFlow}
        setFlowsSettings={setFlowsSettings}
      />
    </>
  );
};
