import { Dispatch, SetStateAction, useEffect } from "react";

import { ModuleData } from "features/map/ModuleManager";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { getGatesLayers } from "./map-data/layers";
import { getGatesSources } from "./map-data/sources";

interface Props {
  setGatesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
}

export const MapController = ({ setGatesModuleData }: Props) => {
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const gates = useAppSelector((state) => state.analytics.datasetGates);

  // Set module data
  useEffect(() => {
    if (selectedFocusArea && gates.state === DataState.AVAILABLE) {
      const commonSources = getGatesSources(gates.data);
      const commonLayers = getGatesLayers();

      setGatesModuleData((data) => (data ? data : { sources: commonSources, layers: commonLayers, data: { gates } }));
    }
  }, [selectedFocusArea, gates, setGatesModuleData]);

  return null;
};
