import { ToggleButtonGroup, styled } from "@mui/material";
import { ToggleButton } from "components_new";

import { SelectLinkPredicateLogic } from "types";

interface Props {
  value: string;
  disabled: boolean;
  onChange: (index: SelectLinkPredicateLogic) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & .MuiButtonBase-root {
    font-size: 11px;
    font-weight: 600;
    min-width: 50px;
    padding: 0;
    margin: 2px 0;
  }
`;

export const AndOrToggle = ({ value, disabled, onChange }: Props) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newLogicIndex: SelectLinkPredicateLogic) => {
    if (newLogicIndex) onChange(newLogicIndex);
  };

  return (
    <StyledToggleButtonGroup value={value} exclusive disabled={disabled} onChange={handleChange}>
      <ToggleButton value="0">AND</ToggleButton>
      <ToggleButton value="1">OR</ToggleButton>
    </StyledToggleButtonGroup>
  );
};
