import { Layer } from "mapbox-gl";

import { GATES_SOURCE_ID } from "./sources";

export const GATES_LAYER_ID = "GATES_LAYER_ID";

export const getGatesLayers = () =>
  [
    {
      id: GATES_LAYER_ID,
      type: "circle",
      source: GATES_SOURCE_ID,
      paint: {
        "circle-color": ["case", ["boolean", ["feature-state", "hover"], false], "#1E40AF", "#139EEC"],
        "circle-radius": 8,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    },
    {
      id: `highlight-range-${GATES_LAYER_ID}`,
      type: "circle",
      source: GATES_SOURCE_ID,
      filter: ["in", "identifier", ""],
      paint: {
        "circle-opacity": 0,
        "circle-radius": 8,
        "circle-stroke-width": 3,
        "circle-stroke-opacity": 0.8,
        "circle-stroke-color": "#ffff00",
      },
    },
  ] as Layer[];
