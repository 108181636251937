import styled from "@emotion/styled";
import { FC, useMemo } from "react";

import { ExportFilters, ExportJob } from "types";

import { FilterList, getFiltersFromDimensions, getSelectLinkFilters } from "./";

export interface ExportDetailProps {
  exportJob: ExportJob;
}

const OpenRowContainer = styled.div`
  margin: 0.5rem 1rem 1rem 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  align-items: stretch;
  height: 220px;
`;

const OptionsLabel = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-align: center;
`;

const OptionsContainer = styled.div`
  height: calc(220px - 1rem);
  padding: 0.75rem 1rem 0.5rem 1rem;
  color: var(--color-textSecondary);
  border: 1px solid var(--color-gray-200);
  background-color: var(--color-gray-50);
  border-radius: 8px;
  overflow-y: scroll;
`;

const OptionsList = styled.ul`
  margin: 0 0 0.5rem 1.5rem;
  list-style-type: square;
`;

const OptionsListItem = styled.li`
  font-size: 11px;
`;

export const ExportDetail: FC<ExportDetailProps> = ({ exportJob }) => {
  const currentRoadMeasure = useMemo(
    () => (exportJob.road ? Object.values(exportJob.road?.measures)?.[0] : null),
    [exportJob.road],
  );
  const parsedOdFilters = useMemo(
    () =>
      exportJob.od
        ? getFiltersFromDimensions(
            exportJob.od?.measures?.aadt?.filter as ExportFilters,
            exportJob.od?.measures?.aadt?.dimensions || [],
          )
        : {},
    [exportJob.od],
  );

  const parsedRoadsFilters = useMemo(
    () =>
      currentRoadMeasure
        ? getFiltersFromDimensions(currentRoadMeasure.filter as ExportFilters, currentRoadMeasure.dimensions || [])
        : {},
    [currentRoadMeasure],
  );

  const selectLinkFilters = useMemo(
    () => (exportJob.selectLink ? getSelectLinkFilters(exportJob.selectLink) : null),
    [exportJob.selectLink],
  );

  const roadVmtMeasure = useMemo(
    () => (exportJob.roadVmt ? Object.values(exportJob.roadVmt?.measures)?.[0] : null),
    [exportJob.roadVmt],
  );
  const roadVmtFilters = useMemo(
    () =>
      roadVmtMeasure
        ? getFiltersFromDimensions(roadVmtMeasure.filter as ExportFilters, roadVmtMeasure.dimensions || [])
        : {},
    [roadVmtMeasure],
  );

  return (
    <OpenRowContainer>
      {!exportJob.roadVmt && (
        <div>
          <OptionsLabel>Options</OptionsLabel>
          <OptionsContainer>
            <OptionsList>
              {exportJob.od && <OptionsListItem>Zoning level: {exportJob.od.level}</OptionsListItem>}
              {exportJob.od && (
                <OptionsListItem>Zones exported: {exportJob?.od?.shouldExportZones ? "Yes" : "No"}</OptionsListItem>
              )}
              <OptionsListItem>
                Segments exported: {exportJob.road?.shouldExportSegments ? "Yes" : "No"}
              </OptionsListItem>
              <OptionsListItem>
                Detailed volumes by category: {exportJob.road?.shouldExportVolumeBreakdowns ? "Yes" : "No"}
              </OptionsListItem>
              <OptionsListItem>
                Sequential Ids: {exportJob.commonOptions.shouldGenerateSeqIds ? "Yes" : "No"}
              </OptionsListItem>
              <OptionsListItem>Screenlines exported: {exportJob.road.screenlineCount ? "Yes" : "No"}</OptionsListItem>
            </OptionsList>
          </OptionsContainer>
        </div>
      )}
      {!exportJob.roadVmt && (
        <div>
          <OptionsLabel>Road Classes</OptionsLabel>
          <OptionsContainer>
            <OptionsList>
              {exportJob.road?.roadClassesToExport?.map((roadClass: any) => (
                <OptionsListItem key={roadClass.id}>
                  {roadClass.id} - {roadClass.label}
                </OptionsListItem>
              ))}
            </OptionsList>
          </OptionsContainer>
        </div>
      )}
      {exportJob.od && (
        <div>
          <OptionsLabel>OD Filters</OptionsLabel>
          <OptionsContainer>
            <FilterList filters={parsedOdFilters} />
          </OptionsContainer>
        </div>
      )}

      {exportJob.selectLink && selectLinkFilters && (
        <div>
          <OptionsLabel>Select Link Filters</OptionsLabel>
          <OptionsContainer>
            <FilterList filters={selectLinkFilters} />
          </OptionsContainer>
        </div>
      )}

      {exportJob.road && (
        <div>
          <OptionsLabel>Roads Filters</OptionsLabel>
          <OptionsContainer>
            <FilterList filters={parsedRoadsFilters} />
          </OptionsContainer>
        </div>
      )}

      {exportJob.roadVmt && (
        <div>
          <OptionsLabel>Road VMT & Mileage Filters</OptionsLabel>
          <OptionsContainer>
            <FilterList filters={roadVmtFilters} />
          </OptionsContainer>
        </div>
      )}
    </OpenRowContainer>
  );
};
