import { MoreHoriz } from "@mui/icons-material";
import { IconButton, IconButtonProps } from "components_new";

export const PopoverControlButton = (props: IconButtonProps) => (
  <IconButton
    color="primary"
    sx={{
      borderRadius: "6px",
      width: "30px",
      height: "30px",
      boxShadow: 1,
    }}
    {...props}
  >
    <MoreHoriz />
  </IconButton>
);
