import { HighlightAlt, WarningAmberOutlined } from "@mui/icons-material";
import { FormGroup, Grid, ListItemIcon, ListItemText, MenuItem, Stack, Typography, styled } from "@mui/material";
import { Alert, Button, Checkbox, Dialog, DialogContentText, DialogProps, TextField } from "components_new";
import { ChangeEvent, FC, useEffect, useState } from "react";

import { TimePeriodSelector } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { CreateDatasetPayload, CustomDataset, DatasetType } from "types";

interface FolderDatasetDialogProps extends Omit<DialogProps, "onSubmit"> {
  areas?: boolean;
  buttonLabel: string;
  description?: string;
  fieldLabel?: string;
  initialText?: string;
  errorMessage?: string | null;
  isAreaOfFocusSelectDisabled?: boolean;
  datasetToCopy?: CustomDataset;
  optionsAvailable?: boolean;
  customZoning?: { id: string; name: string } | null;
  onSubmit: (
    name: string,
    openEditor: boolean,
    configOptions: Omit<CreateDatasetPayload, "folderId" | "datasetName">,
  ) => void;
}

const Label = styled("label")`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

export const FolderDatasetDialog: FC<FolderDatasetDialogProps> = ({
  areas,
  description,
  fieldLabel,
  buttonLabel,
  initialText,
  errorMessage,
  isAreaOfFocusSelectDisabled,
  datasetToCopy,
  optionsAvailable,
  customZoning,
  onSubmit,
  ...props
}) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.datasetFolders.loading);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreas);

  const [text, setText] = useState(initialText || "");
  const [activeSubareaIndex, setActiveSubareaIndex] = useState(0);
  const [timePeriod, setTimePeriod] = useState<string>("");
  const [options, setOptions] = useState({
    initializeSubarea: true,
    addDefaultGates: true,
    openEditor: true,
  });

  useEffect(() => {
    if (areas) {
      dispatch(analyticsActions.fetchFocusAreas(customZoning?.id, customZoning ? true : false));
    }
  }, [customZoning, areas, dispatch]);

  useEffect(() => {
    if (datasetToCopy && isAreaOfFocusSelectDisabled) {
      const subareaIndex = focusAreas.data?.findIndex((area) => area.id === `${datasetToCopy.licensedAreaId}`);
      if (subareaIndex !== undefined) setActiveSubareaIndex(subareaIndex);
    }
  }, [focusAreas.data, datasetToCopy, isAreaOfFocusSelectDisabled]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleChangeOptions = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "initializeSubarea" && !checked) {
      setOptions({ ...options, [name]: checked, addDefaultGates: false });
      return;
    }
    setOptions({ ...options, [name]: checked });
  };

  const handleSubmit = () => {
    onSubmit(text, options.openEditor, {
      timePeriod,
      datasetType: DatasetType.OD,
      licensedAreaId: focusAreas.data?.[activeSubareaIndex]?.licensedAreaId as string,
      initializeSubarea: options.initializeSubarea,
      addDefaultGates: options.addDefaultGates,

      ...(customZoning?.id ? { customZoningId: customZoning.id } : {}),
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      actions={
        <Button
          size="medium"
          color="secondary"
          loading={loading}
          disabled={!text || (areas && !focusAreas.data?.length)}
          onClick={handleSubmit}
        >
          {buttonLabel}
        </Button>
      }
      {...props}
    >
      <Stack spacing={2} marginTop={1}>
        {description && <DialogContentText>{description}</DialogContentText>}
        {customZoning && (
          <>
            <Label>Custom zoning</Label>
            <Typography fontSize={14} fontWeight={600} mb={1}>
              {customZoning.name}
            </Typography>
          </>
        )}
        <TextField
          fullWidth
          label={fieldLabel}
          value={text}
          onChange={handleChange}
          disabled={areas && !focusAreas.data?.length}
          sx={{ marginTop: 1 }}
        />

        {areas && (
          <>
            {/* TODO Implement AreaOfFocusSelector component */}

            <TextField
              fullWidth
              select
              label="Area of interest"
              disabled={
                Boolean(focusAreas.data?.length && focusAreas.data.length <= 1) ||
                isAreaOfFocusSelectDisabled ||
                !focusAreas.data?.length
              }
              value={activeSubareaIndex}
            >
              {(focusAreas?.data || []).map((area, i) => (
                <MenuItem key={area.id} value={i} disabled={!area.enabled} onClick={() => setActiveSubareaIndex(i)}>
                  <Grid container alignItems={"center"}>
                    <ListItemIcon sx={{ minWidth: "0 !important", marginRight: "8px" }}>
                      {area.enabled ? (
                        <HighlightAlt fontSize="small" color={"secondary"} />
                      ) : (
                        <WarningAmberOutlined fontSize="small" color={"warning"} />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={area.label}
                      primaryTypographyProps={{ noWrap: true, fontSize: 14 }}
                      secondary={area.disabledReason}
                      secondaryTypographyProps={{ color: "warning.main", fontSize: 10 }}
                      sx={{ margin: 0 }}
                    />
                  </Grid>
                </MenuItem>
              ))}
            </TextField>

            <TimePeriodSelector
              timePeriods={focusAreas.data?.[activeSubareaIndex]?.timePeriods || []}
              selectedTimePeriod={timePeriod}
              defaultTimePeriod={datasetToCopy?.timePeriod}
              setSelectedTimePeriod={setTimePeriod}
              disabled={false}
            />
          </>
        )}
        {!datasetToCopy && focusAreas.state === DataState.AVAILABLE && !focusAreas.data?.length && (
          <Alert severity="warning">No area of interest intersects with the uploaded zoning.</Alert>
        )}
        {optionsAvailable && (
          <FormGroup sx={{ marginTop: 1 }}>
            {!datasetToCopy && (
              <>
                <Checkbox
                  color="secondary"
                  size="small"
                  name="initializeSubarea"
                  checked={options.initializeSubarea}
                  onChange={handleChangeOptions}
                  disabled={!focusAreas.data?.length}
                  label="Initialize subarea (covering all zones)"
                />

                <Checkbox
                  color="secondary"
                  size="small"
                  name="addDefaultGates"
                  checked={options.addDefaultGates}
                  onChange={handleChangeOptions}
                  disabled={!focusAreas.data?.length || !options.initializeSubarea}
                  label="Add gates on major roads"
                />
              </>
            )}

            <Checkbox
              color="secondary"
              size="small"
              name="openEditor"
              checked={options.openEditor}
              onChange={handleChangeOptions}
              disabled={!focusAreas.data?.length}
              label="Open dataset editor"
            />
          </FormGroup>
        )}
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </Stack>
    </Dialog>
  );
};
