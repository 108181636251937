import { Box, styled } from "@mui/material";
import { Map } from "mapbox-gl";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";

import { RoadVmtLoadingSpinnerWrapper } from "features/vmt/RoadVmtLoadingSpinnerWrapper";
import { RoadVmtMap } from "features/vmt/RoadVmtMap";
import { RoadVmtMapControlsPanel } from "features/vmt/RoadVmtMapControlPanel";
import { RoadVmtMapController } from "features/vmt/RoadVmtMapController";
import { ZoneAnalyticsPanel } from "features/vmt/ZoneAnalyticsPanel";
import { ZonePopupContent, ZonePopupProps } from "features/vmt/ZonePopupContent";

import { PopupWrapper } from "components/molecules/popup-wrapper/PopupWrapper";

const MapPageContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const RoadVmtPage = () => {
  const [mapLoaded, setMapLoaded] = useState(false);

  const map = useRef<Map | null>(null);
  const mapController = useRef(null);

  const zonePopupRef = useRef<HTMLDivElement>(null);
  const setZonePopupRef = useRef<Dispatch<SetStateAction<ZonePopupProps | null>>>(null);

  const [colorScaleFn, setColorScaleFn] = useState<Function | null>(null);

  const closeZoneAnalyticsPanelRef = useRef<() => void>(null);

  return (
    <MapPageContainer>
      <RoadVmtMapControlsPanel mapController={mapController} colorScaleFn={colorScaleFn} />
      <RoadVmtMapController
        map={map}
        mapController={mapController}
        mapLoaded={mapLoaded}
        zonePopupRef={zonePopupRef}
        setZonePopupRef={setZonePopupRef}
        setColorScale={setColorScaleFn}
        closeZoneAnalyticsPanelRef={closeZoneAnalyticsPanelRef}
      />
      <RoadVmtMap map={map} onMapLoaded={setMapLoaded}>
        <PopupWrapper
          popupRef={zonePopupRef}
          setPopupRef={setZonePopupRef}
          renderPopupContent={({ id, feature, vmt, vmtPerSqmi, mileage }) => (
            <ZonePopupContent id={id} feature={feature} vmt={vmt} vmtPerSqmi={vmtPerSqmi} mileage={mileage} />
          )}
        />
      </RoadVmtMap>
      <ZoneAnalyticsPanel closeZoneAnalyticsPanelRef={closeZoneAnalyticsPanelRef} />
      <RoadVmtLoadingSpinnerWrapper />
    </MapPageContainer>
  );
};
