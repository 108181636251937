import { Box, Menu as MuiMenu, MenuProps as MuiMenuProps } from "@mui/material";
import * as React from "react";

export interface MenuProps extends Omit<MuiMenuProps, "open"> {
  control: (handleOpen: (event: React.MouseEvent<HTMLElement>) => void, open: boolean) => JSX.Element;
  children: React.ReactNode;
}

export const Menu: React.FC<MenuProps> = ({ control, children, sx, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "menu" : undefined;

  return (
    <Box sx={sx}>
      {control(handleOpen, open)}
      <MuiMenu id={id} open={open} anchorEl={anchorEl} onClose={handleClose} {...props}>
        {children}
      </MuiMenu>
    </Box>
  );
};
