import { Stack } from "@mui/material";
import { Button, Dialog, DialogProps } from "components_new";
import * as React from "react";

interface ConfirmDialogProps extends Omit<DialogProps, "actions"> {
  onCancel: () => void;
  onConfirm: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  onConfirm,
  onCancel,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  ...props
}) => {
  return (
    <Dialog
      closeOnBackdropClick={false}
      actions={
        <Stack direction={"row"} spacing={2}>
          <Button size="medium" color="secondary" variant="outlined" onClick={onCancel}>
            {cancelButtonText}
          </Button>
          <Button size="medium" color="secondary" onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </Stack>
      }
      {...props}
    />
  );
};
