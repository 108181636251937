import { Tabs as MuiTabs, TabsProps as MuiTabsProps, styled } from "@mui/material";
import * as React from "react";

export interface TabsProps extends MuiTabsProps {
  // Add your custom props here
}

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    bottom: theme.spacing(1),
  },
}));

export const Tabs: React.FC<TabsProps> = ({ color = "secondary", ...props }) => {
  return <StyledTabs color={color} {...props} />;
};
