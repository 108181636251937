import { MenuItem, Stack } from "@mui/material";
import { Button, CircularProgress, Dialog, TextField } from "components_new";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions } from "store/sections/screenlines";
import { datasetFoldersActions } from "store/sections/userFolders";

import { getScreenlinesBboxPolygon } from "./utils";

export const ScreenlineSaveDialog: FC = () => {
  const dispatch = useAppDispatch();

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const datasetFoldersData = useAppSelector((state) => state.datasetFolders.folders.data);
  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const isSaveScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isSaveScreenlinesDialogOpen);
  const loading = useAppSelector((state) => state.datasetFolders.loading);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState("");

  const folders = useMemo(() => Object.entries(datasetFoldersData?.folders || {}), [datasetFoldersData?.folders]);

  const handleSaveScreenlines = () => {
    if (timePeriod && name && selectedFolderId) {
      const polygon = getScreenlinesBboxPolygon(screenlines);

      dispatch(
        datasetFoldersActions.createConfigDocument({
          folderId: selectedFolderId,
          configDocumentType: "screenlines",
          configDocumentName: name,
          description: description,
          configPayload: screenlines,
          configSchemaVersion: "1.0",
          boundingBox: polygon,
          timePeriod: timePeriod,
        }),
      );
    }
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setSelectedFolderId("");
  };

  const handleCloseDialog = useCallback(() => {
    dispatch(screenlinesActions.setIsSaveScreenlineDialogOpen(false));
    resetForm();
  }, [dispatch]);

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(datasetFoldersActions.fetchFoldersStructure());
    }
  }, [isTokenLoaded, dispatch]);

  //If just one folder exist, set it to selected
  useEffect(() => {
    if (folders.length === 1) {
      setSelectedFolderId(folders[0][0]);
    }
  }, [folders]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isSaveScreenlinesDialogOpen}
      onClose={handleCloseDialog}
      title="Save screenlines to document"
      actions={
        <Stack direction={"row"} spacing={1}>
          <Button color="secondary" variant="outlined" disabled={loading} onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button color="secondary" disabled={loading || !folders.length} onClick={handleSaveScreenlines}>
            {loading ? <CircularProgress size={14} color="inherit" /> : "Save"}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={2} marginTop={1}>
        <TextField
          label={"Folder"}
          select
          required
          value={selectedFolderId}
          disabled={loading || !folders.length}
          onChange={(e) => setSelectedFolderId(e.target.value)}
        >
          {folders.map(([folderId, folder]) => (
            <MenuItem key={folderId} value={folderId}>
              {folder.folderName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={"Document name"}
          required
          value={name}
          disabled={loading || !folders.length}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label={"Description"}
          multiline
          minRows={2}
          maxRows={2}
          value={description}
          disabled={loading || !folders.length}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Stack>
    </Dialog>
  );
};
