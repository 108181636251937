import { Help } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const SegmentNote = () => (
  <p>
    The counts for segments which are positioned in two zones are included proportional to the length that is in each
    zone.
  </p>
);

export const roadAadtVmtFileColumns = [
  {
    column: "time_period",
    type: "Text",
    description: "Year i.e. “2019”, “2022” ",
    notes: "",
  },
  {
    column: "zone_id",
    type: "Integer",
    description: "County ID (FIPS Code)",
    notes: "",
  },
  {
    column: "road_urban_rural",
    type: "Text",
    description: (
      <div>
        Road type (“Urban”, “Rural”)<sup>1</sup>
      </div>
    ),
    notes: "",
  },
  {
    column: "road_ramp",
    type: "Text",
    description: "Ramp (“Ramp”, “Non-ramp”)",
    notes: "",
  },
  {
    column: "fhwa_class",
    type: "Integer",
    description: (
      <div>
        FHWA functional class<sup>2</sup>
      </div>
    ),
    notes: "",
  },
  {
    column: "aadt_vmt",
    type: "Long",
    description: (
      <div>
        Sum of the result of the multiplication of AADT of each segment with its length in miles for all segments
        touching a zone, aggregated by zone ID{" "}
        <Tooltip title={<SegmentNote />}>
          <Help style={{ marginLeft: "10px" }} />
        </Tooltip>
      </div>
    ),
    notes: "",
  },
];
