import protobuf from "protobufjs";

import { Counts, ODMeasureRange, ZoneIdAreaPairs, ZoneStats } from "../types";
import RestHandler from "./RestHandler";

export const getVolumesProtobuf = async (
  restHandler: RestHandler,
  uri: string,
  request: any,
  protoName: string,
  propertyName: string = "segmentId",
): Promise<any> => {
  return await restHandler.postForBinary(uri, request).then((res) => {
    const volumes = new Map();
    let maxVolume: number | null = null;
    let minVolume: number | null = null;

    const buffer = new Uint8Array(res);
    const reader = protobuf.Reader.create(buffer);
    return Promise.resolve(
      protobuf
        .load(`/${protoName}.proto`)
        .then((root: any) => {
          const SegmentCount = root.lookupType(`analytics.clickhouse.${protoName}`);

          while (reader.pos < reader.len) {
            const msg = SegmentCount.decodeDelimited(reader);
            maxVolume = maxVolume !== null ? Math.max(maxVolume, msg.measure) : msg.measure;
            minVolume = minVolume !== null ? Math.min(minVolume, msg.measure) : msg.measure;
            volumes.set(msg[propertyName], msg.measure);
          }

          return { volumes, maxVolume: maxVolume || 0, minVolume: minVolume || 0 };
        })
        .catch((err: any) => {
          throw new Error(err);
        }),
    );
  });
};

export const mergeZoningLevelsCounts = (counts: Counts[]): Counts => {
  const zones: Map<string, number> = new Map();
  const gates: Map<string, number> = new Map();
  const availableRange: ODMeasureRange = {};

  counts.forEach((count) => {
    Object.assign(availableRange, count.availableRange);

    Array.from(count.zones.keys()).forEach((key) => {
      if (!zones.has(key)) zones.set(key, count.zones.get(key)!);
    });
    Array.from(count.gates.keys()).forEach((key) => {
      if (!gates.has(key)) gates.set(key, count.gates.get(key)!);
    });
  });

  return {
    zones,
    gates,
    availableRange,
  };
};

export const parseCounts = (body: any, zoningLevel: string, protoName?: string) => {
  const zones: Map<string, number> = new Map();
  const gates: Map<string, number> = new Map();
  let minCount: number | null = null;
  let maxCount: number | null = null;

  const buffer = new Uint8Array(body);
  const reader = protobuf.Reader.create(buffer);

  return Promise.resolve(
    protobuf
      .load(`/${protoName}.proto`)
      .then((root: any) => {
        const ItemCount = root.lookupType(`analytics.clickhouse.${protoName}`);

        while (reader.pos < reader.len) {
          const msg = ItemCount.decodeDelimited(reader);

          minCount = minCount !== null ? Math.min(minCount, msg.measure) : msg.measure;
          maxCount = maxCount !== null ? Math.max(maxCount, msg.measure) : msg.measure;

          if (msg.isGate) {
            gates.set(msg.zoneId, msg.measure > 0 ? msg.measure : 0);
          } else {
            zones.set(msg.zoneId, msg.measure > 0 ? msg.measure : 0);
          }
        }

        return {
          zones,
          gates,
          availableRange: {
            [zoningLevel]: { min: minCount || 0, max: maxCount || 0 },
          },
        };
      })
      .catch((err: any) => {
        throw new Error(err);
      }),
  );
};

export const parseNumbers = (body: any, protoName: string, propertyName: string) => {
  const result: number[] = [];
  const buffer = new Uint8Array(body);
  const reader = protobuf.Reader.create(buffer);

  return Promise.resolve(
    protobuf
      .load(`/${protoName}.proto`)
      .then((root: any) => {
        const ItemCount = root.lookupType(`analytics.clickhouse.${protoName}`);

        while (reader.pos < reader.len) {
          const msg = ItemCount.decodeDelimited(reader);

          result.push(msg[propertyName]);
        }

        return result;
      })
      .catch((err: any) => {
        throw new Error(err);
      }),
  );
};

export const parseZoneIds = (body: ZoneIdAreaPairs): Map<string, ZoneStats> => {
  const zoneIds: Map<string, ZoneStats> = new Map();

  for (const zone of body.zoneIds) {
    const { id, ...props } = zone;
    zoneIds.set(id, props as ZoneStats);
  }

  return zoneIds;
};

export const mergeLicensedAreaAndDatasetItems = (items: any[]) => {
  const mergedItems = [];
  const licensedAreaItems = items[0].licensedAreaItems || items[1].licensedAreaItems;
  const datasetItems = items[0]?.datasetItems || items[1]?.datasetItems;
  const datasetItemsMap = datasetItems?.reduce((map: any, item: any) => {
    const { licensedAreaId } = item;
    const listItem = {
      id: String(item.datasetId),
      areaSqKm: item.areaSqKm,
      centroidLat: item.centroidLat,
      centroidLon: item.centroidLon,
      datasetId: item.datasetId,
      label: item.datasetName.trim(),
      folderName: item.folderName.trim(),
      gateCount: item.gateCount,
      zoningLevel: item.zoningLevel,
      population: item.population,
      geometry: item.subareaGeometry,
      areas: item.areaUnits.map((areaUnit: number | string) => String(areaUnit)),
      licensedAreaId: String(licensedAreaId),
      timePeriods: [item.timePeriod],
    };

    if (map.has(licensedAreaId)) {
      map.set(licensedAreaId, [...map.get(licensedAreaId), listItem]);
    } else {
      map.set(licensedAreaId, [listItem]);
    }

    return map;
  }, new Map());

  for (let i = 0, { length } = licensedAreaItems; i < length; i++) {
    const { licensedAreaId } = licensedAreaItems[i];
    const listItem = {
      id: String(licensedAreaId),
      areaSqKm: licensedAreaItems[i].areaSqKm,
      centroidLat: licensedAreaItems[i].centroidLat,
      centroidLon: licensedAreaItems[i].centroidLon,
      label: licensedAreaItems[i].name,
      population: licensedAreaItems[i].population,
      zoningLevel: licensedAreaItems[i].zoningLevel,
      region: licensedAreaItems[i].name,
      geometry: licensedAreaItems[i].geometry,
      areas: licensedAreaItems[i].areaUnits.map((areaUnit: number | string) => String(areaUnit)),
      licensedAreaId: String(licensedAreaId),
      enabled: licensedAreaItems[i].enabled,
      disabledReason: licensedAreaItems[i].disabledReason,
      timePeriods: licensedAreaItems[i].timePeriods,
      isDemo: licensedAreaItems[i].isDemo,
    };

    mergedItems.push(listItem);

    if (datasetItemsMap?.has(licensedAreaId)) {
      mergedItems.push(
        ...datasetItemsMap
          .get(licensedAreaId)
          .map((item: any) => ({ ...item, region: licensedAreaItems[i].name, isDemo: licensedAreaItems[i].isDemo })),
      );
    }
  }

  return mergedItems;
};
