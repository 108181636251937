import { AnySourceData } from "mapbox-gl";

export const SCREENLINES_SOURCE_ID = "SCREENLINES_SOURCE_ID";
export const SCREENLINES_SEGMENTS_SOURCE_ID = "SCREENLINES_SEGMENTS_SOURCE_ID";
export const SCREENLINES_INTERSECTIONS_SOURCE_ID = "SCREENLINES_INTERSECTIONS_SOURCE_ID";

export const getScreenlinesSources = () => {
  const sources = [
    {
      id: SCREENLINES_SOURCE_ID,
      source: {
        type: "geojson",
        promoteId: "id",
        buffer: 512,
        data: { type: "FeatureCollection", features: [] },
      } as AnySourceData,
    },
    {
      id: SCREENLINES_SEGMENTS_SOURCE_ID,
      source: {
        type: "geojson",
        promoteId: "segmentId",
        data: { type: "FeatureCollection", features: [] },
      } as AnySourceData,
    },
    {
      id: SCREENLINES_INTERSECTIONS_SOURCE_ID,
      source: {
        type: "geojson",
        promoteId: "id",
        data: { type: "FeatureCollection", features: [] },
      } as AnySourceData,
    },
  ];

  return sources;
};
