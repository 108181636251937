import { AnySourceData } from "mapbox-gl";

import { getODPromoteIds } from "features/map/utils";

import { ODMetadata, ODTileLayer, ODTileService, SelectedArea } from "types";

export interface ZoneSourceInput {
  selectedZone: SelectedArea | null;
  tileService: ODTileService;
  availableLayers: ODTileLayer[];
  maxZoom: number;
  metadata?: ODMetadata;
}

export const defaultLayers = ["admin_country", "road-label"];

export const getODSource = (
  tileService: ODTileService,
  isZoningLevelBlocked: boolean,
  availableLayers: ODTileLayer[],
  maxZoom: number,
) => {
  return {
    type: "vector",
    tiles: isZoningLevelBlocked
      ? [availableLayers[0].url + "/{z}/{x}/{y}.pbf"]
      : [tileService.url + "/{z}/{x}/{y}.pbf"],
    promoteId: getODPromoteIds(tileService.layers),
    minzoom: tileService.minZoom,
    maxzoom: maxZoom,
  } as AnySourceData;
};
