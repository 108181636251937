import { EventData, MapMouseEvent, MapboxGeoJSONFeature, Popup } from "mapbox-gl";

import { getLayerFromZoom, getSqMiDensity, updateFeatureStatesByCounts } from "features/map/utils";

import { ODTileLayer, SelectedZone } from "types";

import { ROAD_VMT_LAYER_PREFIX } from "./layers";
import { ROAD_VMT_ZONE_SOURCE_ID } from "./sources";

export const getRoadVmtHandlers = (
  mapRef: any,
  roadVmtTileLayers: ODTileLayer[],
  layerId: string,
  zoneIdsRef: any,
  zoneVmtCountsRef: any,
  zoneMileageCountsRef: any,
  zonePopupRef: any,
  mapboxZoneCountsHoverPopupRef: any,
  setZonePopupRef: any,
  closeZoneAnalyticsPanelRef: any,
  setSelectedZone: (selectedZone: SelectedZone | null) => void,
) => {
  let hoveredZone: null | MapboxGeoJSONFeature = null;
  let selectedZone: null | MapboxGeoJSONFeature = null;

  const intFormat = Intl.NumberFormat("en-US");
  const decimalFormat = Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  });

  closeZoneAnalyticsPanelRef.current = () => {
    deselectZone();
  };

  const updateCounts = (scaleFn: (n: number) => string) => {
    if (mapRef.current && mapRef.current.getSource(ROAD_VMT_ZONE_SOURCE_ID)) {
      const zoom = mapRef.current.getZoom();
      const counts = zoneVmtCountsRef.current;
      const zoneIds = zoneIdsRef.current;
      const sourceLayerId = getLayerFromZoom(zoom, roadVmtTileLayers)?.name as string;
      const layerZoomLevel = getLayerFromZoom(zoom, roadVmtTileLayers)?.level as string;

      updateFeatureStatesByCounts({
        map: mapRef.current,
        counts,
        ids: zoneIds,
        scale: scaleFn,
        sourceLayerId,
        zoneSourceId: ROAD_VMT_ZONE_SOURCE_ID,
        layerZoomLevel,
        byArea: true,
      });
    }
  };

  const handleZoneClick = (feature: MapboxGeoJSONFeature) => {
    const { id, properties } = feature;
    const isSameZone = selectedZone?.id === id;

    deselectZone();

    if (isSameZone) {
      return;
    }

    mapRef.current.setFeatureState(
      {
        source: ROAD_VMT_ZONE_SOURCE_ID,
        sourceLayer: layerId,
        id,
      },
      { selected: true },
    );

    selectedZone = feature;
    setSelectedZone({
      zoneId: id as string,
      name: properties?.cnty_name as string,
    });
  };

  const handleZoneMousemove = (e: any) => {
    const feature = e.features[0];

    if (!feature) {
      return;
    }

    const { id } = feature;

    if (mapboxZoneCountsHoverPopupRef.current) {
      mapboxZoneCountsHoverPopupRef.current.remove();
    }

    if (hoveredZone !== null) {
      mapRef.current.setFeatureState(
        {
          source: ROAD_VMT_ZONE_SOURCE_ID,
          sourceLayer: layerId,
          id: hoveredZone.id,
        },
        { hover: false },
      );
    }

    hoveredZone = feature;

    mapRef.current.setFeatureState(
      {
        source: ROAD_VMT_ZONE_SOURCE_ID,
        sourceLayer: layerId,
        id,
      },
      { hover: true },
    );

    if (typeof setZonePopupRef.current === "function") {
      const vmtRawValue = zoneVmtCountsRef.current.zones.get(id) || 0;
      const mileageRawValue = zoneMileageCountsRef.current.zones.get(id) || 0;
      const vmt = intFormat.format(vmtRawValue);
      const mileage = intFormat.format(mileageRawValue);
      const vmtPerSqmi = getSqMiDensity(vmtRawValue, feature.properties?.area_sqkm);
      const vmtPerCapita = vmtRawValue / feature.properties?.population;
      setZonePopupRef.current({
        id: feature.id,
        feature: feature,
        vmt,
        vmtPerSqmi: intFormat.format(vmtPerSqmi),
        vmtPerCapita: decimalFormat.format(vmtPerCapita),
        mileage,
      });
    }

    if (zonePopupRef.current) {
      mapboxZoneCountsHoverPopupRef.current = new Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 15,
      })
        .setLngLat(e.lngLat)
        .setDOMContent(zonePopupRef.current as Node)
        .addTo(mapRef.current);
    }
  };

  const handleZoneMouseleave = () => {
    mapRef.current.getCanvas().style.cursor = "";

    if (hoveredZone !== null) {
      mapRef.current.setFeatureState(
        {
          source: ROAD_VMT_ZONE_SOURCE_ID,
          sourceLayer: layerId,
          id: hoveredZone.id,
        },
        { hover: false },
      );
    }

    if (mapboxZoneCountsHoverPopupRef.current) {
      mapboxZoneCountsHoverPopupRef.current.remove();
    }

    hoveredZone = null;
    setZonePopupRef.current(null);
  };

  const handleMapClick = (
    e: MapMouseEvent & {
      features?: MapboxGeoJSONFeature[] | undefined;
    } & EventData,
  ) => {
    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: [`${ROAD_VMT_LAYER_PREFIX}_${layerId}`],
    });

    const isFirstFeatureZone = features?.[0]?.layer.id === `${ROAD_VMT_LAYER_PREFIX}_${layerId}`;

    if (isFirstFeatureZone) {
      handleZoneClick(features[0]);
    } else {
      deselectZone();
    }
  };

  const deselectZone = () => {
    if (selectedZone !== null) {
      mapRef.current.setFeatureState(
        {
          source: ROAD_VMT_ZONE_SOURCE_ID,
          sourceLayer: layerId,
          id: selectedZone.id,
        },
        { selected: false },
      );

      selectedZone = null;
      setSelectedZone(null);
    }
  };

  mapRef.current.on("click", handleMapClick);

  mapRef.current.on("mousemove", `${ROAD_VMT_LAYER_PREFIX}_${layerId}`, handleZoneMousemove);
  mapRef.current.on("mouseleave", `${ROAD_VMT_LAYER_PREFIX}_${layerId}`, handleZoneMouseleave);

  return {
    deselectZone,
    updateCounts,
  };
};
