import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, styled } from "@mui/material";
import * as React from "react";

type IconButtonBaseProps = Omit<
  MuiIconButtonProps,
  | "centerRipple"
  | "disableElevation"
  | "disableFocusRipple"
  | "disableRipple"
  | "disableTouchRipple"
  | "focusRipple"
  | "TouchRippleProps"
  | "touchRippleRef"
>;

export interface IconButtonProps extends IconButtonBaseProps {
  // Add your custom props here
}

const StyledIconButton = styled(MuiIconButton)(({ theme }) => ({
  padding: 0,
}));

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ color = "primary", ...props }, ref) => {
    return <StyledIconButton ref={ref} color={color} {...props} />;
  },
);
