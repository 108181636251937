import styled from "@emotion/styled";
import { Close, Info } from "@mui/icons-material";
import { IconButton } from "components_new";
import React, { FC, ReactNode } from "react";

import { FlexContainer } from "components";

export interface RightSidebarPanelProps {
  children?: ReactNode;
  isOpen: boolean;
  title: String;
  subtitle?: String;
  cardType?: String;
  onClose?: () => void;
}

const RightSidebarContainer = styled.div<{ isOpen: boolean }>`
  z-index: 9;
  min-width: var(--right-sidebar-width);
  max-width: var(--right-sidebar-width);
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 0;
  background: #ffffff;
  border-radius: 8px 0px 0px 8px;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(110%)")};
  transition: 0.5s ease;
  padding-bottom: 1rem;
`;

const CardHeader = styled(FlexContainer)`
  color: #ffffff;
  background: var(--color-text);
  border-radius: 8px 0px 0px 0px;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
`;

const CardTitle = styled(FlexContainer)`
  font-weight: 600;
  & svg {
    margin-right: 0.5rem;
  }
`;

const CardSubtitle = styled.div`
  background-color: var(--color-blue-50);
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  font-size: 1rem;
`;

export const RightSidebarPanel: FC<RightSidebarPanelProps> = ({
  children,
  isOpen,
  title,
  subtitle,
  cardType,
  onClose,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <RightSidebarContainer isOpen={isOpen}>
      <CardHeader>
        <CardTitle>
          <Info />
          <span>{title}</span>
        </CardTitle>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </CardHeader>
      {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
      {children}
    </RightSidebarContainer>
  );
};
