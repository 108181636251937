import styled from "@emotion/styled";
import { DirectionsCar, FormatListBulleted, Place } from "@mui/icons-material";
import { Dialog, Divider } from "components_new";
import React, { Dispatch, FC, SetStateAction, useCallback, useState } from "react";

import { FlexContainer } from "components";

import { usePageTracking } from "hooks";

import { Chart, ChartContainer } from "./DocsCharts";
import { DocsIcon } from "./DocsIcon";
import { PageContent } from "./PageContent";
import { PageHeader } from "./PageHeader";
import { QualityPoint } from "./QualityPoint";
import { QualityPointType, generateQualityPoints } from "./qualityPoints";

const DataQualtyContainer = styled(PageContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubTitle = styled.h4`
  grid-area: subtitle;
  margin: 0.5rem 0;
  font-size: 16px;
`;

const DevItemsContainer = styled.div`
  max-width: 1500px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  gap: 2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 992px) {
    gap: 4rem;
  }
`;

const DevItem = styled.div`
  margin: 1rem 0;
  display: grid;
  align-content: start;
  align-items: center;
  justify-items: flex-start;
  grid-template-columns: auto 1rem 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "docs-icon . subtitle"
    "docs-text docs-text docs-text";

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "docs-icon"
      "subtitle"
      "docs-text";
  }
`;

const QualityAssuranceTextContainer = styled.div`
  margin: 4rem 0 2rem;
  max-width: 1200px;

  & h2 {
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const QualityAssuranceList = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.span<{ italic?: boolean; black?: boolean; bold?: boolean }>`
  max-width: 1500px;
  grid-area: docs-text;
  margin: 0.5rem 0;
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: ${({ black }) => (black ? "#000000" : "var(--color-textSecondary)")};
`;

const ChartTitle = styled.h4`
  color: var(--color-text);
  font-size: 14px;
  font-weight: 500;
  margin: 0.5rem 0 0.25rem 0;
  text-align: center;

  @media only screen and (min-width: 1200px) {
    font-size: 20px;
  }
`;

const ChartImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const Glossary = styled(FlexContainer)`
  width: 500px;
  justify-content: space-between;
`;

export const DataQuality: FC = () => {
  const [selectedChart, setSelectedChart] = useState<Chart>();
  const [qualityPoints, setQualityPoints] = useState<QualityPointType[]>(generateQualityPoints(setSelectedChart));

  usePageTracking();

  const handleToggleQualityPoint = useCallback(
    (index: number, callback: Dispatch<SetStateAction<QualityPointType[]>>) => {
      callback(qualityPoints.map((point, i) => (i === index ? { ...point, isOpen: !point.isOpen } : point)));
    },
    [qualityPoints],
  );

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={!!selectedChart} onClose={() => setSelectedChart(undefined)}>
        <ChartContainer>
          <ChartTitle>{selectedChart?.title}</ChartTitle>
          <ChartImg src={selectedChart?.image} alt="" />
        </ChartContainer>
      </Dialog>
      <>
        <PageHeader title="OpenPaths Patterns: Data Quality" subtitle="How is OpenPaths Patterns developed?" />
        <DataQualtyContainer>
          <DevItemsContainer>
            <DevItem>
              <DocsIcon>
                <Place />
              </DocsIcon>
              <SubTitle>Sampled Movement</SubTitle>
              <Text>
                Billions of GPS location points from mobile devices and connected vehicles are transformed into trips
                routed on the road network, with origins and destinations, trip purpose, time of day, and home location.
              </Text>
            </DevItem>
            <DevItem>
              <DocsIcon>
                <DirectionsCar />
              </DocsIcon>
              <SubTitle>Referenced Movement</SubTitle>
              <Text>
                More than 2 million observed traffic counts from published government sources and toll road authorities,
                along with demographic and employment data, and points of interest data are regularly compiled to assist
                in the process of scaling sampled movements.
              </Text>
            </DevItem>
            <DevItem>
              <DocsIcon>
                <FormatListBulleted />
              </DocsIcon>
              <SubTitle>Scaled Movement</SubTitle>
              <Text>
                Mobility relationships derived from government surveys, and technical publications, are used in the
                expansion process along with the referenced movement data, to produce travel data representative of the
                total population.
              </Text>
            </DevItem>
          </DevItemsContainer>
          <Divider
            style={{
              width: "100%",
              maxWidth: "1500px",
              marginTop: "1rem",
            }}
          />
          <QualityAssuranceTextContainer>
            <h2>Quality Assurance</h2>
            <Text>
              Quality assurance is an integral part of the OpenPaths Patterns data product creation process. When
              traffic volumes and trips are updated, validation reports are automatically created to ensure quality
              goals are achieved. Validation is completed for every region of the US using local and national data
              sources such as traffic counts and travel survey data. Quality review and validation are completed across
              multiple dimensions, including:
            </Text>
          </QualityAssuranceTextContainer>

          <QualityAssuranceList>
            {qualityPoints?.map((point, i) => (
              <QualityPoint
                key={i}
                isOpen={point.isOpen}
                title={point.title}
                subtitle={point.subtitle}
                handleToggle={() => handleToggleQualityPoint(i, setQualityPoints)}
              >
                {point.children}
              </QualityPoint>
            ))}
          </QualityAssuranceList>

          <Text italic>Examples of standard validation output from the 2019 content production for reference.</Text>
          <Text italic>
            <Text italic black bold>
              How is the target obtained?
            </Text>{" "}
            Targets are primarily estimated from household travel surveys, including the National Household Travel
            Survey. Federally sponsored travel reports, such as those published by the National Cooperative Highway
            Research Program (NCHRP).
          </Text>
          <Glossary>
            <Text>
              <Text black bold>
                HBW:
              </Text>{" "}
              home-based work
            </Text>
            <Text>
              <Text black bold>
                HBO:
              </Text>{" "}
              home-based other
            </Text>
            <Text>
              <Text black bold>
                NHB:
              </Text>{" "}
              non home-based
            </Text>
          </Glossary>
        </DataQualtyContainer>
      </>
    </>
  );
};
