import styled from "@emotion/styled";
import { ButtonProps, IconButtonProps, Menu, MenuItem, MenuItemProps, Tooltip, TooltipProps } from "@mui/material";
import { ComponentType, FC, MouseEvent, useState } from "react";

import { Card, FlexContainer } from "components";

export interface PopoverMenuItem extends MenuItemProps {
  tooltipProps?: Omit<TooltipProps, "children">;
}

export interface PopoverProps {
  control: ComponentType<ButtonProps | IconButtonProps>;
  menuItems: PopoverMenuItem[];
}

export const PopoverContainer = styled(Card)`
  padding: 1rem;
`;

export const PopoverHeader = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 1rem;

  & .bp4-icon {
    cursor: pointer;
  }
`;

export const PopoverActions = styled(FlexContainer)`
  justify-content: space-between;

  & div {
    color: var(--color-primary);
    cursor: pointer;
  }
`;

export const PopoverContent = styled.div<{ maxHeight?: number }>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "320px")};
  overflow-y: auto;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const Popover: FC<PopoverProps> = ({ control: Control, menuItems }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Control onClick={handleClick} />
      <Menu
        id="popover-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "popover-menu-item",
        }}
      >
        {menuItems.map(({ children, onClick, tooltipProps, ...props }, i) =>
          tooltipProps ? (
            <Tooltip key={i} placement="left" {...tooltipProps}>
              <span>
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    if (onClick) onClick(e);
                    handleClose();
                  }}
                  {...props}
                >
                  {children}
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              key={i}
              onClick={(e) => {
                if (onClick) onClick(e);
                handleClose();
              }}
              {...props}
            >
              {children}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
};
