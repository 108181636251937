import styled from "@emotion/styled";
import { CircularProgress } from "components_new";
import { FC, memo, useMemo } from "react";

import { RoadAnalytics } from "features";

import { RightSidebarPanel } from "components";

import { useAppSelector } from "hooks";

import { DataState } from "store/interfaces";

import { SelectedVolume } from "types";

import { useSetSelectedEdgeId } from "./ControllerCallbacks";

interface EdgeAnalyticsPanelProps {
  closeEdgeAnalyticsPanelRef: React.RefObject<() => void>;
}

const RightSidebarLoaderWrapper = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EdgeAnalyticsPanel: FC<EdgeAnalyticsPanelProps> = memo(({ closeEdgeAnalyticsPanelRef }) => {
  const selectedCorridorEdge = useAppSelector((state) => state.corridor.selectedCorridorEdge);
  const selectedCorridorEdgeDirectionId = useAppSelector((state) => state.corridor.selectedCorridorEdgeDirectionId);
  const corridorEdgeCountsState = useAppSelector((state) => state.corridor.corridorEdgeCounts.state);
  const corridorEdgeDetails = useAppSelector((state) => state.corridor.corridorEdgeDetails);
  const corridorDimensions = useAppSelector((state) => state.corridor.corridorMetadata.data?.measures[0].dimensions);

  const selectRoadVolumeId = useSetSelectedEdgeId();

  const isLoading = useMemo(() => {
    return corridorEdgeCountsState === DataState.LOADING || corridorEdgeDetails.state === DataState.LOADING;
  }, [corridorEdgeCountsState, corridorEdgeDetails.state]);

  const isShowRightSidebar = useMemo(
    () =>
      Boolean(
        selectedCorridorEdge &&
          (corridorEdgeDetails.state === DataState.AVAILABLE || corridorEdgeDetails.state === DataState.LOADING),
      ),
    [selectedCorridorEdge, corridorEdgeDetails.state],
  );

  const isEdgeDetailsLoading = Boolean(selectedCorridorEdge && isLoading);

  const handleClosePanel = () => {
    if (typeof closeEdgeAnalyticsPanelRef.current === "function") {
      closeEdgeAnalyticsPanelRef.current();
      selectRoadVolumeId(null);
    }
  };

  // If selectedCorridorEdgeDirectionId is null, it means that the user has selected a direction by clicking on the segment
  const selectedRoadVolumeId = selectedCorridorEdgeDirectionId
    ? selectedCorridorEdgeDirectionId
    : String(selectedCorridorEdge?.id);

  return (
    <RightSidebarPanel
      title={isEdgeDetailsLoading ? "Loading..." : String(selectedCorridorEdge?.id)}
      cardType="road-segment"
      onClose={handleClosePanel}
      isOpen={isShowRightSidebar || isEdgeDetailsLoading}
    >
      {!isEdgeDetailsLoading && selectedCorridorEdge && corridorEdgeDetails.data?.results ? (
        <RoadAnalytics
          breakdowns={corridorEdgeDetails ? corridorEdgeDetails.data.results[selectedRoadVolumeId]?.breakdowns : []}
          selectedRoadVolumeId={selectedRoadVolumeId}
          selectedRoadVolume={
            {
              ftSegmentId: String(selectedCorridorEdge.id),
              ftDirection: selectedCorridorEdge.ft_dir,
              tfSegmentId: String(-selectedCorridorEdge.id),
              tfDirection: selectedCorridorEdge.tf_dir,
            } as SelectedVolume
          }
          roadMetadataDimensions={corridorDimensions!}
          selectedSegmentId={String(selectedCorridorEdge.id)}
          changeRoadVolumeId={selectRoadVolumeId}
          isPedestriansMode={false}
        />
      ) : null}
      {isEdgeDetailsLoading && (
        <RightSidebarLoaderWrapper>
          <CircularProgress />
        </RightSidebarLoaderWrapper>
      )}
    </RightSidebarPanel>
  );
});
